import React from "react";
import "./admin-sale-requests-list.css";
import "../../pages/cart-page.css";
//
import { RequestStatus } from "../../constants";
import { LoadingComponent } from "../public/loading-component";
import { FormModal } from "../../components/public/form-components";
import { TableComponent } from "../public/table-component";
import { BaseService } from "../../services/base-service";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export class AdminAdvisoryList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      getSaleRequestsListAdvisoryRequestResult: [],
      headers: [
        { title: "Ngày tạo", width: "240" },
        { title: "Sản phẩm" },
        { title: "Số điện thoại khách", width: "350" },
        { title: "", width: "1" },
      ],
      isOpenAdvisoryModal: false,
      modalTitle: "",
      targetAdvisory: null,
      target: "_blank",
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (
      this.props.getSaleRequestsListAdvisoryRequestStatus !==
        nextProps.getSaleRequestsListAdvisoryRequestStatus &&
      nextProps.getSaleRequestsListAdvisoryRequestStatus ===
        RequestStatus.RQ_SUCCESS
    ) {
      let getSaleRequestsListAdvisoryRequestResult =
        nextProps.getSaleRequestsListAdvisoryRequestResult;
      this.reOrderGetSaleRequestsListByTypeRequestResult(
        getSaleRequestsListAdvisoryRequestResult
      );
    }
  }

  reOrderGetSaleRequestsListByTypeRequestResult = (
    getSaleRequestsListAdvisoryRequestResult
  ) => {
    let res = BaseService.getOrderListWithCreatedTime(
      getSaleRequestsListAdvisoryRequestResult.map((t) => {
        const item = t;
        item.createdTimeOrderString =
          BaseService.convertCreatedTimeToDateOrderString(item.createdTime);
        return item;
      })
    );
    this.setState({
      getSaleRequestsListAdvisoryRequestResult: res,
    });
  };

  getTableHeaders = () => {
    const headers = this.state.headers;
    return headers;
  };

  getTableRows = () => {
    const rows = [];
    this.state.getSaleRequestsListAdvisoryRequestResult.forEach((item) => {
      const content = JSON.parse(item.content);
      rows.push({
        columns: [
          <>
            <div
              className={
                item.status === "RECORD_STATUS_0_NEW"
                  ? "admin-sale-request-new-record-wrapper-div"
                  : ""
              }
            >
              {item.status === "RECORD_STATUS_0_NEW" ? (
                <div className="admin-sale-request-new-record-sign-div"></div>
              ) : (
                <></>
              )}
              {BaseService.convertCreatedTimeToDateString(item.createdTime)}
            </div>
          </>,
          <>
            <div>
              <div
                className="table-component-title-link-div"
                onClick={() => {
                  this.openAdvisoryContentModal(item);
                }}
              >
                {content.products[0].name}
                <div className="table-component-image-div">
                  <img alt="" src={content.products[0].imgURL} />
                </div>
              </div>
            </div>
          </>,
          <>
            <div>
              <b>{content.customer.phone}</b>
            </div>
          </>,
          <></>,
        ],
      });
    });
    return rows;
  };

  openAdvisoryContentModal = (advisory) => {
    let stateAdvisory = { ...advisory };
    stateAdvisory.content = JSON.parse(advisory.content);
    stateAdvisory.createdTime = BaseService.convertCreatedTimeToDateString(
      advisory.createdTime
    );
    this.setState(
      {
        modalTitle: "Yêu cầu tư vấn",
        isOpenAdvisoryModal: true,
        targetAdvisory: stateAdvisory,
      },
      () => {
        let advisoryInput = { ...advisory };
        if (advisoryInput.status === "RECORD_STATUS_0_NEW") {
          let getSaleRequestsListAdvisoryRequestResult =
            this.state.getSaleRequestsListAdvisoryRequestResult;
          const idx = getSaleRequestsListAdvisoryRequestResult.findIndex(
            (t) => t.id === advisoryInput.id
          );
          getSaleRequestsListAdvisoryRequestResult[idx].status =
            "RECORD_STATUS_1_READ";
          advisoryInput = { ...getSaleRequestsListAdvisoryRequestResult[idx] };
          this.setState(
            {
              getSaleRequestsListAdvisoryRequestResult:
                getSaleRequestsListAdvisoryRequestResult,
            },
            () => {
              this.props.updateSaleRequests(advisoryInput);
            }
          );
        }
      }
    );
  };

  closeModal = () => {
    this.setState({
      isOpenAdvisoryModal: false,
      targetAdvisory: null,
    });
  };

  getCustomerElement = (item) => {
    const element = (
      <>
        <Row>
          <Col xs={3}>Số điện thoại:</Col>
          <Col xs={9}>
            <div className="admin-sale-request-content-modal-content-customer-info-phone-div">
              {item.customer.phone}
            </div>
          </Col>
        </Row>
        {!BaseService.stringIsNullOrWhiteSpace(item.customer.name) ? (
          <Row>
            <Col xs={3}>Họ tên:</Col>
            <Col xs={9}>
              <div>{item.customer.name}</div>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  getProductItemElement = (item) => {
    const element = (
      <>
        <div className="cart-page-product-item-wrapper-div">
          <Row>
            <Col xs={3}>
              <Link
                target={this.state.target}
                className="cart-page-product-item-link"
                // to={`/san-pham/${BaseService.transformVNIToHyphenCase(item.products[0].categoryName)}/${item.products[0].titleId}`}
                to={`/${item.products[0].titleId}`}
              >
                <img
                  alt=""
                  className="cart-page-product-item-img"
                  src={item.products[0].imgURL}
                />
              </Link>
            </Col>
            <Col xs={9} className="cart-page-product-item-info-wrapper-div">
              {/* <div className="cart-page-product-item-content-info-bottom-side-div">
                <div
                  className="cart-page-product-item-content-info-delete-button-div"
                  onClick={() => {
                    this.handleOnRemoveProductItem(item.products[0].id);
                  }}
                >
                  Xóa
                </div>
              </div> */}
              <div className="cart-page-product-item-content-div">
                <div className="cart-page-product-item-content-name-div">
                  <Link
                    target={this.state.target}
                    className="cart-page-product-item-link"
                    // to={`/san-pham/${BaseService.transformVNIToHyphenCase(item.products[0].categoryName)}/${item.products[0].titleId}`}
                    to={`/${item.products[0].titleId}`}
                  >
                    {item.products[0].name}
                  </Link>
                </div>
                <div className="cart-page-product-item-content-info-div">
                  {/* <div className="cart-page-product-item-content-info-left-side-div">
                    Số lượng: {item.amount}
                  </div> */}
                  <div>
                    <div className="cart-page-product-item-content-price-div">
                      {BaseService.formatNumber(item.products[0].price)}đ
                    </div>
                    <div className="cart-page-product-item-content-info-sale-info">
                      {item.products[0].saleInfo &&
                      item.products[0].saleInfo.sale11 === true ? (
                        <>
                          <span className="cart-page-product-item-content-info-sale-sale11-span">
                            {/* Khuyến mãi mua 1 tặng 1 */}
                            Khuyến mãi
                          </span>
                        </>
                      ) : (
                        <>
                          {item.products[0].price <
                          item.products[0].originalPrice ? (
                            <>
                              <span className="cart-page-product-item-content-info-sale-original-price-span">
                                {BaseService.formatNumber(
                                  item.products[0].originalPrice
                                )}
                                đ
                              </span>
                              <span className="cart-page-product-item-content-info-sale-column-span">
                                |
                              </span>
                              {/* <span className="cart-page-product-item-content-info-sale-discount-percent-span">
                                {" "}
                                -{item.products[0].discountPercent}%
                              </span> */}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
    return element;
  };

  getAdvisoryContentElement = () => {
    const element = (
      <>
        <div className="admin-sale-request-content-modal-content-div">
          <Container>
            <Row>
              <Col>
                <div className="cart-page-title">Thông tin khách hàng</div>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.getCustomerElement(this.state.targetAdvisory.content)}
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <div className="cart-page-title">Thông tin sản phẩm</div>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.getProductItemElement(this.state.targetAdvisory.content)}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        {/* {
        this.props.getSaleRequestsListAdvisoryRequestStatus ===
        RequestStatus.RQ_SUCCESS ? (
          <> */}
        <TableComponent
          headers={this.getTableHeaders()}
          rows={this.getTableRows()}
          noItemRow={
            <td colSpan={this.state.headers.length}>Chưa có yêu cầu</td>
          }
        />
        <FormModal
          size="lg"
          isShowModal={this.state.isOpenAdvisoryModal}
          modalTitle={this.state.modalTitle}
          modalContent={
            this.state.targetAdvisory ? (
              this.getAdvisoryContentElement()
            ) : (
              <div style={{ height: "450px" }}>
                <LoadingComponent />
              </div>
            )
          }
          closeModal={this.closeModal}
        />
        {/* </>
        ) : (
          <>
            <LoadingComponent />
          </>
        )} */}
      </>
    );
    return html;
  }
}

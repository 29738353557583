import { ProductService } from "../../services/product-service";
import { CategoryService } from "../../services/category-service";
import { BaseService } from "../../services/base-service";
import { Messages } from "../../constants";
import { UploadService } from "../../services/upload-service";

export const productpage_GET_PRODUCT_LIST_REQUEST =
  "productpage_GET_PRODUCT_LIST_REQUEST";
export const productpage_GET_PRODUCT_LIST_SUCCESS =
  "productpage_GET_PRODUCT_LIST_SUCCESS";
export const productpage_GET_PRODUCT_LIST_ERROR =
  "productpage_GET_PRODUCT_LIST_ERROR";

export const productpage_INSERT_PRODUCT_SUCCESS =
  "productpage_INSERT_PRODUCT_SUCCESS";
export const productpage_INSERT_PRODUCT_REQUEST =
  "productpage_INSERT_PRODUCT_REQUEST";
export const productpage_INSERT_PRODUCT_ERROR =
  "productpage_INSERT_PRODUCT_ERROR";

export const productpage_UPDATE_PRODUCT_REQUEST =
  "productpage_UPDATE_PRODUCT_REQUEST";
export const productpage_UPDATE_PRODUCT_SUCCESS =
  "productpage_UPDATE_PRODUCT_SUCCESS";
export const productpage_UPDATE_PRODUCT_ERROR =
  "productpage_UPDATE_PRODUCT_ERROR";

export const productpage_UPDATE_PRODUCT_ORDER_NUMBER_REQUEST =
  "productpage_UPDATE_PRODUCT_ORDER_NUMBER_REQUEST";
export const productpage_UPDATE_PRODUCT_ORDER_NUMBER_SUCCESS =
  "productpage_UPDATE_PRODUCT_ORDER_NUMBER_SUCCESS";
export const productpage_UPDATE_PRODUCT_ORDER_NUMBER_ERROR =
  "productpage_UPDATE_PRODUCT_ORDER_NUMBER_ERROR";

export const productpage_UPDATE_PRODUCT_IS_DELETED_REQUEST =
  "productpage_UPDATE_PRODUCT_IS_DELETED_REQUEST";
export const productpage_UPDATE_PRODUCT_IS_DELETED_SUCCESS =
  "productpage_UPDATE_PRODUCT_IS_DELETED_SUCCESS";
export const productpage_UPDATE_PRODUCT_IS_DELETED_ERROR =
  "productpage_UPDATE_PRODUCT_IS_DELETED_ERROR";

export const productpage_DELETE_PRODUCT_REQUEST =
  "productpage_DELETE_PRODUCT_REQUEST";
export const productpage_DELETE_PRODUCT_SUCCESS =
  "productpage_DELETE_PRODUCT_SUCCESS";
export const productpage_DELETE_PRODUCT_ERROR =
  "productpage_DELETE_PRODUCT_ERROR";

export const productpage_GET_ALL_HASHTAGS_REQUEST =
  "productpage_GET_ALL_HASHTAGS_REQUEST";
export const productpage_GET_ALL_HASHTAGS_SUCCESS =
  "productpage_GET_ALL_HASHTAGS_SUCCESS";
export const productpage_GET_ALL_HASHTAGS_ERROR =
  "productpage_GET_ALL_HASHTAGS_ERROR";

export const productpage_GET_PRODUCT_DETAIL_REQUEST =
  "productpage_GET_PRODUCT_DETAIL_REQUEST";
export const productpage_GET_PRODUCT_DETAIL_SUCCESS =
  "productpage_GET_PRODUCT_DETAIL_SUCCESS";
export const productpage_GET_PRODUCT_DETAIL_ERROR =
  "productpage_GET_PRODUCT_DETAIL_ERROR";

export const productpage_GET_PRODUCT_LIST_BY_CATEGORY_REQUEST =
  "productpage_GET_PRODUCT_LIST_BY_CATEGORY_REQUEST";
export const productpage_GET_PRODUCT_LIST_BY_CATEGORY_SUCCESS =
  "productpage_GET_PRODUCT_LIST_BY_CATEGORY_SUCCESS";
export const productpage_GET_PRODUCT_LIST_BY_CATEGORY_ERROR =
  "productpage_GET_PRODUCT_LIST_BY_CATEGORY_ERROR";

export const getProductDetailByTitleId = (titleId) => {
  return (dispatch) => {
    dispatch({
      type: productpage_GET_PRODUCT_DETAIL_REQUEST,
    });

    const request = ProductService.getProductDetailByTitleId(titleId);
    if (request) {
      request.then(
        (data) => {
          // const productDetail = {
          //     id: data.id,
          //     name: data.name,
          //     titleId: data.titleId,
          //     description: data.description,
          //     informationDescription: data.informationDescription,
          //     ingredientDescription: data.ingredientDescription,
          //     usesDescription: data.usesDescription,
          //     price: data.price,
          //     originalPrice: data.originalPrice,
          //     discountPercent: data.discountPercent,
          //     promoText: data.promoText,
          //     isHot: data.isHot,
          //     isNew: data.isNew,
          //     image: data.image,
          //     image_url: data.image_url,
          //     images: data.images,
          //     hashTags: data.hashTags,
          //     category: data.category,
          //     seoTitle: data.seoTitle,
          //     seoKeyWords: data.seoKeyWords,
          //     seoDescription: data.seoDescription
          // };

          const productDetail = data;
          if (!BaseService.stringIsNullOrWhiteSpace(productDetail.colorTags)) {
            const colorTags = JSON.parse(productDetail.colorTags);
            productDetail.colorTags = colorTags;
          }
          productDetail.images = productDetail.images.map((item) => {
            const imageURL =
              // BaseService.getImageLink(item.url);
              BaseService.getImageUrlFromServer(item.id, item.name, item.url)
            BaseService.preLoadImage(imageURL);
            if (!BaseService.stringIsNullOrWhiteSpace(item.colorTags)) {
              item.colorTags = JSON.parse(item.colorTags);
            }
            return {
              ...item,
              url: imageURL,
            };
          });
          if (!BaseService.stringIsNullOrWhiteSpace(productDetail.saleInfo)) {
            const saleInfo = JSON.parse(productDetail.saleInfo);
            productDetail.saleInfo = saleInfo;
          }
          if (
            !BaseService.stringIsNullOrWhiteSpace(productDetail.styleConfig)
          ) {
            const styleConfig = JSON.parse(productDetail.styleConfig);
            productDetail.styleConfig = styleConfig;
          }
          console.log("ProductService.getProductDetail(productId);", data);
          setTimeout(() => {
            dispatch({
              type: productpage_GET_PRODUCT_DETAIL_SUCCESS,
              payload: {
                productDetail: productDetail,
              },
            });
          }, 50);
        },
        (error) => {
          dispatch({
            type: productpage_GET_PRODUCT_DETAIL_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const getProductDetail = (productId) => {
  return (dispatch) => {
    dispatch({
      type: productpage_GET_PRODUCT_DETAIL_REQUEST,
    });

    const request = ProductService.getProductDetail(productId);
    if (request) {
      request.then(
        (data) => {
          // const productDetail = {
          //     id: data.id,
          //     name: data.name,
          //     titleId: data.titleId,
          //     description: data.description,
          //     informationDescription: data.informationDescription,
          //     ingredientDescription: data.ingredientDescription,
          //     usesDescription: data.usesDescription,
          //     price: data.price,
          //     originalPrice: data.originalPrice,
          //     discountPercent: data.discountPercent,
          //     promoText: data.promoText,
          //     isHot: data.isHot,
          //     isNew: data.isNew,
          //     image: data.image,
          //     image_url: data.image_url,
          //     images: data.images,
          //     hashTags: data.hashTags,
          //     category: data.category,
          //     seoTitle: data.seoTitle,
          //     seoKeyWords: data.seoKeyWords,
          //     seoDescription: data.seoDescription
          // };

          let productDetail = data;
          if (!BaseService.stringIsNullOrWhiteSpace(productDetail.colorTags)) {
            const colorTags = JSON.parse(productDetail.colorTags);
            productDetail.colorTags = colorTags;
          }
          productDetail.images.forEach((item) => {
            if (!BaseService.stringIsNullOrWhiteSpace(item.colorTags)) {
              item.colorTags = JSON.parse(item.colorTags);
            }
          });
          if (!BaseService.stringIsNullOrWhiteSpace(productDetail.saleInfo)) {
            const saleInfo = JSON.parse(productDetail.saleInfo);
            productDetail.saleInfo = saleInfo;
          }
          if (
            !BaseService.stringIsNullOrWhiteSpace(productDetail.styleConfig)
          ) {
            const styleConfig = JSON.parse(productDetail.styleConfig);
            productDetail.styleConfig = styleConfig;
          }
          console.log("ProductService.getProductDetail(productId);", data);
          dispatch({
            type: productpage_GET_PRODUCT_DETAIL_SUCCESS,
            payload: {
              productDetail: productDetail,
            },
          });
        },
        (error) => {
          dispatch({
            type: productpage_GET_PRODUCT_DETAIL_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const loadProductListByCaterogy = (category) => {
  return (dispatch) => {
    dispatch({
      type: productpage_GET_PRODUCT_LIST_BY_CATEGORY_REQUEST,
    });

    // const productListByCategory = [
    //     {
    //         id: 1,
    //         title: 'Cras sodales justo Cras sodales justo Cras sodales justo',
    //         categoryId: 1,
    //         tags: '11,13',
    //         imgURL: product1
    //     }
    // ];

    // dispatch({
    //     type: productpage_LOAD_PRODUCT_LIST_BY_CATEGORY_SUCCESS,
    //     payload: {
    //         productListByCategory: productListByCategory
    //     }
    // });

    const request = ProductService.getProductListByCategory(category);
    if (request) {
      request.then(
        (data) => {
          let productListByCategory = data.map((item) => {
            // const imgURL = item.image
            //   ? BaseService.getImageUrlFromServer(
            //       item.image.id,
            //       item.image.name
            //     )
            //   : "";
            const imgURL =
              item.image && item.image.url
                ?
                // BaseService.getImageLink(item.image.url)
                BaseService.getImageUrlFromServer(item.image.id, item.image.name, item.image.url)
                : "";
            BaseService.preLoadImage(imgURL);

            let secondImgURL = null;
            const images = item.images;
            if (images && images.length > 0) {
              secondImgURL = images[0].url
                ?
                // BaseService.getImageLink(images[0].url)
                BaseService.getImageUrlFromServer(images[0].id, images[0].name, images[0].url)
                : "";
              BaseService.preLoadImage(secondImgURL);
            }

            return {
              id: item.id,
              name: item.name,
              titleId: item.titleId,
              categoryId: item.category.id,
              categoryName: item.category.name,
              tags:
                item.hashTags && item.hashTags.length > 0
                  ? BaseService.convertArrayStringToArray(
                    item.hashTags,
                    "#"
                  ).toString()
                  : "",
              price: item.price,
              originalPrice: item.originalPrice,
              discountPercent: item.discountPercent,
              promoText: item.promoText,
              imgURL: imgURL,
              secondImgURL: secondImgURL ? secondImgURL : imgURL,
              image: item.image,
              orderNumber: item.orderNumber,
              isNew: item.isNew,
              isHot: item.isHot,
              isDeleted: item.isDeleted,
              saleInfo: !BaseService.stringIsNullOrWhiteSpace(item.saleInfo)
                ? JSON.parse(item.saleInfo)
                : {},
              styleConfig: !BaseService.stringIsNullOrWhiteSpace(
                item.styleConfig
              )
                ? JSON.parse(item.styleConfig)
                : {},
              // id: item.id,
              // name: item.name,
              // titleId: item.titleId,
              // imgURL: item.image
              //   ? BaseService.getImageUrlFromServer(
              //       item.image.id,
              //       item.image.name
              //     )
              //   : "",
              // price: item.price,
              // originalPrice: item.originalPrice,
              // discountPercent: item.discountPercent,
              // promoText: item.promoText,
              // tags: [],
            };
          });
          setTimeout(() => {
            dispatch({
              type: productpage_GET_PRODUCT_LIST_BY_CATEGORY_SUCCESS,
              payload: {
                productListByCategory: productListByCategory,
              },
            });
          }, 50);
        },
        (error) => {
          dispatch({
            type: productpage_GET_PRODUCT_LIST_BY_CATEGORY_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const getProductList = () => {
  return (dispatch) => {
    dispatch({
      type: productpage_GET_PRODUCT_LIST_REQUEST,
    });

    const request = ProductService.getProductList();
    let productList = [];
    if (request) {
      request.then(
        (data) => {
          console.log(
            "const request = ProductService.getProductList(); - data",
            data
          );
          if (data.length > 0) {
            productList = data
              // .filter(t => (!t.isDeleted || t.isDeleted === false))
              .map((item) => {
                const imgURL = item.image
                  ? BaseService.getImageUrlFromServer(
                    item.image.id,
                    item.image.name,
                    item.image.url
                  )
                  : "";
                BaseService.preLoadImage(imgURL);

                let secondImgURL = null;
                const images = item.images;
                if (images && images.length > 0) {
                  secondImgURL = images[0].url
                    ?
                    // BaseService.getImageLink(images[0].url)
                    BaseService.getImageUrlFromServer(images[0].id, images[0].name, images[0].url)
                    : "";
                  BaseService.preLoadImage(secondImgURL);
                }

                return {
                  id: item.id,
                  name: item.name,
                  titleId: item.titleId,
                  categoryId: item.category.id,
                  categoryName: item.category.name,
                  tags:
                    item.hashTags && item.hashTags.length > 0
                      ? BaseService.convertArrayStringToArray(
                        item.hashTags,
                        "#"
                      ).toString()
                      : "",
                  price: item.price,
                  originalPrice: item.originalPrice,
                  discountPercent: item.discountPercent,
                  promoText: item.promoText,
                  imgURL: imgURL,
                  secondImgURL: secondImgURL ? secondImgURL : imgURL,
                  image: item.image,
                  orderNumber: item.orderNumber,
                  isNew: item.isNew,
                  isHot: item.isHot,
                  isDeleted: item.isDeleted,
                  saleInfo: !BaseService.stringIsNullOrWhiteSpace(item.saleInfo)
                    ? JSON.parse(item.saleInfo)
                    : {},
                  styleConfig: !BaseService.stringIsNullOrWhiteSpace(
                    item.styleConfig
                  )
                    ? JSON.parse(item.styleConfig)
                    : {},
                };
              });
          }
          setTimeout(() => {
            dispatch({
              type: productpage_GET_PRODUCT_LIST_SUCCESS,
              payload: {
                productList: productList,
              },
            });
          }, 50);
        },
        (error) => {
          dispatch({
            type: productpage_GET_PRODUCT_LIST_ERROR,
            //     payload: {
            //         errorMessage: Messages.REQUEST_ERROR
            //     }
          });
        }
      );
    }
  };
};

export const insertProduct = (product) => {
  return (dispatch) => {
    dispatch({
      type: productpage_INSERT_PRODUCT_REQUEST,
    });

    const request = ProductService.insertProduct(product);
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: productpage_INSERT_PRODUCT_SUCCESS,
            payload: {
              insertedProduct: data,
            },
          });
        },
        (error) => {
          dispatch({
            type: productpage_INSERT_PRODUCT_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const insertProductWithSaveProductImageList = (product, imageList) => {
  return async (dispatch) => {
    dispatch({
      type: productpage_INSERT_PRODUCT_REQUEST,
    });

    // if(imageList && imageList.length > 0) {
    //     const imageLength = imageList.length;
    //     let idx = 0;
    //     if(idx < imageLength) {
    //         const image = imageList[idx];
    //         const imageInput = {
    //             id: image.id,
    //             colorTags: JSON.stringify(image.colorTags)
    //         }
    //         const request = await UploadService.updateImage(imageInput);
    //         console.log('UploadService.updateImage(imageInput)');
    //         if(request) {
    //             idx += 1;
    //         }
    //         else {
    //             dispatch({
    //                 type: productpage_INSERT_PRODUCT_ERROR
    //             });
    //             return;
    //         }
    //     }
    // }

    imageList.forEach(async (image) => {
      const imageInput = {
        id: image.id,
        colorTags: JSON.stringify(image.colorTags),
      };
      const request = await UploadService.updateImage(imageInput);
      console.log("UploadService.updateImage(imageInput)");
      if (!request) {
        dispatch({
          type: productpage_INSERT_PRODUCT_ERROR,
        });
        return;
      }
    });

    const request = ProductService.insertProduct(product);
    console.log("ProductService.insertProduct(product)");
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: productpage_INSERT_PRODUCT_SUCCESS,
            payload: {
              insertedProduct: data,
            },
          });
        },
        (error) => {
          dispatch({
            type: productpage_INSERT_PRODUCT_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const updateProductWithSaveProductImageList = (product, imageList) => {
  return (dispatch) => {
    imageList.forEach(async (image) => {
      const imageInput = {
        id: image.id,
        colorTags: JSON.stringify(image.colorTags),
      };
      const request = await UploadService.updateImage(imageInput);
      console.log("UploadService.updateImage(imageInput)");
      if (!request) {
        dispatch({
          type: productpage_INSERT_PRODUCT_ERROR,
        });
        return;
      }
    });

    return updateProductCore(
      dispatch,
      product,
      productpage_UPDATE_PRODUCT_REQUEST,
      productpage_UPDATE_PRODUCT_SUCCESS,
      productpage_UPDATE_PRODUCT_ERROR
    );
  };
};

export const updateProduct = (product) => {
  return (dispatch) => {
    return updateProductCore(
      dispatch,
      product,
      productpage_UPDATE_PRODUCT_REQUEST,
      productpage_UPDATE_PRODUCT_SUCCESS,
      productpage_UPDATE_PRODUCT_ERROR
    );
  };
};

export const updateProduct_OrderNumber = (productId, imageId, value) => {
  return async (dispatch) => {
    return updateProductCore(
      dispatch,
      {
        id: productId,
        imageId: imageId,
        orderNumber: value,
      },
      productpage_UPDATE_PRODUCT_ORDER_NUMBER_REQUEST,
      productpage_UPDATE_PRODUCT_ORDER_NUMBER_SUCCESS,
      productpage_UPDATE_PRODUCT_ORDER_NUMBER_ERROR
    );

    // const data = await ProductService.getProductDetail(productId);
    // if(data) {
    //     let product = ProductService.getProductInput(data);
    //     product.orderNumber = value;
    //     return updateProductCore(dispatch, product, productpage_UPDATE_PRODUCT_ORDER_NUMBER_REQUEST, productpage_UPDATE_PRODUCT_ORDER_NUMBER_SUCCESS, productpage_UPDATE_PRODUCT_ORDER_NUMBER_ERROR);
    // }
  };
};

export const updateProduct_IsDeleted = (productId, imageId, value) => {
  return async (dispatch) => {
    return updateProductCore(
      dispatch,
      {
        id: productId,
        imageId: imageId,
        isDeleted: value,
      },
      productpage_UPDATE_PRODUCT_IS_DELETED_REQUEST,
      productpage_UPDATE_PRODUCT_IS_DELETED_SUCCESS,
      productpage_UPDATE_PRODUCT_IS_DELETED_ERROR
    );

    // const data = await ProductService.getProductDetail(productId);
    // if(data) {
    //     let product = ProductService.getProductInput(data);
    //     product.isDeleted = value;
    //     return updateProductCore(dispatch, product, productpage_UPDATE_PRODUCT_IS_DELETED_REQUEST, productpage_UPDATE_PRODUCT_IS_DELETED_SUCCESS, productpage_UPDATE_PRODUCT_IS_DELETED_ERROR);
    // }
  };
};

const updateProductCore = (
  dispatch,
  product,
  requestStatus,
  successStatus,
  errorStatus
) => {
  dispatch({
    type: requestStatus,
  });

  const request = ProductService.updateProduct(product);
  if (request) {
    request.then(
      (data) => {
        dispatch({
          type: successStatus,
          payload: {
            updatedProduct: data,
          },
        });
      },
      (error) => {
        dispatch({
          type: errorStatus,
          payload: {
            errorMessage: Messages.REQUEST_ERROR,
          },
        });
      }
    );
  }
};

export const deleteProduct = (product) => {
  return (dispatch) => {
    dispatch({
      type: productpage_DELETE_PRODUCT_REQUEST,
    });

    const request = ProductService.deleteProduct(product);
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: productpage_DELETE_PRODUCT_SUCCESS,
            payload: {
              deletedProduct: product,
            },
          });
        },
        (error) => {
          dispatch({
            type: productpage_DELETE_PRODUCT_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const getAllHashTags = () => {
  return (dispatch) => {
    dispatch({
      type: productpage_GET_ALL_HASHTAGS_REQUEST,
    });

    const request = CategoryService.getAllHashTags();
    if (request) {
      request.then(
        (data) => {
          let allHashTags = "";
          if (data && data.length > 0) {
            data.forEach((item) => {
              allHashTags += item.trim();
            });
          }
          dispatch({
            type: productpage_GET_ALL_HASHTAGS_SUCCESS,
            payload: {
              allHashTags: allHashTags,
            },
          });
        },
        (error) => {
          dispatch({
            type: productpage_GET_ALL_HASHTAGS_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

// export const getHashTagByCategory = (category) => {
//     return(dispatch) => {
//         dispatch({
//             type: productpage_GET_HASHTAG_BY_CATEGORY_REQUEST
//         });

//         const request = CategoryService.getHashTagByCategory(category);
//         if(request) {
//             request.then(data => {
//                 let allHashTags = '';
//                 if(data && data.length > 0) {
//                     data.forEach(item => {
//                         allHashTags += item.trim();
//                     })
//                 }
//                 dispatch({
//                     type: productpage_GET_HASHTAG_BY_CATEGORY_SUCCESS,
//                     payload: {
//                         allHashTags: allHashTags
//                     }
//                 });
//             }, error => {
//                 dispatch({
//                     type: productpage_GET_HASHTAG_BY_CATEGORY_SUCCESS,
//                     payload: {
//                         errorMessage: Messages.REQUEST_ERROR
//                     }
//                 });
//             });
//         }
//     }
// }

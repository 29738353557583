import React from "react";
import { Row, Col } from "react-bootstrap";
import "./admin-partners-form.css";
import {
  FormButton,
  //   // FormHR,
  FormTextInput,
  FormToggle,
  FormNumberInput,
  FormImageUploader,
} from "../public/form-components";
import { RequestStatus } from "../../constants";
import { BaseService } from "../../services/base-service";
import { UIConfigsService } from "../../services/ui-configns-service";
import "./admin-footer-form.css";
import "./admin-menu-list.css";

export class AdminPartnersForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminPartnersListContent: [],
    };
  }

  componentDidMount() {
    const content = this.props.adminPartnersList.content;
    this.setAdminPartnersListContent(content);
  }

  componentWillReceiveProps(nextProps) {
    const content = nextProps.adminPartnersList.content;
    this.setAdminPartnersListContent(content);
  }

  setAdminPartnersListContent = (content) => {
    const adminPartnersListContent = !BaseService.stringIsNullOrWhiteSpace(
      content
    )
      ? JSON.parse(content)
      : [];
    this.setState((state) => ({
      adminPartnersListContent: BaseService.getOrderListWithName(
        adminPartnersListContent
      ),
    }));
  };

  trackIsDataHasUpdatedTimeout = null;
  trackIsDataHasUpdated = () => {
    clearTimeout(this.trackIsDataHasUpdatedTimeout);
    this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
      let isDataHasUpdated = true;
      this.props.trackIsDataHasUpdated(isDataHasUpdated);
    }, 100);
  };

  handleOnChangePartnerOrderNumber = (id, value) => {
    this.trackIsDataHasUpdated();
    const adminPartnersListContent = this.state.adminPartnersListContent;
    const index = adminPartnersListContent.findIndex((t) => t.id === id);
    if (index !== -1) {
      adminPartnersListContent[index].orderNumber = value;
      this.setState({
        adminPartnersListContent,
      });
    }
  };

  handleOnChangePartnerIsDelete = (id, value) => {
    this.trackIsDataHasUpdated();
    const adminPartnersListContent = this.state.adminPartnersListContent;
    const index = adminPartnersListContent.findIndex((t) => t.id === id);
    if (index !== -1) {
      adminPartnersListContent[index].isDeleted = !value;
      this.setState({
        adminPartnersListContent,
      });
    }
  };

  onChangePartnerLogo = (id, res) => {
    const adminPartnersListContent = this.state.adminPartnersListContent;
    const index = adminPartnersListContent.findIndex((t) => t.id === id);
    if (index !== -1) {
      const imageId = res ? res.data.id : "";
      const imageName = res ? res.data.name : "";
      const imageURL = res ? res.data.url : "";
      adminPartnersListContent[index].logoId = imageId;
      adminPartnersListContent[index].logoName = imageName;
      adminPartnersListContent[index].logoURL = imageURL;
      this.setState({
        adminPartnersListContent,
      });
    }
  };

  handleOnChangePartnerName = (id, value) => {
    this.trackIsDataHasUpdated();
    const adminPartnersListContent = this.state.adminPartnersListContent;
    const index = adminPartnersListContent.findIndex((t) => t.id === id);
    if (index !== -1) {
      adminPartnersListContent[index].name = value;
      this.setState({
        adminPartnersListContent,
      });
    }
  };

  handleOnChangePartnerURL = (id, value) => {
    this.trackIsDataHasUpdated();
    const adminPartnersListContent = this.state.adminPartnersListContent;
    const index = adminPartnersListContent.findIndex((t) => t.id === id);
    if (index !== -1) {
      adminPartnersListContent[index].url = value;
      this.setState({
        adminPartnersListContent,
      });
    }
  };

  handleOnClickRemovePartner = (id) => {
    this.trackIsDataHasUpdated();
    const adminPartnersListContent = this.state.adminPartnersListContent;
    const index = adminPartnersListContent.findIndex((t) => t.id === id);
    if (index !== -1) {
      adminPartnersListContent.splice(index, 1);
      this.setState({
        adminPartnersListContent,
      });
    }
  };

  handleOnClickAddNewPartner = () => {
    this.trackIsDataHasUpdated();
    const adminPartnersListContent = this.state.adminPartnersListContent;
    const newPartner = {
      id: BaseService.getRandomString(5),
      name: "",
      url: "",
      logoId: "",
      logoName: "",
      logoURL: "",
      orderNumber: adminPartnersListContent.length,
      isDeleted: false,
    };
    this.setState(
      (state) => {
        return {
          adminFooterFormContent: {
            adminPartnersListContent: adminPartnersListContent.push(newPartner),
          },
        };
      },
      () => {
        const adminPartnersListContent = this.state.adminPartnersListContent;
      }
    );
  };

  renderPartnersList = () => {
    const adminPartnersListContent = this.state.adminPartnersListContent;
    const html = (
      <>
        <Row className="admin-footer-form-input-row">
          {adminPartnersListContent.map((partner) => (
            <>
              <Col xs={6} lg={2} className="admin-footer-form-input-col">
                <FormNumberInput
                  value={partner.orderNumber}
                  min="0"
                  onChange={(value) => {
                    this.handleOnChangePartnerOrderNumber(partner.id, value);
                  }}
                />
              </Col>
              <Col
                xs={6}
                lg={1}
                className="admin-footer-form-input-col admin-footer-form-input-delete-col"
              >
                <FormToggle
                  value={partner.isDeleted ? !partner.isDeleted : true}
                  onChange={(value) => {
                    this.handleOnChangePartnerIsDelete(partner.id, value);
                  }}
                />
              </Col>
              <Col sm={12} lg={2} className="admin-footer-form-input-col">
                <FormImageUploader
                  labelText="Logo"
                  guide=""
                  isRequired={false}
                  type="partner-logo"
                  imgUrl={
                    partner.logoId
                      ? BaseService.getImageUrlFromServer(
                        partner.logoId,
                        partner.logoName,
                        partner.logoURL
                      )
                      : null
                  }
                  onChangeImageInput={(res) =>
                    this.onChangePartnerLogo(partner.id, res)
                  }
                  error={null}
                />
              </Col>
              <Col sm={12} lg={3} className="admin-footer-form-input-col">
                <FormTextInput
                  value={partner.name}
                  onChange={(value) => {
                    this.handleOnChangePartnerName(partner.id, value);
                  }}
                  placeholder="Tên đối tác"
                />
              </Col>
              <Col sm={11} lg={3} className="admin-footer-form-input-col">
                <FormTextInput
                  value={partner.url}
                  onChange={(value) => {
                    this.handleOnChangePartnerURL(partner.id, value);
                  }}
                  placeholder="Đường dẫn (Nếu có)"
                />
              </Col>
              <Col
                sm={1}
                lg={1}
                className="admin-footer-form-input-col admin-footer-form-input-delete-col"
              >
                <div
                  className="admin-menu-list-delete-button-div"
                  onClick={() => {
                    this.handleOnClickRemovePartner(partner.id);
                  }}
                >
                  Xóa
                </div>
              </Col>
            </>
          ))}
        </Row>
        <Row>
          <Col>
            <div
              className="admin-menu-list-add-menu-button-div admin-footer-form-input-col"
              onClick={this.handleOnClickAddNewPartner}
            >
              Thêm đối tác
            </div>
          </Col>
        </Row>
      </>
    );
    return html;
  };

  renderAdminPartnersFormContent = () => {
    const html = (
      <>
        <Row>
          <Col>
            <div className="admin-menu-list-title-div">
              <div className="admin-menu-list-title-text-div">
                Danh sách đối tác kinh doanh
              </div>
              <div className="admin-menu-list-title-hr-div"></div>
            </div>
          </Col>
        </Row>
        {this.renderPartnersList()}
      </>
    );
    return html;
  };

  handleOnClickSaveAdminPartnersList = () => {
    const adminPartnersListContent = this.state.adminPartnersListContent;
    let uiConfigs = UIConfigsService.getUIConfigsInput({
      ...this.props.adminPartnersList,
      content: JSON.stringify(adminPartnersListContent),
    });
    this.props.save(uiConfigs);
  };

  render() {
    const html = (
      <>
        {this.renderAdminPartnersFormContent()}
        <br />
        <FormButton
          type="save"
          isRequesting={
            this.props.insertUIConfigsRequestStatus ===
              RequestStatus.RQ_REQUEST ||
              this.props.updateUIConfigsRequestStatus === RequestStatus.RQ_REQUEST
              ? true
              : false
          }
          disabled={
            this.props.insertUIConfigsRequestStatus ===
              RequestStatus.RQ_REQUEST ||
              this.props.updateUIConfigsRequestStatus === RequestStatus.RQ_REQUEST
              ? true
              : false
          }
          onClick={this.handleOnClickSaveAdminPartnersList}
        />
      </>
    );
    return html;
  }
}

import React from "react";
import "./admin-sale-requests-list.css";
import "../../pages/cart-page.css";
//
import { RequestStatus } from "../../constants";
import { LoadingComponent } from "../public/loading-component";
import { FormModal } from "../../components/public/form-components";
import { TableComponent } from "../public/table-component";
import { BaseService } from "../../services/base-service";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export class AdminOrderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      getSaleRequestsListOrderRequestResult: [],
      headers: [
        { title: "Ngày tạo", width: "240" },
        { title: "Thông tin đơn hàng" },
        { title: "Thông tin khách hàng", width: "350" },
        { title: "", width: "1" },
      ],
      isOpenOrderModal: false,
      modalTitle: "",
      targetOrder: null,
      target: "_blank",
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (
      this.props.getSaleRequestsListOrderRequestStatus !==
        nextProps.getSaleRequestsListOrderRequestStatus &&
      nextProps.getSaleRequestsListOrderRequestStatus ===
        RequestStatus.RQ_SUCCESS
    ) {
      let getSaleRequestsListOrderRequestResult =
        nextProps.getSaleRequestsListOrderRequestResult;
      this.reOrderGetSaleRequestsListByTypeRequestResult(
        getSaleRequestsListOrderRequestResult
      );
    }
  }

  reOrderGetSaleRequestsListByTypeRequestResult = (
    getSaleRequestsListOrderRequestResult
  ) => {
    let res = BaseService.getOrderListWithCreatedTime(
      getSaleRequestsListOrderRequestResult.map((t) => {
        const item = t;
        item.createdTimeOrderString =
          BaseService.convertCreatedTimeToDateOrderString(item.createdTime);
        return item;
      })
    );
    this.setState({
      getSaleRequestsListOrderRequestResult: res,
    });
  };

  getTableHeaders = () => {
    const headers = this.state.headers;
    return headers;
  };

  getTableRows = () => {
    const rows = [];
    this.state.getSaleRequestsListOrderRequestResult.forEach((item) => {
      const content = JSON.parse(item.content);
      rows.push({
        columns: [
          <>
            <div
              className={
                item.status === "RECORD_STATUS_0_NEW"
                  ? "admin-sale-request-new-record-wrapper-div"
                  : ""
              }
            >
              {item.status === "RECORD_STATUS_0_NEW" ? (
                <div className="admin-sale-request-new-record-sign-div"></div>
              ) : (
                <></>
              )}
              {BaseService.convertCreatedTimeToDateString(item.createdTime)}
            </div>
          </>,
          <>
            <div>
              <div
                className="table-component-title-link-div"
                onClick={() => {
                  this.openOrderContentModal(item);
                }}
              >
                <div>
                  Sản phẩm:{" "}
                  {content.products.map((item, index) => (
                    <>
                      {item.productItem.name}
                      <span className="admin-cart-item-x-span">
                        x {item.amount}
                      </span>
                      {index < content.products.length - 1 ? ", " : ""} <br />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </>,
          <>
            <div>
              Số điện thoại: <b>{content.customer.phone}</b>
            </div>
            <div>Họ tên: {content.customer.name}</div>
            <div>Địa chỉ: {content.customer.address}</div>
          </>,
          <></>,
        ],
      });
    });
    return rows;
  };

  openOrderContentModal = (order) => {
    let stateOrder = { ...order };
    stateOrder.content = JSON.parse(order.content);
    stateOrder.createdTime = BaseService.convertCreatedTimeToDateString(
      order.createdTime
    );
    this.setState(
      {
        modalTitle: "Đơn đặt hàng",
        isOpenOrderModal: true,
        targetOrder: stateOrder,
      },
      () => {
        let orderInput = { ...order };
        if (orderInput.status === "RECORD_STATUS_0_NEW") {
          let getSaleRequestsListOrderRequestResult =
            this.state.getSaleRequestsListOrderRequestResult;
          const idx = getSaleRequestsListOrderRequestResult.findIndex(
            (t) => t.id === orderInput.id
          );
          getSaleRequestsListOrderRequestResult[idx].status =
            "RECORD_STATUS_1_READ";
          orderInput = { ...getSaleRequestsListOrderRequestResult[idx] };
          this.setState(
            {
              getSaleRequestsListOrderRequestResult:
                getSaleRequestsListOrderRequestResult,
            },
            () => {
              this.props.updateSaleRequests(orderInput);
            }
          );
        }
      }
    );
  };

  closeModal = () => {
    this.setState({
      isOpenOrderModal: false,
      targetOrder: null,
    });
  };

  getCustomerElement = (item) => {
    const element = (
      <>
        <Row>
          <Col xs={3}>Số điện thoại:</Col>
          <Col xs={9}>
            <div className="admin-sale-request-content-modal-content-customer-info-phone-div">
              {item.customer.phone}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Họ tên:</Col>
          <Col xs={9}>
            <div>{item.customer.name}</div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Địa chỉ:</Col>
          <Col xs={9}>
            <div>{item.customer.address}</div>
          </Col>
        </Row>
      </>
    );
    return element;
  };

  getProductItemElement = (orderContent) => {
    const products = orderContent.products;
    const element = (
      <>
        {products.map((item) => (
          <>
            <div className="cart-page-product-item-wrapper-div">
              <Row>
                <Col xs={3}>
                  <Link
                    target={this.state.target}
                    className="cart-page-product-item-link"
                    // to={`/san-pham/${BaseService.transformVNIToHyphenCase(
                    //   item.productItem.categoryName
                    // )}/${item.productItem.titleId}`}
                    to={`/${item.productItem.titleId}`}
                  >
                    <img
                      alt=""
                      className="cart-page-product-item-img"
                      src={item.productItem.imgURL}
                    />
                  </Link>
                </Col>
                <Col xs={9} className="cart-page-product-item-info-wrapper-div">
                  {/* <div className="cart-page-product-item-content-info-bottom-side-div">
                    <div
                      className="cart-page-product-item-content-info-delete-button-div"
                      onClick={() => {
                        this.handleOnRemoveProductItem(item.productItem.id);
                      }}
                    >
                      Xóa
                    </div>
                  </div> */}
                  <div className="cart-page-product-item-content-div">
                    <div className="cart-page-product-item-content-name-div">
                      <Link
                        target={this.state.target}
                        className="cart-page-product-item-link"
                        // to={`/san-pham/${BaseService.transformVNIToHyphenCase(
                        //   item.productItem.categoryName
                        // )}/${item.productItem.titleId}`}
                        to={`/${item.productItem.titleId}`}
                      >
                        {item.productItem.name}
                      </Link>
                    </div>
                    <div className="cart-page-product-item-content-info-div">
                      <div className="cart-page-product-item-content-info-left-side-div admin-order-list-amount-wrapper-div">
                        x{" "}
                        <span className="admin-order-list-amount-span">
                          {item.amount}
                        </span>
                      </div>
                      <div>
                        <div className="cart-page-product-item-content-price-div">
                          {BaseService.formatNumber(item.productItem.price)}đ
                        </div>
                        <div className="cart-page-product-item-content-info-sale-info">
                          {item.productItem.saleInfo &&
                          item.productItem.saleInfo.sale11 === true ? (
                            <>
                              <span className="cart-page-product-item-content-info-sale-sale11-span">
                                {/* Khuyến mãi mua 1 tặng 1 */}
                                Khuyến mãi
                              </span>
                            </>
                          ) : (
                            <>
                              {item.productItem.price <
                              item.productItem.originalPrice ? (
                                <>
                                  <span className="cart-page-product-item-content-info-sale-original-price-span">
                                    {BaseService.formatNumber(
                                      item.productItem.originalPrice
                                    )}
                                    đ
                                  </span>
                                  <span className="cart-page-product-item-content-info-sale-column-span">
                                    |
                                  </span>
                                  {/* <span className="cart-page-product-item-content-info-sale-discount-percent-span">
                                    {" "}
                                    -{item.productItem.discountPercent}%
                                  </span> */}
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="cart-page-product-item-content-info-sale-sale11-span">
                                {item.productItem.promoText}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ))}
      </>
    );
    return element;
  };

  getProductsTotalPrice = (orderContent) => {
    const products = orderContent.products;
    let res = 0;
    products.forEach((item) => {
      res += item.productItem.price * item.amount;
    });
    return res;
  };

  getTotalElement = (orderContent) => {
    const note = orderContent.note;
    const element = (
      <>
        <div className="cart-page-total-wrapper-div">
          <Row>
            <Col xs={3}></Col>
            <Col xs={9}>
              <div>
                Ghi chú: <b>{note}</b>
              </div>
              <br />
              <div>Tổng cộng:</div>
              <div className="cart-page-total-div">
                {BaseService.formatNumber(
                  this.getProductsTotalPrice(orderContent)
                )}
                đ
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
    return element;
  };

  getOrderContentElement = () => {
    const element = (
      <>
        <div className="admin-sale-request-content-modal-content-div">
          <Container>
            <Row>
              <Col>
                <div className="cart-page-title">Thông tin khách hàng</div>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.getCustomerElement(this.state.targetOrder.content)}
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <div className="cart-page-title">Thông tin sản phẩm</div>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.getProductItemElement(this.state.targetOrder.content)}
                {this.getTotalElement(this.state.targetOrder.content)}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        {/* {
        this.props.getSaleRequestsListOrderRequestStatus ===
        RequestStatus.RQ_SUCCESS ? (
          <> */}
        <TableComponent
          headers={this.getTableHeaders()}
          rows={this.getTableRows()}
          noItemRow={
            <td colSpan={this.state.headers.length}>Chưa có yêu cầu</td>
          }
        />
        <FormModal
          size="lg"
          isShowModal={this.state.isOpenOrderModal}
          modalTitle={this.state.modalTitle}
          modalContent={
            this.state.targetOrder ? (
              this.getOrderContentElement()
            ) : (
              <div style={{ height: "450px" }}>
                <LoadingComponent />
              </div>
            )
          }
          closeModal={this.closeModal}
        />
        {/* </>
        ) : (
          <>
            <LoadingComponent />
          </>
        )} */}
      </>
    );
    return html;
  }
}

import React from "react";
import "./home-page.css";
//
import { Container } from "react-bootstrap";
import { BaseService } from "../services/base-service";
import { ImageSlider } from "../components/web-user-layout/image-slider";
// import { ServiceList } from '../components/web-user-layout/service-list';
import { ProductList } from "../components/web-user-layout/product-list";
// import { PolicyList } from '../components/web-user-layout/policy-list';
import { TypicalFaceStarList } from "../components/web-user-layout/typical-face-star-list";
// import { TypicalFaceDistributorList } from "../components/web-user-layout/typical-face-list-distributor";
import { VideoList } from "../components/web-user-layout/video-list";
import { EventList } from "../components/web-user-layout/event-list";
import { PartnersList } from "../components/web-user-layout/partners-list";
import { RequestStatus } from "../constants";
import { LoadingComponent } from "../components/public/loading-component";
// import home_page_title_jpg from '../assets/images/home_page_title.jpg';

export class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingCompleted: false,
    };
  }

  componentDidMount() {
    this.props.loadImageSliderImagesList();
    // this.props.loadServicesList();
    this.props.loadProductCategoryList();
    this.props.loadProductList();
    this.props.loadPolicyList();
    this.props.loadTypicalFaceList();
    this.props.loadVideoList();
    this.props.loadEventList();
    this.props.getPartnersList();
    setTimeout(() => {
      this.handleScroll();
      window.addEventListener("scroll", this.handleScroll);
    }, 0);
  }

  componentWillReceiveProps(nextProps) {
    this.checkIsPageLoadCompleted(nextProps);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  checkIsPageLoadCompleted = (props) => {
    if (!this.state.isLoadingCompleted) {
      if (
        // props.imageSliderImagesListRequestStatus === RequestStatus.RQ_SUCCESS &&
        // props.serviceListRequestStatus === RequestStatus.RQ_SUCCESS &&
        props.productCategoryListRequestStatus === RequestStatus.RQ_SUCCESS &&
        props.productListRequestStatus === RequestStatus.RQ_SUCCESS //&&
        // props.videoListRequestStatus === RequestStatus.RQ_SUCCESS &&
        // props.policyListRequestStatus === RequestStatus.RQ_SUCCESS &&
        // props.typicalFaceListRequestStatus === RequestStatus.RQ_SUCCESS &&
        // props.eventListRequestStatus === RequestStatus.RQ_SUCCESS
      ) {
        setTimeout(() => {
          this.setState({
            isLoadingCompleted: true,
          });
        }, 500);
      }
    }
  };

  handleScroll = () => {
    // const top = document.scrollingElement.scrollTop;
    // const bottom = top + BaseService.getWindowHeight();
    // //
    // if(BaseService.checkScrollToElement(bottom, 'serviceListContainerDiv', 90) === true) {
    // BaseService.addClassToElement('serviceListContainerTitleDiv', 'component-title-show-div');
    // }
    // if(BaseService.checkScrollToElement(bottom, 'serviceListContainerDiv', 300) === true) {
    // BaseService.addClassToClassItems('service-list-item-col', 'service-list-item-show-col');
    // }
    // //
    // if(BaseService.checkScrollToElement(bottom, 'productListContainerDiv', 90) === true) {
    // BaseService.addClassToElement('productListContainerTitleDiv', 'component-title-show-div');
    // }
    // if(BaseService.checkScrollToElement(bottom, 'productListContainerDiv', 300) === true) {
    // BaseService.addClassToClassItems('product-list-container-div', 'product-list-container-show-div');
    // }
    // //
    // if(BaseService.checkScrollToElement(bottom, 'policyListContainerDiv', 90) === true) {
    // BaseService.addClassToElement('policyListContainerTitleDiv', 'component-title-show-div');
    // }
    // if(BaseService.checkScrollToElement(bottom, 'policyListContainerDiv', 300) === true) {
    // BaseService.addClassToClassItems('policy-item-outer-div', 'policy-item-outer-show-div', 200);
    // }
    // //
    // if(BaseService.checkScrollToElement(bottom, 'typicalFaceListContainerDiv', 90) === true) {
    // BaseService.addClassToElement('typicalFaceListContainerTitleDiv', 'component-title-show-div');
    // }
    // if(BaseService.checkScrollToElement(bottom, 'typicalFaceListContainerDiv', 300) === true) {
    // BaseService.addClassToClassItems('typical-face-item-div', 'typical-face-item-show-div', 200);
    // }
    // //
    // if(BaseService.checkScrollToElement(bottom, 'videoListContainerDiv', 90) === true) {
    // BaseService.addClassToElement('videoListContainerTitleDiv', 'component-title-show-div');
    // }
    // if(BaseService.checkScrollToElement(bottom, 'videoListContainerDiv', 300) === true) {
    BaseService.addClassToClassItems("video-container", "video-show-container");
    // }
    // //
    // if(BaseService.checkScrollToElement(bottom, 'eventListContainerDiv', 90) === true) {
    // BaseService.addClassToElement('eventListContainerTitleDiv', 'component-title-show-div');
    // }
    // if(BaseService.checkScrollToElement(bottom, 'eventListContainerDiv', 300) === true) {
    BaseService.addClassToClassItems(
      "event-item-link",
      "event-item-show-link",
      200
    );
    // }
  };

  homepageContentRender = () => {
    const element = (
      <>
        <ImageSlider
          id="imageSliderContainerDiv"
          imageSliderImagesList={this.props.imageSliderImagesList}
          windowWidth={this.props.windowWidth}
          isHomePageImage={true}
        />
        {this.props.subImageSliderImagesList &&
        this.props.subImageSliderImagesList.length > 0 ? (
          <ImageSlider
            id="imageSliderContainerDiv"
            imageSliderImagesList={this.props.subImageSliderImagesList}
            windowWidth={this.props.windowWidth}
            isHomePageImage={true}
          />
        ) : (
          <></>
        )}
        {/* <Container fluid={true} className='service-container'>
                <Container fluid={true} className='fluid-container'>
                    <ServiceList
                        serviceListRequestStatus={this.props.serviceListRequestStatus}
                        serviceList={this.props.serviceList}
                        home_page_title_jpg={null}
                    />
                </Container>
            </Container> */}
        {/* <Container fluid={true} className='product-container'>
                <Container fluid={true} className='fluid-container'> */}
        <div className="product-container">
          <ProductList
            windowWidth={this.props.windowWidth}
            productCategoryListRequestStatus={
              this.props.productCategoryListRequestStatus
            }
            productCategoryList={this.props.productCategoryList}
            productListRequestStatus={this.props.productListRequestStatus}
            productList={this.props.productList}
            home_page_title_jpg={null}
            addProductItemToCart={this.props.addProductItemToCart}
          />
        </div>
        {/* </Container>
            </Container> */}
        {/* <Container fluid={true} className='policy-container'>
                <Container className='component-container-non-padding'>
                    <PolicyList
                        policyListRequestStatus={this.props.policyListRequestStatus}
                        policyList={this.props.policyList}
                        home_page_title_jpg={null}
                    />
                </Container>
            </Container> */}
      </>
    );
    return element;
  };

  renderHomepageContent = () => {
    const res = (
      <>
        {this.state.isLoadingCompleted ? (
          <>{this.homepageContentRender()}</>
        ) : (
          <>
            <LoadingComponent />
          </>
        )}
        <Container fluid={true} className="typical-face-container">
          <Container fluid={true} className="fluid-container">
            <TypicalFaceStarList
              windowWidth={this.props.windowWidth}
              typicalFaceListRequestStatus={
                this.props.typicalFaceListRequestStatus
              }
              typicalFaceStarList={this.props.typicalFaceStarList}
              home_page_title_jpg={null}
            />
          </Container>
        </Container>
        {/* <Container fluid={true} className="typical-face-container">
          <Container fluid={true} className="fluid-container">
            <TypicalFaceDistributorList
              windowWidth={this.props.windowWidth}
              typicalFaceListRequestStatus={
                this.props.typicalFaceListRequestStatus
              }
              typicalFaceDistributorList={this.props.typicalFaceDistributorList}
              home_page_title_jpg={null}
            />
          </Container>
        </Container> */}
        <Container fluid={true} className="event-container">
          <Container fluid={true} className="fluid-container">
            <PartnersList
              windowWidth={this.props.windowWidth}
              getPartnersListRequestStatus={
                this.props.getPartnersListRequestStatus
              }
              getPartnersListRequestResult={
                this.props.getPartnersListRequestResult
              }
              home_page_title_jpg={null}
            />
          </Container>
        </Container>
        <Container fluid={true} className="video-container">
          <Container fluid={true} className="fluid-container">
            <VideoList
              videoList={this.props.videoList}
              videoListRequestStatus={this.props.videoListRequestStatus}
              home_page_title_jpg={null}
            />
          </Container>
        </Container>
        <Container fluid={true} className="event-container">
          <Container fluid={true} className="fluid-container">
            <EventList
              eventListRequestStatus={this.props.eventListRequestStatus}
              eventList={this.props.eventList}
              home_page_title_jpg={null}
            />
          </Container>
        </Container>
      </>
    );
    return res;
  };

  render() {
    const html = <>{this.renderHomepageContent()}</>;
    return html;
  }
}

// /assets/images/commitment_background.jpg
//
// import tempImg3 from '../../assets/images/image-slider-img/Flower - 26790.mp4';
import tempImg2 from "../../assets/images/image-slider-img/cream-2817325_640.jpg";
// import tempImg1 from '../../assets/images/image-slider-img/Conversation - 180.mp4';
import tempImg4 from "../../assets/images/image-slider-img/cream-2817325_640.jpg";

// import productBackground1 from '../../assets/images/product-img/cloth-569222_640.jpg';
// import product1 from '../../assets/images/product-img/2260942.jpg';
// import product2 from '../../assets/images/product-img/507073060_Colour_507076660_1_720x928.jpg';

// import tempImg5 from '../../assets/images/service-img/service_mypham.jpg';
// import tempImg7 from '../../assets/images/service-img/service_chailo.png';
// import tempImg8 from '../../assets/images/service-img/service_nguyenlieu.jpg';
// import tempImg9 from '../../assets/images/service-img/service_thietke.png';

// import tempImg6 from '../../assets/images/profile-picture/115d95e57c47e812da47abdeb04a5c5c.jpeg';
// import tempImg18 from '../../assets/images/profile-picture/beautiful-beauty-cute-2247450.jpg';

import policyImg01 from "../../assets/images/policy-img/007180427_1-6aa1b9d5d87ec00ad942aed1a03ca094.png";
import policyImg02 from "../../assets/images/policy-img/007180427_1-6aa1b9d5d87ec00ad942aed1a03ca094.png";
import policyImg03 from "../../assets/images/policy-img/007180427_1-6aa1b9d5d87ec00ad942aed1a03ca094.png";

// import eventImg01 from '../../assets/images/event-img/wedding-1937022_1280.jpg';
// import eventImg02 from '../../assets/images/event-img/wedding-1937022_1280.jpg';
// import eventImg03 from '../../assets/images/event-img/wedding-1937022_1280.jpg';
// import eventImg04 from '../../assets/images/event-img/wedding-1937022_1280.jpg';
// import eventImg05 from '../../assets/images/event-img/wedding-1937022_1280.jpg';
// import eventImg06 from '../../assets/images/event-img/wedding-1937022_1280.jpg';
//

import { BaseService } from "../../services/base-service";
import { BannerService } from "../../services/banner-service";
import { CategoryService } from "../../services/category-service";
import { ProductService } from "../../services/product-service";
// import { EventStatus } from '../../constants';
import { TypicalFaceService } from "../../services/typical-face-service";
import { VideoService } from "../../services/video-service";
import { NewsService } from "../../services/news-service";
import { UIConfigsService } from "../../services/ui-configns-service";

export const homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_REQUEST =
  "homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_REQUEST";
export const homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_SUCCESS =
  "homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_SUCCESS";
export const homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_ERROR =
  "homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_ERROR";
export const homepage_LOAD_SERVICE_LIST_REQUEST =
  "homepage_LOAD_SERVICE_LIST_REQUEST";
export const homepage_LOAD_SERVICE_LIST_SUCCESS =
  "homepage_LOAD_SERVICE_LIST_SUCCESS";
export const homepage_LOAD_SERVICE_LIST_ERROR =
  "homepage_LOAD_SERVICE_LIST_ERROR";
export const homepage_LOAD_PRODUCT_CATEGORY_LIST_REQUEST =
  "homepage_LOAD_PRODUCT_CATEGORY_LIST_REQUEST";
export const homepage_LOAD_PRODUCT_CATEGORY_LIST_SUCCESS =
  "homepage_LOAD_PRODUCT_CATEGORY_LIST_SUCCESS";
export const homepage_LOAD_PRODUCT_CATEGORY_LIST_ERROR =
  "homepage_LOAD_PRODUCT_CATEGORY_LIST_ERROR";
export const homepage_LOAD_PRODUCT_LIST_REQUEST =
  "homepage_LOAD_PRODUCT_LIST_REQUEST";
export const homepage_LOAD_PRODUCT_LIST_SUCCESS =
  "homepage_LOAD_PRODUCT_LIST_SUCCESS";
export const homepage_LOAD_PRODUCT_LIST_ERROR =
  "homepage_LOAD_PRODUCT_LIST_ERROR";
export const homepage_LOAD_VIDEO_LIST_REQUEST =
  "homepage_LOAD_VIDEO_LIST_REQUEST";
export const homepage_LOAD_VIDEO_LIST_SUCCESS =
  "homepage_LOAD_VIDEO_LIST_SUCCESS";
export const homepage_LOAD_VIDEO_LIST_ERROR = "homepage_LOAD_VIDEO_LIST_ERROR";
export const homepage_LOAD_POLICY_LIST_REQUEST =
  "homepage_LOAD_POLICY_LIST_REQUEST";
export const homepage_LOAD_POLICY_LIST_SUCCESS =
  "homepage_LOAD_POLICY_LIST_SUCCESS";
export const homepage_LOAD_POLICY_LIST_ERROR =
  "homepage_LOAD_POLICY_LIST_ERROR";
export const homepage_LOAD_TYPICAL_FACE_LIST_REQUEST =
  "homepage_LOAD_TYPICAL_FACE_LIST_REQUEST";
export const homepage_LOAD_TYPICAL_FACE_LIST_SUCCESS =
  "homepage_LOAD_TYPICAL_FACE_LIST_SUCCESS";
export const homepage_LOAD_TYPICAL_FACE_LIST_ERROR =
  "homepage_LOAD_TYPICAL_FACE_LIST_ERROR";
export const homepage_LOAD_EVENT_LIST_REQUEST =
  "homepage_LOAD_EVENT_LIST_REQUEST";
export const homepage_LOAD_EVENT_LIST_SUCCESS =
  "homepage_LOAD_EVENT_LIST_SUCCESS";
export const homepage_LOAD_EVENT_LIST_ERROR = "homepage_LOAD_EVENT_LIST_ERROR";
export const homepage_LOAD_PARTNERS_LIST_REQUEST =
  "homepage_LOAD_PARTNERS_LIST_REQUEST";
export const homepage_LOAD_PARTNERS_LIST_SUCCESS =
  "homepage_LOAD_PARTNERS_LIST_SUCCESS";
export const homepage_LOAD_PARTNERS_LIST_FAILURE =
  "homepage_LOAD_PARTNERS_LIST_FAILURE";

export const loadImageSliderImagesList = () => {
  return (dispatch) => {
    dispatch({
      type: homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_REQUEST,
    });

    let imageSliderImagesList = [];
    let subImageSliderImagesList = [];
    const request = BannerService.getBannerList();
    if (request) {
      request.then(
        (data) => {
          if (data.length > 0) {
            const banner = data.find((t) => t.id === 1);
            if (banner) {
              if (banner.banner_items.length > 0) {
                const banner_items = BaseService.getOrderListWithName(
                  banner.banner_items
                );
                banner_items
                  .filter((t) => !t.isDeleted || t.isDeleted === false)
                  .forEach((item) => {
                    // const imageUrl =
                    //   item.image && item.image.id
                    //     ? BaseService.getImageUrlFromServer(
                    //         item.image.id,
                    //         item.image.name
                    //       )
                    //     : null;
                    const imageUrl =
                      item.image && item.image.url
                        ?
                        // BaseService.getImageLink(item.image.url)
                        BaseService.getImageUrlFromServer(item.image.id, item.image.name, item.image.url)
                        : "";
                    if (!BaseService.stringIsNullOrWhiteSpace(imageUrl)) {
                      BaseService.preLoadImage(imageUrl);
                    }

                    const smallImageUrl =
                      item.smallImage && item.smallImage.url
                        ?
                        // BaseService.getImageLink(item.smallImage.url)
                        BaseService.getImageUrlFromServer(item.smallImage.id, item.smallImage.name, item.smallImage.url)
                        : "";
                    if (!BaseService.stringIsNullOrWhiteSpace(smallImageUrl)) {
                      BaseService.preLoadImage(smallImageUrl);
                    }

                    imageSliderImagesList.push({
                      imageUrl: imageUrl,
                      smallImageUrl: smallImageUrl,
                      videoUrl: item.videoUrl,
                      linkUrl: item.linkUrl,
                    });
                  });
              }
            }

            const subBanner = data.find((t) => t.id === 5);
            if (subBanner) {
              if (subBanner.banner_items.length > 0) {
                const banner_items = BaseService.getOrderListWithName(
                  subBanner.banner_items
                );
                banner_items
                  .filter((t) => !t.isDeleted || t.isDeleted === false)
                  .forEach((item) => {
                    // const imageUrl =
                    //   item.image && item.image.id
                    //     ? BaseService.getImageUrlFromServer(
                    //         item.image.id,
                    //         item.image.name
                    //       )
                    //     : null;
                    const imageUrl =
                      item.image && item.image.url
                        ?
                        // BaseService.getImageLink(item.image.url)
                        BaseService.getImageUrlFromServer(item.image.id, item.image.name, item.image.url)
                        : "";
                    if (!BaseService.stringIsNullOrWhiteSpace(imageUrl)) {
                      BaseService.preLoadImage(imageUrl);
                    }

                    const smallImageUrl =
                      item.smallImage && item.smallImage.url
                        ?
                        // BaseService.getImageLink(item.smallImage.url)
                        BaseService.getImageUrlFromServer(item.smallImage.id, item.smallImage.name, item.smallImage.url)
                        : "";
                    if (!BaseService.stringIsNullOrWhiteSpace(smallImageUrl)) {
                      BaseService.preLoadImage(smallImageUrl);
                    }

                    subImageSliderImagesList.push({
                      imageUrl: imageUrl,
                      smallImageUrl: smallImageUrl,
                      videoUrl: item.videoUrl,
                      linkUrl: item.linkUrl,
                    });
                  });
              }
            }

            // const imageSliderImagesList = [
            //     // { url: tempImg1 },
            //     { url: tempImg2 },
            //     // { url: tempImg3 }
            // ];
            // //
          }
          setTimeout(() => {
            dispatch({
              type: homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_SUCCESS,
              payload: {
                imageSliderImagesList: imageSliderImagesList,
                subImageSliderImagesList: subImageSliderImagesList,
              },
            });
          }, 50);
        },
        (error) => {
          dispatch({
            type: homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_ERROR,
          });
        }
      );
    }

    dispatch({
      type: homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_SUCCESS,
      payload: {
        imageSliderImagesList: imageSliderImagesList,
      },
    });
  };
};

export const loadServicesList = () => {
  return (dispatch) => {
    dispatch({
      type: homepage_LOAD_SERVICE_LIST_REQUEST,
    });

    //
    const serviceList = [
      {
        id: 1,
        title: "Lorem ipsum dolor",
        description:
          "Curabitur eget lorem non arcu condimentum maximus. Ut sed tempus lacus.",
        imgURL: tempImg4,
      },
      {
        id: 2,
        title: "Lorem",
        description:
          "Curabitur eget lorem non arcu condimentum maximus. Ut sed tempus lacus. Etiam porttitor urna lorem, a feugiat tortor pharetra a.",
        imgURL: tempImg2,
      },
      {
        id: 3,
        title: "Lorem ipsum dolor",
        description:
          "Curabitur eget lorem non arcu condimentum maximus. Ut sed tempus lacus. Etiam porttitor urna lorem.",
        imgURL: tempImg4,
      },
      {
        id: 4,
        title: "Lorem ipsum dolor",
        description: "Curabitur eget lorem non arcu condimentum maximus.",
        imgURL: tempImg2,
      },
    ];
    //

    dispatch({
      type: homepage_LOAD_SERVICE_LIST_SUCCESS,
      payload: {
        serviceList: serviceList,
      },
    });
  };
};

export const loadProductCategoryList = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: homepage_LOAD_PRODUCT_CATEGORY_LIST_REQUEST,
    });

    let productCategoryList = [];
    const request = CategoryService.getCategoryList();
    if (request) {
      request.then(
        (data) => {
          data = data.filter((t) => !t.isDeleted || t.isDeleted === false);
          if (data.length > 0) {
            data.forEach(async (item, index) => {
              let tags = [];

              const tagRequestRes = await CategoryService.getHashTagByCategory(
                item
              );
              if (tagRequestRes && tagRequestRes.length > 0) {
                let allHashTags = "";
                if (tagRequestRes && tagRequestRes.length > 0) {
                  tagRequestRes.forEach((item) => {
                    allHashTags += item.trim();
                  });
                  allHashTags = BaseService.convertArrayStringToArray(
                    allHashTags,
                    "#"
                  );
                  allHashTags.forEach((item, index) => {
                    if (tags.findIndex((t) => t.title === item) === -1) {
                      tags.push({
                        id: index,
                        title: item,
                        name: item,
                      });
                    }
                  });
                  console.log("tags", tags);
                }
              }

              item.styleConfig = !BaseService.stringIsNullOrWhiteSpace(
                item.styleConfig
              )
                ? JSON.parse(item.styleConfig)
                : {
                  displayName: item.name,
                };

              productCategoryList.push({
                id: item.id,
                title: item.name,
                displayName: item.styleConfig.displayName,
                displayImage: item.styleConfig.displayImage,
                displayImageMobile: item.styleConfig.displayImageMobile,
                imgURL: item.image
                  ? BaseService.getImageUrlFromServer(
                    item.image.id,
                    item.image.name,
                    item.image.url
                  )
                  : "",
                tags: tags,
                orderNumber: item.orderNumber,
              });

              if (productCategoryList.length === data.length) {
                dispatch({
                  type: homepage_LOAD_PRODUCT_CATEGORY_LIST_SUCCESS,
                  payload: {
                    productCategoryList: productCategoryList,
                  },
                });
              }
            });
          }
        },
        (error) => {
          console.log("login - error", error);
          dispatch({
            type: homepage_LOAD_PRODUCT_CATEGORY_LIST_ERROR,
            //     payload: {
            //         errorMessage: Messages.REQUEST_ERROR
            //     }
          });
        }
      );
    }
  };
};

export const loadProductList = () => {
  return (dispatch) => {
    dispatch({
      type: homepage_LOAD_PRODUCT_LIST_REQUEST,
    });

    const request = ProductService.getProductList();
    let productList = [];
    if (request) {
      request.then(
        (data) => {
          console.log(
            "const request = ProductService.getProductList(); - data",
            data
          );
          if (data.length > 0) {
            productList = data
              .filter((t) => !t.isDeleted || t.isDeleted === false)
              .map((item) => {
                // const imgURL = item.image
                //   ? BaseService.getImageUrlFromServer(
                //       item.image.id,
                //       item.image.name
                //     )
                //   : "";
                const imgURL =
                  item.image && item.image.url
                    ?
                    // BaseService.getImageLink(item.image.url)
                    BaseService.getImageUrlFromServer(item.image.id, item.image.name, item.image.url)
                    : "";
                BaseService.preLoadImage(imgURL);

                let secondImgURL = null;
                const images = item.images;
                if (images && images.length > 0) {
                  secondImgURL = images[0].url
                    ?
                    // BaseService.getImageLink(images[0].url)
                    BaseService.getImageUrlFromServer(images[0].id, images[0].name, images[0].url)
                    : "";
                  BaseService.preLoadImage(secondImgURL);
                }

                return {
                  id: item.id,
                  name: item.name,
                  titleId: item.titleId,
                  categoryId: item.category.id,
                  categoryName: item.category.name,
                  tags:
                    item.hashTags && item.hashTags.length > 0
                      ? BaseService.convertArrayStringToArray(
                        item.hashTags,
                        "#"
                      ).toString()
                      : "",
                  price: item.price,
                  originalPrice: item.originalPrice,
                  discountPercent: item.discountPercent,
                  promoText: item.promoText,
                  imgURL: imgURL,
                  secondImgURL: secondImgURL ? secondImgURL : imgURL,
                  orderNumber: item.orderNumber,
                  isNew: item.isNew,
                  isHot: item.isHot,
                  saleInfo: !BaseService.stringIsNullOrWhiteSpace(item.saleInfo)
                    ? JSON.parse(item.saleInfo)
                    : {},
                };
              });
          }
          setTimeout(() => {
            dispatch({
              type: homepage_LOAD_PRODUCT_LIST_SUCCESS,
              payload: {
                productList: productList,
              },
            });
          }, 50);
        },
        (error) => {
          dispatch({
            type: homepage_LOAD_PRODUCT_LIST_ERROR,
            //     payload: {
            //         errorMessage: Messages.REQUEST_ERROR
            //     }
          });
        }
      );
    }
  };
};

export const loadVideoList = () => {
  return (dispatch) => {
    dispatch({
      type: homepage_LOAD_VIDEO_LIST_REQUEST,
    });

    let videoList = [];
    const request = VideoService.getVideoList();
    if (request) {
      request.then(
        (data) => {
          const list = data;
          if (list.length > 0) {
            videoList = BaseService.getOrderListWithName(
              list.filter((t) => !t.isDeleted || t.isDeleted === false)
            );
          }

          dispatch({
            type: homepage_LOAD_VIDEO_LIST_SUCCESS,
            payload: {
              videoList: videoList,
            },
          });
        },
        (error) => {
          dispatch({
            type: homepage_LOAD_VIDEO_LIST_ERROR,
          });
        }
      );
    }
  };
};

export const loadPolicyList = () => {
  return (dispatch) => {
    dispatch({
      type: homepage_LOAD_POLICY_LIST_REQUEST,
    });

    //
    const policyList = [
      {
        id: 1,
        title: "Lorem ipsum dolor sit amet",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 2,
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 3,
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 4,
        title: "Lorem ipsum dolor sit amet",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 5,
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 6,
        title: "Lorem ipsum dolor",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 7,
        title: "Lorem ipsum dolor sit amet, consectetur",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 8,
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 9,
        title: "Lorem ipsum dolor sit amet",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 10,
        title: "Lorem ipsum dolor sit amet, consectetur",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 11,
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
      {
        id: 12,
        title: "Lorem ipsum dolor sit amet",
        imgsURL: [policyImg01, policyImg02, policyImg03],
      },
    ];
    //

    dispatch({
      type: homepage_LOAD_POLICY_LIST_SUCCESS,
      payload: {
        policyList: policyList,
      },
    });
  };
};

export const loadTypicalFaceList = () => {
  return (dispatch) => {
    dispatch({
      type: homepage_LOAD_TYPICAL_FACE_LIST_REQUEST,
    });

    const request = TypicalFaceService.getTypicalFaceList();
    let typicalFaceList = [];
    if (request) {
      request.then(
        (data) => {
          console.log(
            "const request = TypicalFaceService.getTypicalFaceList(); - data",
            data
          );
          if (data.length > 0) {
            typicalFaceList = data
              .filter((t) => !t.isDeleted || t.isDeleted === false)
              .map((item) => {
                item.styleConfig = !BaseService.stringIsNullOrWhiteSpace(
                  item.styleConfig
                )
                  ? JSON.parse(item.styleConfig)
                  : {
                    displayName: item.name,
                  };
                // const imgURL = item.image
                //   ? BaseService.getImageUrlFromServer(
                //       item.image.id,
                //       item.image.name
                //     )
                //   : "";
                const imgURL =
                  item.image && item.image.url
                    ?
                    // BaseService.getImageLink(item.image.url)
                    BaseService.getImageUrlFromServer(item.image.id, item.image.name, item.image.url)
                    : "";
                BaseService.preLoadImage(imgURL);
                return {
                  id: item.id,
                  name: item.name,
                  imgURL: imgURL,
                  description: item.description,
                  isHot: item.isHot,
                  url: item.url,
                  orderNumber: item.orderNumber,
                  displayName: item.styleConfig.displayName,
                  district: !BaseService.stringIsNullOrWhiteSpace(
                    item.styleConfig.district
                  )
                    ? JSON.parse(item.styleConfig.district)
                    : null,
                };
              });
          }
          setTimeout(() => {
            dispatch({
              type: homepage_LOAD_TYPICAL_FACE_LIST_SUCCESS,
              payload: {
                typicalFaceList: typicalFaceList,
              },
            });
          }, 50);
        },
        (error) => {
          dispatch({
            type: homepage_LOAD_TYPICAL_FACE_LIST_ERROR,
          });
        }
      );
    }
  };
};

export const loadEventList = () => {
  return (dispatch) => {
    dispatch({
      type: homepage_LOAD_EVENT_LIST_REQUEST,
    });

    const request = NewsService.getNewsList();
    let newsList = [];
    if (request) {
      request.then(
        (data) => {
          console.log(
            "const request = NewsService.getNewsList(); - data",
            data
          );
          if (data.length > 0) {
            newsList = BaseService.getOrderListWithCreatedTime(
              data.filter((t) => !t.isDeleted || t.isDeleted === false)
            ).map((item) => {
              return {
                id: item.id,
                titleId: item.titleId,
                name: item.name,
                imgURL: item.image
                  ? BaseService.getImageUrlFromServer(
                    item.image.id,
                    item.image.name,
                    item.image.url
                  )
                  : "",
                description: item.description,
                isHot: item.isHot,
                url: item.url,
                orderNumber: item.orderNumber,
                createdTime: item.createdTime,
                createdTimeDisplayString:
                  BaseService.convertCreatedTimeToDateString(item.createdTime),
                createdTimeOrderString:
                  BaseService.convertCreatedTimeToDateOrderString(
                    item.createdTime
                  ),
              };
            });
          }
          dispatch({
            type: homepage_LOAD_EVENT_LIST_SUCCESS,
            payload: {
              eventList: newsList,
            },
          });
        },
        (error) => {
          dispatch({
            type: homepage_LOAD_EVENT_LIST_ERROR,
          });
        }
      );
    }
  };
};

export const getPartnersList = () => {
  return (dispatch) => {
    dispatch({
      type: homepage_LOAD_PARTNERS_LIST_REQUEST,
    });

    const request = UIConfigsService.getUIConfigsDetail(19);
    if (request) {
      request.then(
        (data) => {
          const uiConfigsDetail = data;
          let getPartnersListRequestResult = [];
          if (uiConfigsDetail.content && uiConfigsDetail.content.length > 0) {
            getPartnersListRequestResult = JSON.parse(
              uiConfigsDetail.content
            ).filter((t) => !t.isDeleted || t.isDeleted === false);
          }
          dispatch({
            type: homepage_LOAD_PARTNERS_LIST_SUCCESS,
            payload: {
              getPartnersListRequestResult: getPartnersListRequestResult,
            },
          });
        },
        (error) => {
          dispatch({
            type: homepage_LOAD_PARTNERS_LIST_FAILURE,
          });
        }
      );
    }
  };
};

import { TypicalFaceService } from "../../services/typical-face-service";
import { BaseService } from "../../services/base-service";
import { Messages } from "../../constants";

export const typicalfacepage_GET_TYPICAL_FACE_LIST_REQUEST =
  "typicalfacepage_GET_TYPICAL_FACE_LIST_REQUEST";
export const typicalfacepage_GET_TYPICAL_FACE_LIST_SUCCESS =
  "typicalfacepage_GET_TYPICAL_FACE_LIST_SUCCESS";
export const typicalfacepage_GET_TYPICAL_FACE_LIST_ERROR =
  "typicalfacepage_GET_TYPICAL_FACE_LIST_ERROR";

export const typicalfacepage_GET_TYPICAL_FACE_DETAIL_REQUEST =
  "typicalfacepage_GET_TYPICAL_FACE_DETAIL_REQUEST";
export const typicalfacepage_GET_TYPICAL_FACE_DETAIL_SUCCESS =
  "typicalfacepage_GET_TYPICAL_FACE_DETAIL_SUCCESS";
export const typicalfacepage_GET_TYPICAL_FACE_DETAIL_ERROR =
  "typicalfacepage_GET_TYPICAL_FACE_DETAIL_ERROR";

export const typicalfacepage_INSERT_TYPICAL_FACE_SUCCESS =
  "typicalfacepage_INSERT_TYPICAL_FACE_SUCCESS";
export const typicalfacepage_INSERT_TYPICAL_FACE_REQUEST =
  "typicalfacepage_INSERT_TYPICAL_FACE_REQUEST";
export const typicalfacepage_INSERT_TYPICAL_FACE_ERROR =
  "typicalfacepage_INSERT_TYPICAL_FACE_ERROR";

export const typicalfacepage_UPDATE_TYPICAL_FACE_REQUEST =
  "typicalfacepage_UPDATE_TYPICAL_FACE_REQUEST";
export const typicalfacepage_UPDATE_TYPICAL_FACE_SUCCESS =
  "typicalfacepage_UPDATE_TYPICAL_FACE_SUCCESS";
export const typicalfacepage_UPDATE_TYPICAL_FACE_ERROR =
  "typicalfacepage_UPDATE_TYPICAL_FACE_ERROR";

export const typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_REQUEST =
  "typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_REQUEST";
export const typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_SUCCESS =
  "typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_SUCCESS";
export const typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_ERROR =
  "typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_ERROR";

export const typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_REQUEST =
  "typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_REQUEST";
export const typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_SUCCESS =
  "typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_SUCCESS";
export const typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_ERROR =
  "typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_ERROR";

export const typicalfacepage_DELETE_TYPICAL_FACE_REQUEST =
  "typicalfacepage_DELETE_TYPICAL_FACE_REQUEST";
export const typicalfacepage_DELETE_TYPICAL_FACE_SUCCESS =
  "typicalfacepage_DELETE_TYPICAL_FACE_SUCCESS";
export const typicalfacepage_DELETE_TYPICAL_FACE_ERROR =
  "typicalfacepage_DELETE_TYPICAL_FACE_ERROR";

export const getTypicalFaceList = () => {
  return (dispatch) => {
    dispatch({
      type: typicalfacepage_GET_TYPICAL_FACE_LIST_REQUEST,
    });

    const request = TypicalFaceService.getTypicalFaceList();
    if (request) {
      request.then(
        (data) => {
          if (data.length > 0) {
            data = data.map((item) => {
              return {
                ...item,
                styleConfig: !BaseService.stringIsNullOrWhiteSpace(
                  item.styleConfig
                )
                  ? JSON.parse(item.styleConfig)
                  : {
                      displayName: "",
                    },
              };
            });
          }
          dispatch({
            type: typicalfacepage_GET_TYPICAL_FACE_LIST_SUCCESS,
            payload: {
              typicalFaceList: data,
            },
          });
        },
        (error) => {
          dispatch({
            type: typicalfacepage_GET_TYPICAL_FACE_LIST_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const getTypicalFaceDetail = (typicalFaceId) => {
  return (dispatch) => {
    dispatch({
      type: typicalfacepage_GET_TYPICAL_FACE_DETAIL_REQUEST,
    });

    const request = TypicalFaceService.getTypicalFaceDetail(typicalFaceId);
    if (request) {
      request.then(
        (data) => {
          const typicalFaceDetail = data;
          console.log(
            "TypicalFaceService.getTypicalFaceDetail(typicalFaceId);",
            data
          );
          if (
            !BaseService.stringIsNullOrWhiteSpace(typicalFaceDetail.styleConfig)
          ) {
            const styleConfig = JSON.parse(typicalFaceDetail.styleConfig);
            typicalFaceDetail.styleConfig = styleConfig;
          }
          dispatch({
            type: typicalfacepage_GET_TYPICAL_FACE_DETAIL_SUCCESS,
            payload: {
              typicalFaceDetail: typicalFaceDetail,
            },
          });
        },
        (error) => {
          dispatch({
            type: typicalfacepage_GET_TYPICAL_FACE_DETAIL_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const insertTypicalFace = (typicalFace) => {
  return (dispatch) => {
    dispatch({
      type: typicalfacepage_INSERT_TYPICAL_FACE_REQUEST,
    });

    const request = TypicalFaceService.insertTypicalFace(typicalFace);
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: typicalfacepage_INSERT_TYPICAL_FACE_SUCCESS,
            payload: {
              insertedTypicalFace: data,
            },
          });
        },
        (error) => {
          dispatch({
            type: typicalfacepage_INSERT_TYPICAL_FACE_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const updateTypicalFace = (typicalFace) => {
  return (dispatch) => {
    return updateTypicalFaceCore(
      dispatch,
      typicalFace,
      typicalfacepage_UPDATE_TYPICAL_FACE_REQUEST,
      typicalfacepage_UPDATE_TYPICAL_FACE_SUCCESS,
      typicalfacepage_UPDATE_TYPICAL_FACE_ERROR
    );
  };
};

export const updateTypicalFace_OrderNumber = (
  typicalFaceId,
  imageId,
  value
) => {
  return async (dispatch) => {
    return updateTypicalFaceCore(
      dispatch,
      {
        id: typicalFaceId,
        imageId: imageId,
        orderNumber: value,
      },
      typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_REQUEST,
      typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_SUCCESS,
      typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_ERROR
    );

    // const data = await TypicalFaceService.getTypicalFaceDetail(typicalFaceId);
    // if(data) {
    //     let typicalFace = TypicalFaceService.getTypicalFaceInput(data);
    //     typicalFace.orderNumber = value;
    //     return updateTypicalFaceCore(dispatch, typicalFace, typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_REQUEST, typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_SUCCESS, typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_ERROR);
    // }
  };
};

export const updateTypicalFace_IsDeleted = (typicalFaceId, imageId, value) => {
  return async (dispatch) => {
    return updateTypicalFaceCore(
      dispatch,
      {
        id: typicalFaceId,
        imageId: imageId,
        isDeleted: value,
      },
      typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_REQUEST,
      typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_SUCCESS,
      typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_ERROR
    );

    // const data = await TypicalFaceService.getTypicalFaceDetail(typicalFaceId);
    // if(data) {
    //     let typicalFace = TypicalFaceService.getTypicalFaceInput(data);
    //     typicalFace.isDeleted = value;
    //     return updateTypicalFaceCore(dispatch, typicalFace, typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_REQUEST, typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_SUCCESS, typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_ERROR);
    // }
  };
};

const updateTypicalFaceCore = (
  dispatch,
  typicalFace,
  requestStatus,
  successStatus,
  errorStatus
) => {
  dispatch({
    type: requestStatus,
  });

  const request = TypicalFaceService.updateTypicalFace(typicalFace);
  if (request) {
    request.then(
      (data) => {
        dispatch({
          type: successStatus,
          payload: {
            updatedTypicalFace: data,
          },
        });
      },
      (error) => {
        dispatch({
          type: errorStatus,
          payload: {
            errorMessage: Messages.REQUEST_ERROR,
          },
        });
      }
    );
  }
};

export const deleteTypicalFace = (typicalFace) => {
  return (dispatch) => {
    dispatch({
      type: typicalfacepage_DELETE_TYPICAL_FACE_REQUEST,
    });

    const request = TypicalFaceService.deleteTypicalFace(typicalFace);
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: typicalfacepage_DELETE_TYPICAL_FACE_SUCCESS,
            payload: {
              deletedTypicalFace: typicalFace,
            },
          });
        },
        (error) => {
          dispatch({
            type: typicalfacepage_DELETE_TYPICAL_FACE_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

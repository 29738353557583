import React from "react";
import "./admin-category-detail.css";
//
import { withRouter } from "react-router";
import { withFormik } from "formik";
import { Row, Col } from "react-bootstrap";
import {
  FormTextInput,
  // FormTextAreaInput,
  FormImageUploader,
  FormButton,
  FormSunEditor,
} from "../public/form-components";
import { BaseService } from "../../services/base-service";
import { CategoryService } from "../../services/category-service";
import { RequestStatus } from "../../constants";

class FormikAdminCategoryDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      originCategory: this.props.category,
      categoryNameTimerId: BaseService.getRandomString(),
    };
  }

  // componentWillReceiveProps(nextProps) {
  //     console.log('FormikAdminCategoryDetail - componentWillReceiveProps - nextProps', nextProps);
  // }

  trackIsDataHasUpdatedTimeout = null;
  trackIsDataHasUpdated = () => {
    clearTimeout(this.trackIsDataHasUpdatedTimeout);
    this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
      let isDataHasUpdated = true; //false;
      // if(
      //     this.state.originCategory && this.props.values.category && (
      //         BaseService.compareAreStringsDifferent(this.props.values.category.name, this.state.originCategory.name) === true ||
      //         BaseService.compareAreStringsDifferent(
      //             (!this.props.values.category.description || this.props.values.category.description === '<p><br></p>')?(''):(this.props.values.category.description),
      //             (!this.state.originCategory.description || this.state.originCategory.description === '<p><br></p>')?(''):(this.state.originCategory.description)
      //         ) === true ||
      //         BaseService.compareAreImagesDifferent((this.props.values.category.image && this.props.values.category.image.id)?(this.props.values.category.image):('none'), this.state.originCategory.image) === true
      //     )
      // ) {
      //     isDataHasUpdated = true;
      // }
      this.props.trackIsDataHasUpdated(isDataHasUpdated);
    }, 100);
  };

  changeCategoryName = (value) => {
    const titleId = BaseService.transformVNIToHyphenCase(value);
    this.props.setFieldValue("category.name", value);
    this.props.setFieldValue("category.titleId", titleId);
    this.trackIsDataHasUpdated();
    BaseService.clearTimer(this.state.categoryNameTimerId);
    BaseService.setTimmer(this.state.categoryNameTimerId, () => {
      // console.log(value);
      this.props.checkTitleIsAvailable(titleId);
    });
  };

  blurCategoryName = (e) => {
    this.props.setFieldTouched("errorCategoryName", true);
  };

  changeCategoryTitleId = (value) => {
    this.props.setFieldValue("category.titleId", value);
    this.trackIsDataHasUpdated();
    BaseService.clearTimer(this.state.categoryNameTimerId);
    BaseService.setTimmer(this.state.categoryNameTimerId, () => {
      // console.log(value);
      this.props.checkTitleIsAvailable(value);
    });
  };

  blurCategoryTitleId = (e) => {
    this.props.setFieldTouched("errorCategoryTitleId", true);
  };

  changeCategoryDisplayName = (value) => {
    let styleConfig = this.props.values.category.styleConfig;
    if (!styleConfig) styleConfig = {};
    const originStyleConfig = { ...styleConfig };
    styleConfig.displayName = value;
    this.props.setFieldValue("category.styleConfig", styleConfig);
    if (value !== originStyleConfig.displayName) {
      this.trackIsDataHasUpdated();
    }
  };

  onChangeDisplayImageInput = (res) => {
    let styleConfig = this.props.values.category.styleConfig;
    if (!styleConfig) styleConfig = {};
    const originStyleConfig = { ...styleConfig };
    const imageId = res ? res.data.id : null;
    const imageName = res ? res.data.name : null;
    styleConfig.displayImage = {
      id: imageId,
      name: imageName,
    };
    this.props.setFieldValue("category.styleConfig", styleConfig);
    if (
      JSON.stringify(styleConfig.displayImage) !==
      JSON.stringify(originStyleConfig.displayImage)
    ) {
      this.trackIsDataHasUpdated();
    }
  };

  onChangeDisplayImageMobileInput = (res) => {
    let styleConfig = this.props.values.category.styleConfig;
    if (!styleConfig) styleConfig = {};
    const originStyleConfig = { ...styleConfig };
    const imageId = res ? res.data.id : null;
    const imageName = res ? res.data.name : null;
    styleConfig.displayImageMobile = {
      id: imageId,
      name: imageName,
    };
    this.props.setFieldValue("category.styleConfig", styleConfig);
    if (
      JSON.stringify(styleConfig.displayImageMobile) !==
      JSON.stringify(originStyleConfig.displayImageMobile)
    ) {
      this.trackIsDataHasUpdated();
    }
  };

  blurCategoryDisplayName = () => {
    this.props.setFieldTouched("errorCategoryDisplayName", true);
  };

  changeCategoryDescription = (value) => {
    this.props.setFieldValue("category.description", value);
    if (value !== this.props.category.description) {
      this.trackIsDataHasUpdated();
    }
  };

  onChangeImageInput = (res) => {
    const imageId = res ? res.data.id : null;
    const imageName = res ? res.data.name : null;
    this.props.setFieldValue("category.image.id", imageId);
    this.props.setFieldValue("category.image.name", imageName);
    this.trackIsDataHasUpdated();
  };

  save = () => {
    if (this.props.insertCategoryRequestStatus === RequestStatus.RQ_REQUEST)
      return;
    // console.log('save', this.props.values.category);
    this.props.handleSubmit();
  };

  render() {
    const html = (
      <>
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <FormImageUploader
                  labelText="Ảnh bìa"
                  guide="(1920x757, < 5MB)"
                  type="category-background"
                  // imgUrl={(!BaseService.stringIsNullOrWhiteSpace())?(`http://${this.props.values.category.image_url}`):(null)}
                  imgUrl={
                    this.props.values.category.image
                      ? BaseService.getImageUrlFromServer(
                        this.props.values.category.image.id,
                        this.props.values.category.image.name,
                        this.props.values.category.image.url
                      )
                      : null
                  }
                  onChangeImageInput={this.onChangeImageInput}
                />
              </Col>
            </Row>
            <br />
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <FormTextInput
                  labelText="Tên"
                  value={this.props.values.category.name}
                  onChange={this.changeCategoryName}
                  onBlur={this.blurCategoryName}
                  error={
                    this.props.touched.errorCategoryName &&
                      this.props.errors.errorCategoryName
                      ? this.props.errors.errorCategoryName
                      : null
                  }
                  isRequired={true}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormTextInput
                  labelText="Slug thương hiệu"
                  value={this.props.values.category.titleId}
                  onChange={this.changeCategoryTitleId}
                  onBlur={this.blurCategoryTitleId}
                  error={
                    // this.props.touched.errorCategoryTitleId &&
                    this.props.errors.errorCategoryTitleId
                      ? this.props.errors.errorCategoryTitleId
                      : null
                  }
                  isRequired={true}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormSunEditor
                  labelText="Tên hiển thị"
                  data={
                    this.props.values.category.styleConfig &&
                      this.props.values.category.styleConfig.displayName
                      ? this.props.values.category.styleConfig.displayName
                      : ""
                  }
                  onChange={this.changeCategoryDisplayName}
                  onBlur={this.blurCategoryDisplayName}
                  error={
                    this.props.touched.errorCategoryDisplayName &&
                      this.props.errors.errorCategoryDisplayName
                      ? this.props.errors.errorCategoryDisplayName
                      : null
                  }
                  height={"180"}
                  isRequired={true}
                />
                <br />
                <i>hoặc</i>
                <br />
                <br />
                <FormImageUploader
                  labelText="Hình hiện thị"
                  type="category-background"
                  imgUrl={
                    this.props.values.category.styleConfig &&
                      this.props.values.category.styleConfig.displayImage &&
                      !BaseService.stringIsNullOrWhiteSpace(
                        this.props.values.category.styleConfig.displayImage.name
                      )
                      ? BaseService.getImageUrlFromServer(
                        this.props.values.category.styleConfig.displayImage
                          .id,
                        this.props.values.category.styleConfig.displayImage
                          .name,
                        this.props.values.category.styleConfig.displayImage
                          .url
                      )
                      : null
                  }
                  onChangeImageInput={this.onChangeDisplayImageInput}
                />
                <br />
                <FormImageUploader
                  labelText="Hình hiện thị (Mobile)"
                  type="category-background"
                  imgUrl={
                    this.props.values.category.styleConfig &&
                      this.props.values.category.styleConfig.displayImageMobile &&
                      !BaseService.stringIsNullOrWhiteSpace(
                        this.props.values.category.styleConfig.displayImageMobile.name
                      )
                      ? BaseService.getImageUrlFromServer(
                        this.props.values.category.styleConfig.displayImageMobile
                          .id,
                        this.props.values.category.styleConfig.displayImageMobile
                          .name,
                        this.props.values.category.styleConfig.displayImageMobile
                          .url
                      )
                      : null
                  }
                  onChangeImageInput={this.onChangeDisplayImageMobileInput}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <label className="form-components-text-input-label">
                  Ghi Chú
                </label>
                <FormSunEditor
                  data={
                    this.props.values.category.description
                      ? this.props.values.category.description
                      : ""
                  }
                  onChange={this.changeCategoryDescription}
                // isSimple={true}
                />
              </Col>
            </Row>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormButton
              type="save"
              onClick={this.save}
              isRequesting={
                this.props.insertCategoryRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                  this.props.updateCategoryRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
              disabled={
                this.props.insertCategoryRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                  this.props.updateCategoryRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
            />
          </Col>
        </Row>
      </>
    );
    return html;
  }
}

const myEnhancedForm = withFormik({
  enableReinitialize: true,

  mapPropsToValues: (props) => {
    console.log("mapPropsToValues - props", props);
    let values = {
      category: props.category,
      errorCategoryName: null,
      errorCategoryTitleId: null,
      errorCategoryDisplayName: null,
    };
    return values;
  },

  validate: (values, props) => {
    const errors = {};

    if (BaseService.stringIsNullOrWhiteSpace(values.category.name)) {
      errors.errorCategoryName = "Tên thương hiệu không được rỗng";
    }

    if (BaseService.stringIsNullOrWhiteSpace(values.category.titleId)) {
      errors.errorCategoryTitleId = "Slug thương hiệu không được rỗng";
    }

    if (
      values.category.name !== props.categoryDetail_originName &&
      props.titleIsAvailableRequestResult === false
    ) {
      errors.errorCategoryTitleId = "Slug đã tồn tại trước đó";
    }

    if (
      !values.category.styleConfig ||
      BaseService.stringIsNullOrWhiteSpace(
        values.category.styleConfig.displayName
      ) ||
      values.category.styleConfig.displayName === "<p><br></p>"
    ) {
      errors.errorCategoryDisplayName = "Tên hiển thị không được rỗng";
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    console.log("values", values);
    let category = CategoryService.getCategoryInput(values.category);
    category.titleId = BaseService.stringIsNullOrWhiteSpace(
      values.category.titleId
    )
      ? BaseService.transformVNIToHyphenCase(values.category.name)
      : values.category.titleId;
    // const category = {
    //     id: values.category.id,
    //     name: values.category.name,
    //     description: values.category.description,
    //     imageId: values.category.image.id
    // };
    props.save(category);
  },

  displayName: "FormikAdminCategoryDetail",
});

export const AdminCategoryDetail = withRouter(
  myEnhancedForm(FormikAdminCategoryDetail)
);

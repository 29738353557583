import { connect } from "react-redux";
import { CartPage } from "../pages/cart-page";
import { webUserLayoutSelectors } from "../redux/selectors";
import { webUserLayoutActions } from "../redux/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    windowWidth: ownProps.windowWidth,
    productItemsInCart: webUserLayoutSelectors.getProductItemsInCart(state),
    productItemsInCart_GetRequestStatus: webUserLayoutSelectors.getProductItemsInCart_GetRequestStatus(
      state
    ),
    productItemsInCart_UpdateRequestStatus: webUserLayoutSelectors.getProductItemsInCart_UpdateRequestStatus(
      state
    ),
    insertSaleRequestsRequestStatus: webUserLayoutSelectors.getInsertSaleRequestsRequestStatus(
      state
    ),
    insertSaleRequestsRequestResult: webUserLayoutSelectors.getInsertSaleRequestsRequestResult(
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  insertSaleRequests: (saleRequests) => {
    dispatch(webUserLayoutActions.insertSaleRequests(saleRequests));
  },

  updateProductItemInCart: (productItemsInCart) => {
    dispatch(webUserLayoutActions.updateProductItemInCart(productItemsInCart));
  },
});

const CartPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartPage);

export default CartPageContainer;

import React from "react";
import "./web-user-layout.css";
//
import { withRouter, Link } from "react-router-dom";
import { Header } from "../components/web-user-layout/header";
import { Footer } from "../components/web-user-layout/footer";
import { BaseService } from "../services/base-service";
import {
  FormModal,
  FormTextInput,
  FormButton,
} from "../components/public/form-components";
import { RequestStatus } from "../constants";
import { Col, Row } from "react-bootstrap";
import { SaleRequestsService } from "../services/sale-requests-service";
// import { ZaloChatbox } from '../components/web-user-layout/zalo-chatbox';
import shopeeIcon from '../assets/images/icons/shopee-48.png';
import facebookIcon from '../assets/images/icons/facebook-48.png';
import youtubeIcon from '../assets/images/icons/youtube-48.png';

class WebUserLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isGroupButtonsOpened: false,
      top: 0,
      isOpenAddProductToCartModal: false,
      customerPhone: "",
      customerPhoneTouch: false,
      customerPhoneError: null,
      customerPhoneErrorMessage: "Xin vui lòng để lại số điện thoại",
      target: "_blank",
    };
  }

  componentDidMount() {
    this.props.loadNavMenu();
    this.props.loadFooterInfo();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
    this.unlisten = this.props.history.listen((location, action) => {
      BaseService.jumpToTopLayout();
    });
    this.props.getProductItemsInCart();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
    this.unlisten();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.productItemsInCart_AddRequestStatus !==
        nextProps.productItemsInCart_AddRequestStatus &&
      nextProps.productItemsInCart_AddRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState({
        isOpenAddProductToCartModal: true,
        customerPhoneTouch: false,
        customerPhoneError: null,
      });
    }

    if (
      this.props.insertSaleRequestsRequestStatus !==
        nextProps.insertSaleRequestsRequestStatus &&
      nextProps.insertSaleRequestsRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState(
        {
          isOpenAddProductToCartModal: false,
        },
        () => {
          const productItem = this.props.productItem;
          if (productItem) {
            let productItemsInCart = this.props.productItemsInCart;
            const idx = this.props.productItemsInCart.findIndex(
              (t) => t.productItem.id === productItem.id
            );
            if (idx !== -1) {
              productItemsInCart.splice(idx, 1);
              this.props.updateProductItemInCart(productItemsInCart);
            }
          }
        }
      );
    }
  }

  setZaloTopPosition = () => {
    const zaloChatButton = document.getElementsByClassName("zalo-chat-widget");
    if (zaloChatButton && zaloChatButton[0]) {
      const webUserLayoutScrollOptionDivHeight = document.getElementById(
        "webUserLayoutScrollOptionDiv"
      ).offsetHeight;
      zaloChatButton[0].style.setProperty(
        "bottom",
        `${webUserLayoutScrollOptionDivHeight}px`
      );
      zaloChatButton[0].style.setProperty("right", `0px`);
    }
  };

  handleResize = () => {
    this.setZaloTopPosition();
  };

  handleScroll = () => {
    this.setZaloTopPosition();
    const top = document.scrollingElement.scrollTop;
    this.setState({
      top: top,
    });
    const userLayoutHeaderContainerDiv = document.getElementById(
      "userLayoutHeaderContainerDiv"
    );
    if (userLayoutHeaderContainerDiv) {
      if (top > 0) {
        userLayoutHeaderContainerDiv.classList.add(
          "header-container-fixed-div"
        );
      } else {
        userLayoutHeaderContainerDiv.classList.remove(
          "header-container-fixed-div"
        );
      }
    }
    //
    // const userLayoutHeaderContainerDiv = document.getElementById('userLayoutHeaderContainerDiv');
    // if(userLayoutHeaderContainerDiv) {
    //     if(top > userLayoutHeaderContainerDiv.offsetHeight) {
    //         userLayoutHeaderContainerDiv.classList.add('header-container-fixed-div');
    //     } else {
    //         userLayoutHeaderContainerDiv.classList.remove('header-container-fixed-div');
    //     }
    // }
  };

  scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  openGroupButtons = () => {
    this.setState({
      isGroupButtonsOpened: !this.state.isGroupButtonsOpened,
    });
  };

  getWebUserLayoutContentElement = () => {
    const element = (
      <>
        <div
          id="webUserLayoutContainerDiv"
          className="web-user-layout-container-div"
        >
          <Header
            windowWidth={this.props.windowWidth}
            navMenuList={this.props.navMenuList}
            productItemsInCart={this.props.productItemsInCart}
            productItemsInCart_LastestModifyTime={
              this.props.productItemsInCart_LastestModifyTime
            }
            footerInfoData={this.props.footerInfoData}
          />
          <div
            id="userLayoutContentDiv"
            className="web-user-layout-content-div"
          >
            {/* <div className='web-user-layout-social-div'>
                        <div><ZaloChatbox /></div>
                    </div> */}
            {this.props.bodyContent}
          </div>
          <Footer
            windowWidth={this.props.windowWidth}
            footerInfoData={this.props.footerInfoData}
          />
          <div
            id="webUserLayoutScrollOptionDiv"
            className={`web-user-layout-scroll-option-div${
              this.state.top > 120
                ? " web-user-layout-scroll-option-div-show"
                : ""
            }`}
          >
            <div
              id="webUserLayoutScrollOptionButtonsDiv"
              className="web-user-layout-scroll-option-buttons-div"
            >
              {/* <div className={`web-user-layout-scroll-option-button-group-div${(this.state.isGroupButtonsOpened)?(' web-user-layout-scroll-option-button-opened-group-div'):('')}`}>
                            <div className='web-user-layout-scroll-option-button-div web-user-layout-scroll-option-phone-button-div'>
                                <span className='web-user-layout-scroll-option-button-title'>0773 588 999</span>
                                <i className='fas fa-phone web-user-layout-scroll-option-button-i'></i>
                            </div>
                            <div className='web-user-layout-scroll-option-button-div web-user-layout-scroll-option-facebook-button-div'>
                                <span className='web-user-layout-scroll-option-button-title'>Trang Facebook</span>
                                <i className='fab fa-facebook web-user-layout-scroll-option-button-i'></i>
                            </div>
                            <div className='web-user-layout-scroll-option-button-div web-user-layout-scroll-option-youtube-button-div'>
                                <span className='web-user-layout-scroll-option-button-title'>Trang Youtube</span>
                                <i className='fab fa-youtube web-user-layout-scroll-option-button-i'></i>
                            </div>
                            <div className='web-user-layout-scroll-option-button-div web-user-layout-scroll-option-main-button-div'
                            onClick={this.openGroupButtons}>
                                <i className='fas fa-plus-circle web-user-layout-scroll-option-button-i'></i>
                            </div>
                        </div> */}
              <div className="web-user-layout-scroll-option-button-div">
                <div>
                  <i
                    className="far fa-arrow-alt-circle-up web-user-layout-scroll-option-button-i"
                    onClick={this.scrollToTop}
                  ></i>
                </div>
              </div>
            </div>
            <div className="web-user-layout-scroll-option-bar">
              <div className="top-branch-bar-hotline-number-div top-branch-bar-hotline-number-first-div">
                Tư vấn da:{" "}
                <a
                  className="top-branch-bar-hotline-number-a"
                  href="tel:+84773588999"
                  title="Bấm để gọi cho chúng tôi."
                >
                  0773 588 999
                </a>
              </div>
              {/* <div className="top-branch-bar-hotline-number-div">
                <i
                  className="fab fa-facebook top-branch-hotline-social-i top-branch-hotline-social-facebook-i"
                  title="Trang Facebook."
                ></i>
              </div>
              <div className="top-branch-bar-hotline-number-div">
                <i
                  className="fab fa-youtube top-branch-hotline-social-i top-branch-hotline-social-youtube-i"
                  title="Trang Youtube."
                ></i>
              </div> */}
              {this.renderFacebookLink()}
              {this.renderYoutubeLink()}
              {this.renderShopeeLink()}
            </div>
          </div>
        </div>
      </>
    );
    return element;
  };

  renderShopeeLink = () => {
    let element = <></>;
    if (this.props.footerInfoData && this.props.footerInfoData.socialLinks) {
      const footerInfoData = this.props.footerInfoData;
      if (footerInfoData.socialLinks[4]) {
        element = (
          <div className="top-branch-bar-hotline-number-div">
            {/* <i
              className="fab fa-youtube top-branch-hotline-social-i top-branch-hotline-social-youtube-i"
              title="Trang Youtube."
            ></i> */}
            <a
              href={footerInfoData.socialLinks[4].path}
              target={this.state.target}
            >
              <img className='top-branch-hotline-social-i' src={shopeeIcon} alt="" />
            </a>
          </div>
        );
      }
    }
    return element;
  };
  
  renderFacebookLink = () => {
    let element = <></>;
    if (this.props.footerInfoData && this.props.footerInfoData.socialLinks) {
      const footerInfoData = this.props.footerInfoData;
      if (footerInfoData.socialLinks[0]) {
        element = (
          <div className="top-branch-bar-hotline-number-div">
            {/* <i
              className="fab fa-youtube top-branch-hotline-social-i top-branch-hotline-social-youtube-i"
              title="Trang Youtube."
            ></i> */}
            <a
              href={`https://www.facebook.com/${footerInfoData.socialLinks[0].path}`}
              target={this.state.target}
            >
              <img className='top-branch-hotline-social-i' src={facebookIcon} alt="" />
            </a>
          </div>
        );
      }
    }
    return element;
  };

  renderYoutubeLink = () => {
    let element = <></>;
    if (this.props.footerInfoData && this.props.footerInfoData.socialLinks) {
      const footerInfoData = this.props.footerInfoData;
      if (footerInfoData.socialLinks[1]) {
        element = (
          <div className="top-branch-bar-hotline-number-div">
            {/* <i
              className="fab fa-youtube top-branch-hotline-social-i top-branch-hotline-social-youtube-i"
              title="Trang Youtube."
            ></i> */}
            <a
              href={footerInfoData.socialLinks[1].path}
              target={this.state.target}
            >
              <img className='top-branch-hotline-social-i' src={youtubeIcon} alt="" />
            </a>
          </div>
        );
      }
    }
    return element;
  };

  closeAddProductToCartModal = () => {
    this.setState({
      isOpenAddProductToCartModal: false,
    });
  };

  validCustomerPhone = (callBack) => {
    this.setState(
      {
        customerPhoneError: BaseService.stringIsNullOrWhiteSpace(
          this.state.customerPhone
        )
          ? this.state.customerPhoneErrorMessage
          : null,
      },
      () => {
        if (callBack) callBack();
      }
    );
  };

  handleOnChangeCustomerPhone = (value) => {
    this.setState(
      {
        customerPhone: value,
        customerPhoneTouch: true,
      },
      () => {
        this.validCustomerPhone();
      }
    );
  };

  handleOnClickSendProductRequest = () => {
    this.setState(
      {
        customerPhoneTouch: true,
      },
      () => {
        this.validCustomerPhone(() => {
          if (!this.state.customerPhoneError) {
            const saleRequestsTypeInfo = SaleRequestsService.getSaleRequestsTypeInfo(
              "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM"
            );
            let saleRequestsInput = SaleRequestsService.getNewSaleRequests();
            saleRequestsInput.name = saleRequestsTypeInfo.text;
            saleRequestsInput.content = JSON.stringify({
              products: [this.props.productItem],
              customer: {
                phone: this.state.customerPhone,
              },
            });
            saleRequestsInput.type = saleRequestsTypeInfo.value;
            saleRequestsInput.status = BaseService.getRecordStatus(0);
            this.props.insertSaleRequests(saleRequestsInput);
          }
        });
      }
    );
  };

  getModalContentElement = () => {
    const element = (
      <>
        <FormModal
          isShowModal={this.state.isOpenAddProductToCartModal}
          modalTitle="Cảm ơn bạn đã tin dùng Grace Face Việt Nam"
          modalContent={
            <>
              {this.props.productItem ? (
                <>
                  <Row>
                    <Col md={4}>
                      <img
                        alt=""
                        className="web-user-layout-add-product-to-card-modal-img"
                        src={this.props.productItem.imgURL}
                      />
                    </Col>
                    <Col md={8}>
                      <div>
                        <b>{this.props.productItem.name}</b>
                      </div>
                      <div className="web-user-layout-add-product-to-card-modal-status-div">
                        *** Thêm vào giỏ hàng thành công
                      </div>
                      <br />
                      <div>
                        Đội Ngũ Chuyên Viên Tư Vấn Da Sẵn Sàng Liên Hệ Tư Vấn Da
                        Và Hướng Dẫn Sử Dụng Sản Phẩm Ngay Cho Bạn.
                      </div>
                      <br />
                      <div className="web-user-layout-add-product-to-card-modal-customer-phone-number-div">
                        <FormTextInput
                          // labelText="Số điện thoại của bạn"
                          placeholder="Số điện thoại của bạn"
                          value={this.state.customerPhone}
                          onChange={this.handleOnChangeCustomerPhone}
                          error={
                            this.state.customerPhoneTouch &&
                            this.state.customerPhoneError
                              ? this.state.customerPhoneError
                              : null
                          }
                          isRequired={true}
                        />
                      </div>
                      <div className="web-user-layout-add-product-to-card-modal-customer-phone-number-submit-div">
                        <FormButton
                          faIconClass="far fa-paper-plane"
                          text={
                            this.props.insertSaleRequestsRequestStatus ===
                            RequestStatus.RQ_REQUEST
                              ? "Đang gửi ..."
                              : "Gửi"
                          }
                          onClick={this.handleOnClickSendProductRequest}
                          // isRequesting={
                          //   this.props.insertBannerRequestStatus ===
                          //     RequestStatus.RQ_REQUEST ||
                          //   this.props.updateBannerRequestStatus ===
                          //     RequestStatus.RQ_REQUEST
                          //     ? true
                          //     : false
                          // }
                          // disabled={
                          //   this.props.insertBannerRequestStatus ===
                          //     RequestStatus.RQ_REQUEST ||
                          //   this.props.updateBannerRequestStatus ===
                          //     RequestStatus.RQ_REQUEST
                          //     ? true
                          //     : false
                          // }
                        />
                        <Link
                          to="/gio-hang"
                          title="Đi tới giỏ hàng"
                          className="web-user-layout-add-product-to-card-modal-customer-phone-number-submit-cart-page-link"
                        >
                          Đi tới giỏ hàng
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <br />
                </>
              ) : (
                <></>
              )}
            </>
          }
          closeText="Tiếp tục mua sắm"
          closeModal={this.closeAddProductToCartModal}
        />
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        {this.getWebUserLayoutContentElement()}
        {this.getModalContentElement()}
      </>
    );
    return html;
  }
}

export default withRouter(WebUserLayout);

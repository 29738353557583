import { CategoryService } from "../../services/category-service";
import { Messages } from "../../constants";
import { BaseService } from "../../services/base-service";

export const categorypage_GET_CATEGORY_LIST_REQUEST =
  "categorypage_GET_CATEGORY_LIST_REQUEST";
export const categorypage_GET_CATEGORY_LIST_SUCCESS =
  "categorypage_GET_CATEGORY_LIST_SUCCESS";
export const categorypage_GET_CATEGORY_LIST_ERROR =
  "categorypage_GET_CATEGORY_LIST_ERROR";

export const categorypage_GET_CATEGORY_DETAIL_REQUEST =
  "categorypage_GET_CATEGORY_DETAIL_REQUEST";
export const categorypage_GET_CATEGORY_DETAIL_SUCCESS =
  "categorypage_GET_CATEGORY_DETAIL_SUCCESS";
export const categorypage_GET_CATEGORY_DETAIL_ERROR =
  "categorypage_GET_CATEGORY_DETAIL_ERROR";

export const categorypage_INSERT_CATEGORY_REQUEST =
  "categorypage_INSERT_CATEGORY_REQUEST";
export const categorypage_INSERT_CATEGORY_SUCCESS =
  "categorypage_INSERT_CATEGORY_SUCCESS";
export const categorypage_INSERT_CATEGORY_ERROR =
  "categorypage_INSERT_CATEGORY_ERROR";

export const categorypage_UPDATE_CATEGORY_REQUEST =
  "categorypage_UPDATE_CATEGORY_REQUEST";
export const categorypage_UPDATE_CATEGORY_SUCCESS =
  "categorypage_UPDATE_CATEGORY_SUCCESS";
export const categorypage_UPDATE_CATEGORY_ERROR =
  "categorypage_UPDATE_CATEGORY_ERROR";

export const categorypage_UPDATE_CATEGORY_ORDER_NUMBER_REQUEST =
  "categorypage_UPDATE_CATEGORY_ORDER_NUMBER_REQUEST";
export const categorypage_UPDATE_CATEGORY_ORDER_NUMBER_SUCCESS =
  "categorypage_UPDATE_CATEGORY_ORDER_NUMBER_SUCCESS";
export const categorypage_UPDATE_CATEGORY_ORDER_NUMBER_ERROR =
  "categorypage_UPDATE_CATEGORY_ORDER_NUMBER_ERROR";

export const categorypage_UPDATE_CATEGORY_IS_DELETED_REQUEST =
  "categorypage_UPDATE_CATEGORY_IS_DELETED_REQUEST";
export const categorypage_UPDATE_CATEGORY_IS_DELETED_SUCCESS =
  "categorypage_UPDATE_CATEGORY_IS_DELETED_SUCCESS";
export const categorypage_UPDATE_CATEGORY_IS_DELETED_ERROR =
  "categorypage_UPDATE_CATEGORY_IS_DELETED_ERROR";

export const categorypage_DELETE_CATEGORY_REQUEST =
  "categorypage_DELETE_CATEGORY_REQUEST";
export const categorypage_DELETE_CATEGORY_SUCCESS =
  "categorypage_DELETE_CATEGORY_SUCCESS";
export const categorypage_DELETE_CATEGORY_ERROR =
  "categorypage_DELETE_CATEGORY_ERROR";

export const getCategoryList = () => {
  return async (dispatch) => {
    dispatch({
      type: categorypage_GET_CATEGORY_LIST_REQUEST,
    });

    let productCategoryList = [];
    const request = CategoryService.getCategoryList();
    if (request) {
      request.then(
        async (data) => {
          if (data.length > 0) {
            data.forEach(async (item, index) => {
              let tags = [];

              const tagRequestRes = await CategoryService.getHashTagByCategory(
                item
              );
              if (tagRequestRes && tagRequestRes.length > 0) {
                let allHashTags = "";
                if (tagRequestRes && tagRequestRes.length > 0) {
                  tagRequestRes.forEach((item) => {
                    allHashTags += item.trim();
                  });
                  allHashTags = BaseService.convertArrayStringToArray(
                    allHashTags,
                    "#"
                  );
                  allHashTags.forEach((item, index) => {
                    if (tags.findIndex((t) => t.title === item) === -1) {
                      tags.push({
                        id: index,
                        title: item,
                        name: item,
                      });
                    }
                  });
                  console.log("tags", tags);
                }
              }

              productCategoryList.push({
                id: item.id,
                name: item.name,
                titleId: item.titleId,
                isDeleted: item.isDeleted,
                orderNumber: item.orderNumber,
                tags: tags,
                styleConfig: !BaseService.stringIsNullOrWhiteSpace(
                  item.styleConfig
                )
                  ? JSON.parse(item.styleConfig)
                  : {},
              });

              if (productCategoryList.length === data.length) {
                dispatch({
                  type: categorypage_GET_CATEGORY_LIST_SUCCESS,
                  payload: {
                    categoryList: productCategoryList,
                  },
                });
              }
            });
          } else {
            dispatch({
              type: categorypage_GET_CATEGORY_LIST_SUCCESS,
              payload: {
                categoryList: productCategoryList,
              },
            });
          }
        },
        (error) => {
          console.log("login - error", error);
          dispatch({
            type: categorypage_GET_CATEGORY_LIST_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const getCategoryDetailByTitleId = (titleId) => {
  return (dispatch) => {
    dispatch({
      type: categorypage_GET_CATEGORY_DETAIL_REQUEST,
    });

    const request = CategoryService.getCategoryDetailByTitleId(titleId);
    if (request) {
      request.then(
        (data) => {
          const categoryDetail = data;
          if (
            !BaseService.stringIsNullOrWhiteSpace(categoryDetail.styleConfig)
          ) {
            const styleConfig = JSON.parse(categoryDetail.styleConfig);
            categoryDetail.styleConfig = styleConfig;
          }
          dispatch({
            type: categorypage_GET_CATEGORY_DETAIL_SUCCESS,
            payload: {
              categoryDetail: categoryDetail,
            },
          });
        },
        (error) => {
          dispatch({
            type: categorypage_GET_CATEGORY_DETAIL_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const getCategoryDetail = (categoryId) => {
  return (dispatch) => {
    dispatch({
      type: categorypage_GET_CATEGORY_DETAIL_REQUEST,
    });

    const request = CategoryService.getCategoryDetail(categoryId);
    if (request) {
      request.then(
        (data) => {
          const categoryDetail = data;
          if (
            !BaseService.stringIsNullOrWhiteSpace(categoryDetail.styleConfig)
          ) {
            const styleConfig = JSON.parse(categoryDetail.styleConfig);
            categoryDetail.styleConfig = styleConfig;
          }
          dispatch({
            type: categorypage_GET_CATEGORY_DETAIL_SUCCESS,
            payload: {
              categoryDetail: categoryDetail,
            },
          });
        },
        (error) => {
          dispatch({
            type: categorypage_GET_CATEGORY_DETAIL_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const insertCategory = (category) => {
  return (dispatch) => {
    dispatch({
      type: categorypage_INSERT_CATEGORY_REQUEST,
    });

    const request = CategoryService.insertCategory(category);
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: categorypage_INSERT_CATEGORY_SUCCESS,
            payload: {
              insertedCategory: data,
            },
          });
        },
        (error) => {
          console.log("login - error", error);
          dispatch({
            type: categorypage_INSERT_CATEGORY_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const updateCategory = (category) => {
  return (dispatch) => {
    return updateCategoryCore(
      dispatch,
      category,
      categorypage_UPDATE_CATEGORY_REQUEST,
      categorypage_UPDATE_CATEGORY_SUCCESS,
      categorypage_UPDATE_CATEGORY_ERROR
    );
  };
};

export const updateCategory_OrderNumber = (categoryId, imageId, value) => {
  return async (dispatch) => {
    return updateCategoryCore(
      dispatch,
      {
        id: categoryId,
        imageId: imageId,
        orderNumber: value,
      },
      categorypage_UPDATE_CATEGORY_ORDER_NUMBER_REQUEST,
      categorypage_UPDATE_CATEGORY_ORDER_NUMBER_SUCCESS,
      categorypage_UPDATE_CATEGORY_ORDER_NUMBER_ERROR
    );

    // const data = await CategoryService.getCategoryDetail(categoryId);
    // if(data) {
    //     let category = CategoryService.getCategoryInput(data);
    //     category.orderNumber = value;
    //     return updateCategoryCore(dispatch, category, categorypage_UPDATE_CATEGORY_ORDER_NUMBER_REQUEST, categorypage_UPDATE_CATEGORY_ORDER_NUMBER_SUCCESS, categorypage_UPDATE_CATEGORY_ORDER_NUMBER_ERROR);
    // }
  };
};

export const updateCategory_IsDeleted = (categoryId, imageId, value) => {
  return async (dispatch) => {
    return updateCategoryCore(
      dispatch,
      {
        id: categoryId,
        imageId: imageId,
        isDeleted: value,
      },
      categorypage_UPDATE_CATEGORY_IS_DELETED_REQUEST,
      categorypage_UPDATE_CATEGORY_IS_DELETED_SUCCESS,
      categorypage_UPDATE_CATEGORY_IS_DELETED_ERROR
    );

    // const data = await CategoryService.getCategoryDetail(categoryId);
    // if(data) {
    //     let category = CategoryService.getCategoryInput(data);
    //     category.isDeleted = value;
    //     return updateCategoryCore(dispatch, category, categorypage_UPDATE_CATEGORY_IS_DELETED_REQUEST, categorypage_UPDATE_CATEGORY_IS_DELETED_SUCCESS, categorypage_UPDATE_CATEGORY_IS_DELETED_ERROR);
    // }
  };
};

const updateCategoryCore = (
  dispatch,
  category,
  requestStatus,
  successStatus,
  errorStatus
) => {
  dispatch({
    type: requestStatus,
  });

  const request = CategoryService.updateCategory(category);
  if (request) {
    request.then(
      (data) => {
        dispatch({
          type: successStatus,
          payload: {
            updatedCategory: data,
          },
        });
      },
      (error) => {
        console.log("login - error", error);
        dispatch({
          type: errorStatus,
          payload: {
            errorMessage: Messages.REQUEST_ERROR,
          },
        });
      }
    );
  }
};

export const deleteCategory = (category) => {
  return (dispatch) => {
    dispatch({
      type: categorypage_DELETE_CATEGORY_REQUEST,
    });

    const request = CategoryService.deleteCategory(category);
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: categorypage_DELETE_CATEGORY_SUCCESS,
            payload: {
              deletedCategory: category,
            },
          });
        },
        (error) => {
          console.log("login - error", error);
          dispatch({
            type: categorypage_DELETE_CATEGORY_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

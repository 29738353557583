import { connect } from "react-redux";
import { AdminHelperPage } from "../pages/admin-helper-page";
import {
  categoryPageActions,
  newsPageActions,
  productPageActions,
  uiConfigsPageActions,
} from "../redux/actions";
import {
  uiConfigsPageSelectors,
  categoryPageSelectors,
  productPageSelectors,
  newsPageSelectors,
} from "../redux/selectors";

const mapStateToProps = (state, ownProps) => {
  return {
    uiConfigsListRequestStatus:
      uiConfigsPageSelectors.getUIConfigsListRequestStatus(state),
    uiConfigsListRequestResult:
      uiConfigsPageSelectors.getUIConfigsListRequestResult(state),
    //
    categoryListRequestStatus:
      categoryPageSelectors.getCategoryListRequestStatus(state),
    categoryListRequestResult: categoryPageSelectors.getCategoryList(state),
    productListRequestStatus:
      productPageSelectors.getProductListRequestStatus(state),
    productListRequestResult: productPageSelectors.getProductList(state),
    //
    newsListRequestStatus: newsPageSelectors.getNewsListRequestStatus(state),
    newsListRequestResult: newsPageSelectors.getNewsList(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUIConfigsList: () => {
    dispatch(uiConfigsPageActions.getUIConfigsList());
  },
  //
  getCategoryList: () => {
    dispatch(categoryPageActions.getCategoryList());
  },
  getProductList: () => {
    dispatch(productPageActions.getProductList());
  },
  //
  getNewsList: () => {
    dispatch(newsPageActions.getNewsList());
  },
});

const AdminHelperPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminHelperPage);

export default AdminHelperPageContainer;

import { BaseService } from "../../services/base-service";
import { UIConfigsService } from "../../services/ui-configns-service";
import { ProductService } from "../../services/product-service";
import { SaleRequestsService } from "../../services/sale-requests-service";
import { getProductItemsInCart as getProductItemsInCartFromProp } from "../selectors/web-user-layout-selectors";
import { Messages } from "../../constants";
import { AdminLayoutService } from "../../services/admin-layout-service";

export const LOAD_NAV_MENU_LIST_REQUEST = "LOAD_NAV_MENU_LIST_REQUEST";
export const LOAD_NAV_MENU_LIST_SUCCESS = "LOAD_NAV_MENU_LIST_SUCCESS";
export const LOAD_NAV_MENU_LIST_ERROR = "LOAD_NAV_MENU_LIST_ERROR";
export const LOAD_FOOTER_INFO_REQUEST = "LOAD_FOOTER_INFO_REQUEST";
export const LOAD_FOOTER_INFO_SUCCESS = "LOAD_FOOTER_INFO_SUCCESS";
export const LOAD_FOOTER_INFO_ERROR = "LOAD_FOOTER_INFO_ERROR";

// Get product items in cart
export const WUL_GET_PRODUCT_ITEMS_IN_CART_REQUEST =
  "WUL_GET_PRODUCT_ITEMS_IN_CART_REQUEST";
export const WUL_GET_PRODUCT_ITEMS_IN_CART_SUCCESS =
  "WUL_GET_PRODUCT_ITEMS_IN_CART_SUCCESS";
export const WUL_GET_PRODUCT_ITEMS_IN_CART_FAILURE =
  "WUL_GET_PRODUCT_ITEMS_IN_CART_FAILURE";

// Add product item to cart
export const WUL_ADD_PRODUCT_ITEM_TO_CART_REQUEST =
  "WUL_ADD_PRODUCT_ITEM_TO_CART_REQUEST";
export const WUL_ADD_PRODUCT_ITEM_TO_CART_SUCCESS =
  "WUL_ADD_PRODUCT_ITEM_TO_CART_SUCCESS";
export const WUL_ADD_PRODUCT_ITEM_TO_CART_FAILURE =
  "WUL_ADD_PRODUCT_ITEM_TO_CART_FAILURE";

// Update product item in cart
export const WUL_UPDATE_PRODUCT_ITEM_IN_CART_REQUEST =
  "WUL_UPDATE_PRODUCT_ITEM_IN_CART_REQUEST";
export const WUL_UPDATE_PRODUCT_ITEM_IN_CART_SUCCESS =
  "WUL_UPDATE_PRODUCT_ITEM_IN_CART_SUCCESS";
export const WUL_UPDATE_PRODUCT_ITEM_IN_CART_FAILURE =
  "WUL_UPDATE_PRODUCT_ITEM_IN_CART_FAILURE";

// Get Sale Requests List
export const WUL_GET_SALE_REQUESTS_LIST_REQUEST =
  "WUL_GET_SALE_REQUESTS_LIST_REQUEST";
export const WUL_GET_SALE_REQUESTS_LIST_SUCCESS =
  "WUL_GET_SALE_REQUESTS_LIST_SUCCESS";
export const WUL_GET_SALE_REQUESTS_LIST_FAILURE =
  "WUL_GET_SALE_REQUESTS_LIST_FAILURE";

// Get Sale Requests Detail
export const WUL_GET_SALE_REQUESTS_DETAIL_REQUEST =
  "WUL_GET_SALE_REQUESTS_DETAIL_REQUEST";
export const WUL_GET_SALE_REQUESTS_DETAIL_SUCCESS =
  "WUL_GET_SALE_REQUESTS_DETAIL_SUCCESS";
export const WUL_GET_SALE_REQUESTS_DETAIL_FAILURE =
  "WUL_GET_SALE_REQUESTS_DETAIL_FAILURE";

// Get Sale Requests List By Type
export const WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_REQUEST =
  "WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_REQUEST";
export const WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_SUCCESS =
  "WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_SUCCESS";
export const WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_FAILURE =
  "WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_FAILURE";

// Insert Sale Requests
export const WUL_INSERT_SALE_REQUESTS_REQUEST =
  "WUL_INSERT_SALE_REQUESTS_REQUEST";
export const WUL_INSERT_SALE_REQUESTS_SUCCESS =
  "WUL_INSERT_SALE_REQUESTS_SUCCESS";
export const WUL_INSERT_SALE_REQUESTS_FAILURE =
  "WUL_INSERT_SALE_REQUESTS_FAILURE";

// Update Sale Requests
export const WUL_UPDATE_SALE_REQUESTS_REQUEST =
  "WUL_UPDATE_SALE_REQUESTS_REQUEST";
export const WUL_UPDATE_SALE_REQUESTS_SUCCESS =
  "WUL_UPDATE_SALE_REQUESTS_SUCCESS";
export const WUL_UPDATE_SALE_REQUESTS_FAILURE =
  "WUL_UPDATE_SALE_REQUESTS_FAILURE";

//
export const adminlayout_CHECK_TITLE_IS_AVAILABLE_REQUEST =
  "adminlayout_CHECK_TITLE_IS_AVAILABLE_REQUEST";
export const adminlayout_CHECK_TITLE_IS_AVAILABLE_SUCCESS =
  "adminlayout_CHECK_TITLE_IS_AVAILABLE_SUCCESS";
export const adminlayout_CHECK_TITLE_IS_AVAILABLE_FAILURE =
  "adminlayout_CHECK_TITLE_IS_AVAILABLE_FAILURE";

//
export const adminlayout_GET_ALL_SLUGS_REQUEST =
  "adminlayout_GET_ALL_SLUGS_REQUEST";
export const adminlayout_GET_ALL_SLUGS_SUCCESS =
  "adminlayout_GET_ALL_SLUGS_SUCCESS";
export const adminlayout_GET_ALL_SLUGS_FAILURE =
  "adminlayout_GET_ALL_SLUGS_FAILURE";

export const loadNavMenu = () => {
  return (dispatch) => {
    dispatch({
      type: LOAD_NAV_MENU_LIST_REQUEST,
    });

    const request = UIConfigsService.getUIConfigsList();
    if (request) {
      request.then(
        (data) => {
          let navMenuList = [];
          const adminMenuListConfigs = data.filter(
            (t) => t.type === "UI_CONFIGS_0_MENU" && t.isDeleted !== true
          );
          if (adminMenuListConfigs.length > 0) {
            const adminMenuListConfig = adminMenuListConfigs[0];
            let menuList = JSON.parse(adminMenuListConfig.content);
            menuList = BaseService.getOrderListWithText(menuList).filter(
              (t) => !t.isDeleted || t.isDeleted === false
            );
            navMenuList = BaseService.convertNavMenuList(menuList);
          }

          dispatch({
            type: LOAD_NAV_MENU_LIST_SUCCESS,
            payload: {
              navMenuList: navMenuList,
            },
          });
        },
        (error) => {
          dispatch({
            type: LOAD_NAV_MENU_LIST_ERROR,
            payload: {
              navMenuList: [],
            },
          });
        }
      );
    }
  };
};

export const loadFooterInfo = () => {
  return (dispatch) => {
    dispatch({
      type: LOAD_FOOTER_INFO_REQUEST,
    });

    const request = UIConfigsService.getUIConfigsList();
    if (request) {
      request.then(
        (data) => {
          let footerInfoData = {};
          const adminFooterListConfigs = data.filter(
            (t) => t.type === "UI_CONFIGS_3_FOOTER" && t.isDeleted !== true
          );
          if (adminFooterListConfigs.length > 0) {
            const adminFooterConfig = adminFooterListConfigs[0];
            footerInfoData = JSON.parse(adminFooterConfig.content);
          }

          let path = footerInfoData.socialLinks[0].path;
          if (path[path.length - 1] === "/") {
            path = path.substring(0, path.length - 1);
          }
          footerInfoData.socialLinks[0].path = path.substring(
            path.lastIndexOf("/") + 1
          );

          dispatch({
            type: LOAD_FOOTER_INFO_SUCCESS,
            payload: {
              footerInfoData: footerInfoData,
            },
          });
        },
        (error) => {
          dispatch({
            type: LOAD_FOOTER_INFO_ERROR,
            payload: {
              footerInfoData: {},
            },
          });
        }
      );
    }
  };
};

// Get product items in cart
export const getProductItemsInCart = () => {
  return (dispatch) => {
    dispatch({
      type: WUL_GET_PRODUCT_ITEMS_IN_CART_REQUEST,
    });

    let productItemsInCart = BaseService.getLocalStorage(
      ProductService.cartProductCookieName
    );
    productItemsInCart = !BaseService.stringIsNullOrWhiteSpace(
      productItemsInCart
    )
      ? JSON.parse(productItemsInCart)
      : [];

    dispatch({
      type: WUL_GET_PRODUCT_ITEMS_IN_CART_SUCCESS,
      payload: {
        productItemsInCart: productItemsInCart,
      },
    });
  };
};

// Add product item to cart
export const addProductItemToCart = (productItem) => {
  return (dispatch, getState) => {
    dispatch({
      type: WUL_ADD_PRODUCT_ITEM_TO_CART_REQUEST,
    });

    productItem = {
      id: productItem.id,
      name: productItem.name,
      titleId: productItem.titleId,
      orderNumber: productItem.orderNumber,
      categoryId: productItem.categoryId,
      categoryName: productItem.categoryName,
      tags: productItem.tags,
      price: productItem.price,
      originalPrice: productItem.originalPrice,
      discountPercent: productItem.discountPercent,
      promoText: productItem.promoText,
      imgURL: productItem.imgURL,
      saleInfo: productItem.saleInfo,
    };
    let productItemsInCart = getProductItemsInCartFromProp(getState());
    productItemsInCart = BaseService.copyAnObject(productItemsInCart);
    const idx = productItemsInCart.findIndex(
      (t) => t.productItem.id === productItem.id
    );
    if (idx === -1) {
      productItemsInCart.push({
        productItem: productItem,
        amount: 1,
        note: "",
      });
    }
    const cartAddedProductsJSON = JSON.stringify(productItemsInCart);
    BaseService.setLocalStorage(
      ProductService.cartProductCookieName,
      cartAddedProductsJSON
    );

    setTimeout(() => {
      dispatch({
        type: WUL_ADD_PRODUCT_ITEM_TO_CART_SUCCESS,
        payload: {
          productItemsInCart: productItemsInCart,
          productItemsInCart_LastestModifyTime: new Date().toLocaleString(),
          productItem: productItem,
        },
      });
    }, 200);
  };
};

// Update product item in cart
export const updateProductItemInCart = (productItemsInCart) => {
  return (dispatch, getState) => {
    dispatch({
      type: WUL_UPDATE_PRODUCT_ITEM_IN_CART_REQUEST,
    });

    const productsInCartJSON = JSON.stringify(productItemsInCart);
    BaseService.setLocalStorage(
      ProductService.cartProductCookieName,
      productsInCartJSON
    );

    setTimeout(() => {
      dispatch({
        type: WUL_UPDATE_PRODUCT_ITEM_IN_CART_SUCCESS,
        payload: {
          productItemsInCart: productItemsInCart,
          productItemsInCart_LastestModifyTime: new Date().toLocaleString(),
        },
      });
    }, 200);
  };
};

// Get Sale Requests List
export const getSaleRequestsList = () => {
  return (dispatch) => {
    dispatch({
      type: WUL_GET_SALE_REQUESTS_LIST_REQUEST,
    });
    dispatch({
      type: WUL_GET_SALE_REQUESTS_LIST_SUCCESS,
      payload: {
        saleRequestsList: [],
      },
    });

    const request = SaleRequestsService.getSaleRequestsList();
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: WUL_GET_SALE_REQUESTS_LIST_SUCCESS,
            payload: {
              saleRequestsList: data,
            },
          });
        },
        (error) => {
          dispatch({
            type: WUL_GET_SALE_REQUESTS_LIST_FAILURE,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

// Get Sale Requests Detail
export const getSaleRequestsDetail = (saleRequestsId) => {
  return (dispatch) => {
    dispatch({
      type: WUL_GET_SALE_REQUESTS_DETAIL_REQUEST,
    });
    dispatch({
      type: WUL_GET_SALE_REQUESTS_DETAIL_SUCCESS,
      payload: {
        saleRequestsDetail: [],
      },
    });

    const request = SaleRequestsService.getSaleRequestsDetail(saleRequestsId);
    if (request) {
      request.then(
        (data) => {
          const saleRequestsDetail = data;
          dispatch({
            type: WUL_GET_SALE_REQUESTS_DETAIL_SUCCESS,
            payload: {
              saleRequestsDetail: saleRequestsDetail,
            },
          });
        },
        (error) => {
          dispatch({
            type: WUL_GET_SALE_REQUESTS_DETAIL_FAILURE,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

// Get Sale Requests List By Type
export const getSaleRequestsListByType = (saleRequestsType) => {
  return (dispatch) => {
    dispatch({
      type: WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_REQUEST,
      payload: {
        saleRequestsType: saleRequestsType,
      },
    });

    const request =
      SaleRequestsService.getSaleRequestsListByType(saleRequestsType);
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_SUCCESS,
            payload: {
              saleRequestsListByType: data,
              saleRequestsType: saleRequestsType,
            },
          });
        },
        (error) => {
          dispatch({
            type: WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_FAILURE,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
              saleRequestsType: saleRequestsType,
            },
          });
        }
      );
    }
  };
};

// Insert Sale Requests
export const insertSaleRequests = (saleRequests) => {
  return (dispatch) => {
    dispatch({
      type: WUL_INSERT_SALE_REQUESTS_REQUEST,
    });
    setTimeout(() => {
      dispatch({
        type: WUL_INSERT_SALE_REQUESTS_SUCCESS,
        payload: {
          insertedSaleRequests: [],
        },
      });

      const request = SaleRequestsService.insertSaleRequests(saleRequests);
      if (request) {
        request.then(
          (data) => {
            let type = "Other";
            const dataType = data.data.type;
            switch (dataType) {
              case "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM": {
                type = "Advisory Request";
                break;
              }
              case "SALE_REQUEST_1_DAT_HANG": {
                type = "Order Request";
                break;
              }
              default: {
              }
            }
            BaseService.setGASendSaleRequestsEvent(type, data.data.id);
            dispatch({
              type: WUL_INSERT_SALE_REQUESTS_SUCCESS,
              payload: {
                insertedSaleRequests: data,
              },
            });
          },
          (error) => {
            dispatch({
              type: WUL_INSERT_SALE_REQUESTS_FAILURE,
              payload: {
                errorMessage: Messages.REQUEST_ERROR,
              },
            });
          }
        );
      }
    }, 50);
  };
};

// Update Sale Requests
export const updateSaleRequests = (saleRequests) => {
  return (dispatch) => {
    return updateSaleRequestsCore(
      dispatch,
      saleRequests,
      WUL_UPDATE_SALE_REQUESTS_REQUEST,
      WUL_UPDATE_SALE_REQUESTS_SUCCESS,
      WUL_UPDATE_SALE_REQUESTS_FAILURE
    );
  };
};

const updateSaleRequestsCore = (
  dispatch,
  saleRequests,
  requestStatus,
  successStatus,
  errorStatus
) => {
  dispatch({
    type: requestStatus,
  });

  const request = SaleRequestsService.updateSaleRequests(saleRequests);
  if (request) {
    request.then(
      (data) => {
        dispatch({
          type: successStatus,
          payload: {
            updatedSaleRequests: data.data,
          },
        });
      },
      (error) => {
        dispatch({
          type: errorStatus,
          payload: {
            errorMessage: Messages.REQUEST_ERROR,
          },
        });
      }
    );
  }
};

export const getAllSlugs = () => {
  return (dispatch) => {
    dispatch({
      type: adminlayout_GET_ALL_SLUGS_REQUEST,
    });

    const slugs = {};
    const request = AdminLayoutService.getTitleIds();
    if (request) {
      request.then(
        (data) => {
          const tData = data;
          // {
          //   category: [
          //     "category-id-3",
          //     "category-id-1",
          //     "category-id-2",
          //     "category-id-4",
          //   ],
          //   news: ["news-id-1"],
          // };
          for (var propt in tData) {
            const items = tData[propt];
            items.forEach((item) => {
              slugs[item] = propt;
            });
          }
          const productRequest = ProductService.getProductList();
          if (productRequest) {
            productRequest.then((productData) => {
              const pData = productData.map((t) => t.titleId);
              pData.forEach((item) => {
                slugs[item] = "product";
              });
              dispatch({
                type: adminlayout_GET_ALL_SLUGS_SUCCESS,
                payload: {
                  getAllSlugsRequestResult: slugs,
                },
              });
            });
          }
        },
        (error) => {
          dispatch({
            type: adminlayout_GET_ALL_SLUGS_FAILURE,
          });
        }
      );
    }
  };
};

export const checkTitleIsAvailable = (titleId) => {
  return (dispatch) => {
    dispatch({
      type: adminlayout_CHECK_TITLE_IS_AVAILABLE_REQUEST,
    });

    const slugs = {};
    const request = AdminLayoutService.getTitleIds();
    if (request) {
      request.then(
        (data) => {
          const tData = data;
          // {
          //   category: [
          //     "category-id-3",
          //     "category-id-1",
          //     "category-id-2",
          //     "category-id-4",
          //   ],
          //   news: ["news-id-1"],
          // };
          for (var propt in tData) {
            const items = tData[propt];
            items.forEach((item) => {
              slugs[item] = propt;
            });
          }
          const productRequest = ProductService.getProductList();
          if (productRequest) {
            productRequest.then((productData) => {
              const pData = productData.map((t) => t.titleId);
              pData.forEach((item) => {
                slugs[item] = "product";
              });
              dispatch({
                type: adminlayout_GET_ALL_SLUGS_SUCCESS,
                payload: {
                  getAllSlugsRequestResult: slugs,
                },
              });
              const slug = slugs[titleId];
              dispatch({
                type: adminlayout_CHECK_TITLE_IS_AVAILABLE_SUCCESS,
                payload: {
                  titleIsAvailableRequestResult:
                    BaseService.stringIsNullOrWhiteSpace(slug),
                },
              });
            });
          }
        },
        (error) => {}
      );
    }

    //   dispatch({
    //     type: adminlayout_CHECK_TITLE_IS_AVAILABLE_REQUEST,
    //   });
    //   const request = ProductService.checkProductTitleIsAvailable(titleId);
    //   if (request) {
    //     request.then(
    //       (res) => {
    //         console.log(
    //           "ProductService.checkProductTitleIsAvailable(titleId);",
    //           res
    //         );
    //         dispatch({
    //           type: adminlayout_CHECK_TITLE_IS_AVAILABLE_SUCCESS,
    //           payload: {
    //             productTitleIsAvailable: res.data,
    //           },
    //         });
    //       },
    //       (error) => {
    //         dispatch({
    //           type: adminlayout_CHECK_TITLE_IS_AVAILABLE_FAILURE,
    //           payload: {
    //             errorMessage: Messages.REQUEST_ERROR,
    //           },
    //         });
    //       }
    //     );
    //   }
  };
};

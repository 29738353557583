import React from "react";
import { ButtonComponent } from "../components/public/button-component";
import { RequestStatus } from "../constants";
import { BaseService } from "../services/base-service";
import "./admin-helper-page.css";

export class AdminHelperPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCallData: false,
      adminPagesConfigs: [],
      adminCategoryList: [],
      adminProductList: [],
      adminNewsList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.uiConfigsListRequestStatus !==
        nextProps.uiConfigsListRequestStatus &&
      nextProps.uiConfigsListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      const uiConfigsListRequestResult =
        BaseService.getOrderListWithCreatedTime(
          nextProps.uiConfigsListRequestResult
        ).reverse();
      const adminPagesConfigs = uiConfigsListRequestResult.filter(
        (t) => t.type === "UI_CONFIGS_2_PAGES"
      );
      this.setState(
        (state) => {
          return {
            adminPagesConfigs: BaseService.getOrderListWithName(
              adminPagesConfigs
            ).map((t) => ({
              id: t.id,
              name: t.name,
              slug: BaseService.stringIsNullOrWhiteSpace(t.content)
                ? ""
                : JSON.parse(t.content).url,
            })),
          };
        },
        () => {
          //   console.log(this.state.adminPagesConfigs);
        }
      );
    }
    if (
      this.props.categoryListRequestStatus !==
        nextProps.categoryListRequestStatus &&
      nextProps.categoryListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState(
        (state) => ({
          adminCategoryList: BaseService.getOrderListWithName(
            nextProps.categoryListRequestResult
          ).map((t) => ({
            id: t.id,
            name: t.name,
            slug: `/${t.titleId}`,
          })),
        }),
        () => {
          //   console.log(this.state.adminCategoryList);
        }
      );
    }
    if (
      this.props.productListRequestStatus !==
        nextProps.productListRequestStatus &&
      nextProps.productListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState(
        (state) => ({
          adminProductList: BaseService.getOrderListWithName(
            nextProps.productListRequestResult
          ).map((t) => ({
            id: t.id,
            name: t.name,
            slug: `/${t.titleId}`,
          })),
        }),
        () => {
          //   console.log(this.state.adminProductList);
        }
      );
    }
    if (
      this.props.newsListRequestStatus !== nextProps.newsListRequestStatus &&
      nextProps.newsListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState(
        (state) => ({
          adminNewsList: BaseService.getOrderListWithName(
            nextProps.newsListRequestResult
          ).map((t) => ({
            id: t.id,
            name: t.name,
            slug: `/${t.titleId}`,
          })),
        }),
        () => {
          console.log(this.state.adminNewsList);
        }
      );
    }
  }

  handleCreateSitemapOnClick = () => {
    this.setState(
      (state) => ({
        isCallData: true,
      }),
      () => {
        this.props.getUIConfigsList();
        //
        this.props.getCategoryList();
        this.props.getProductList();
        //
        this.props.getNewsList();
      }
    );
  };

  renderSitemapGroupElement = (text, list) => {
    let html = <></>;
    if (this.state.isCallData) {
      html = (
        <div>
          {this.props.uiConfigsListRequestStatus ===
          RequestStatus.RQ_REQUEST ? (
            <p>text</p>
          ) : (
            this.renderSitemapItemElement(list)
          )}
        </div>
      );
    }
    return html;
  };

  renderSitemapItemElement = (list) => {
    const html = (
      <>
        {list.length > 0 ? (
          list.map((t) => this.renderRouteElement(t.slug))
        ) : (
          <></>
        )}
      </>
    );
    return html;
  };

  renderRouteElement = (slug) => {
    let html = <></>;
    if (!BaseService.stringIsNullOrWhiteSpace(slug)) {
      html = <div>{`<Route path="${slug}" />`}</div>;
    }
    return html;
  };

  renderSitemapMakerElement = () => {
    const html = (
      <>
        <div>
          <ButtonComponent
            clickFunction={this.handleCreateSitemapOnClick}
            iconClass="fas fa-sitemap"
            text="Tạo Sitemap"
          />
          <br></br>
          {this.renderSitemapGroupElement(
            "Đang tải danh sách Sitemap trang...",
            this.state.adminPagesConfigs
          )}
          {this.renderSitemapGroupElement(
            "Đang tải danh sách Sitemap thương hiệu...",
            this.state.adminCategoryList
          )}
          {this.renderSitemapGroupElement(
            "Đang tải danh sách Sitemap sản phẩm...",
            this.state.adminProductList
          )}
          {this.renderSitemapGroupElement(
            "Đang tải danh sách Sitemap cẩm nang...",
            this.state.adminNewsList
          )}
        </div>
      </>
    );
    return html;
  };

  render() {
    const html = <>{this.renderSitemapMakerElement()}</>;
    return html;
  }
}

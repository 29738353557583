import React from "react";
import "./admin-category-list.css";
//
import { RequestStatus } from "../../constants";
import { LoadingComponent } from "../../components/public/loading-component";
import { TableComponent } from "../../components/public/table-component";
import {
  FormButton,
  FormModal,
  FormConfirmationModal,
  FormNumberInput,
  FormToggle,
} from "../../components/public/form-components";
import { AdminCategoryDetail } from "../../components/admin-layout/admin-category-detail";
import { CategoryService } from "../../services/category-service";
import { BaseService } from "../../services/base-service";

export class AdminCategoryList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenCategoryDetailModal: false,
      isOpenCategoryConfirmDeleteModal: false,
      modalTitle: "",
      confirmModalInfo: {
        modalTitle: "",
        modalContent: "",
      },
      targetCategory: null,
      deletingCategoryId: null,
      categoryList: [],
      categoryOrderNumberTimerId: BaseService.getRandomString(),
      categoryUpdateOrderNumberTargetId: null,
      categoryIsDeletedTimerId: BaseService.getRandomString(),
      categoryUpdateIsDeletedTargetId: null,
      //
      isDataHasUpdated: false,
      headers: [
        { title: "Sắp xếp", width: "111" },
        { title: "Kích hoạt", width: "99" },
        { title: "Tên thương hiệu" },
        // { title: 'Ghi chú' },
        { title: "", width: "120" },
      ],
    };
  }

  componentDidMount() {
    this.props.getCategoryList();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.categoryListRequestStatus !==
      nextProps.categoryListRequestStatus &&
      nextProps.categoryListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      let categoryList = nextProps.categoryList;
      this.reOrderCategoryList(categoryList);
    }

    console.log(
      "AdminCategoryPage - componentWillReceiveProps - nextProps",
      nextProps
    );
    if (
      (this.props.insertCategoryRequestStatus !==
        nextProps.insertCategoryRequestStatus &&
        nextProps.insertCategoryRequestStatus === RequestStatus.RQ_SUCCESS) ||
      (this.props.updateCategoryRequestStatus !==
        nextProps.updateCategoryRequestStatus &&
        nextProps.updateCategoryRequestStatus === RequestStatus.RQ_SUCCESS) ||
      (this.props.deleteCategoryRequestStatus !==
        nextProps.deleteCategoryRequestStatus &&
        nextProps.deleteCategoryRequestStatus === RequestStatus.RQ_SUCCESS)
    ) {
      this.props.getAllSlugs();
      this.setState({
        isOpenCategoryDetailModal: false,
      });
      // setTimeout(() => {
      this.props.getCategoryList();
      // }, 5000);
    }

    if (
      this.props.categoryDetailRequestStatus !==
      nextProps.categoryDetailRequestStatus &&
      nextProps.categoryDetailRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      if (nextProps.categoryDetail) {
        this.setState({
          targetCategory: nextProps.categoryDetail,
        });
      }
    }
  }

  reOrderCategoryList = (categoryList) => {
    let res = BaseService.getOrderListWithName(categoryList);
    this.setState({
      categoryList: res,
    });
  };

  getTableHeaders = () => {
    const headers = this.state.headers;
    return headers;
  };

  onChangeCategoryOrderNumber = (categoryId, value) => {
    const idx = this.state.categoryList.findIndex((t) => t.id === categoryId);
    if (idx !== -1) {
      let categoryList = this.state.categoryList;
      categoryList[idx].orderNumber = value;
      this.setState({
        categoryList: categoryList,
      });
      BaseService.clearTimer(this.state.categoryOrderNumberTimerId);
      BaseService.setTimmer(this.state.categoryOrderNumberTimerId, () => {
        this.setState({
          categoryUpdateOrderNumberTargetId: categoryId,
        });
        this.props.updateCategory_OrderNumber(
          categoryId,
          categoryList[idx].image ? categoryList[idx].image.id : null,
          value
        );
      });
    }
  };

  onChangeCategoryIsDeleted = (categoryId, value) => {
    const idx = this.state.categoryList.findIndex((t) => t.id === categoryId);
    if (idx !== -1) {
      let categoryList = this.state.categoryList;
      categoryList[idx].isDeleted = !value;
      this.setState({
        categoryList: categoryList,
      });
      BaseService.clearTimer(this.state.categoryIsDeletedTimerId);
      BaseService.setTimmer(this.state.categoryIsDeletedTimerId, () => {
        this.setState({
          categoryUpdateIsDeletedTargetId: categoryId,
        });
        this.props.updateCategory_IsDeleted(
          categoryId,
          categoryList[idx].image ? categoryList[idx].image.id : null,
          !value
        );
      });
    }
  };

  getTableRows = () => {
    const rows = [];
    this.state.categoryList.forEach((item) => {
      rows.push({
        columns: [
          <>
            <div>
              <FormNumberInput
                value={item.orderNumber}
                min="0"
                onChange={(value) => {
                  this.onChangeCategoryOrderNumber(item.id, value);
                }}
              />
              {this.props.updateCategoryOrderNumberRequestStatus ===
                RequestStatus.RQ_REQUEST &&
                this.state.categoryUpdateOrderNumberTargetId === item.id ? (
                <div className="form-component-submitting-message-div">
                  <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
                </div>
              ) : (
                <></>
              )}
            </div>
          </>,
          <>
            <FormToggle
              value={item.isDeleted ? !item.isDeleted : true}
              onChange={(value) => {
                this.onChangeCategoryIsDeleted(item.id, value);
              }}
            />
            {this.props.updateCategoryIsDeletedRequestStatus ===
              RequestStatus.RQ_REQUEST &&
              this.state.categoryUpdateIsDeletedTargetId === item.id ? (
              <div className="form-component-submitting-message-div">
                <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
              </div>
            ) : (
              <></>
            )}
          </>,
          <>
            <div>
              <div
                className="table-component-title-link-div"
                onClick={() => {
                  this.openEditCategory(item);
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.name,
                  }}
                ></div>
                <div className="table-component-image-div">
                  <img
                    alt=""
                    src={
                      item.image
                        ? BaseService.getImageUrlFromServer(
                          item.image.id,
                          item.image.name,
                          item.image.url
                        )
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </>,
          // <>
          //     <div style={{ width: '300px' }}>
          //         {item.description}
          //     </div>
          // </>,
          <>
            <div style={{ width: "max-content" }}>
              {/* <FormButton
                                text='Danh sách sản phẩm'
                                faIconClass='fas fa-clipboard-list'
                                isDisabled={true}
                            /> */}
              <FormButton
                type="delete"
                onClick={() => {
                  this.onClickDeleteCategory(item);
                }}
                isRequesting={
                  item.id === this.state.deletingCategoryId ? true : false
                }
              />
            </div>
          </>,
        ],
      });
    });
    return rows;
  };

  openAddNewCategory = () => {
    this.setState({
      modalTitle: "Thêm mới thương hiệu",
      targetCategory: CategoryService.getNewCategory(),
      isOpenCategoryDetailModal: true,
      isDataHasUpdated: false,
    });
  };

  openEditCategory = (category) => {
    this.props.getCategoryDetail(category.id);
    this.setState({
      modalTitle: "Chỉnh sửa thương hiệu",
      targetCategory: null,
      isOpenCategoryDetailModal: true,
      isDataHasUpdated: false,
    });
  };

  onClickDeleteCategory = (category) => {
    if (this.props.deleteCategoryRequestStatus === RequestStatus.RQ_REQUEST)
      return;
    const confirmModalInfo = BaseService.setDeleteConfirmationModalDetail(
      "Thương hiệu",
      category.name
    );
    this.setState({
      targetCategory: category,
      isOpenCategoryConfirmDeleteModal: true,
      confirmModalInfo: {
        modalTitle: confirmModalInfo.title,
        modalContent: confirmModalInfo.content,
      },
    });
  };

  onConfirmYesOrNo = (res) => {
    this.setState({
      isOpenCategoryConfirmDeleteModal: false,
    });
    if (res) {
      this.setState({
        deletingCategoryId: this.state.targetCategory.id,
      });
      this.props.deleteCategory(this.state.targetCategory);
    }
  };

  closeModal = () => {
    this.setState({
      isOpenCategoryDetailModal: false,
    });
  };

  saveCategoryDetail = (category) => {
    if (category.id === null) {
      this.props.insertCategory(category);
    } else {
      this.props.updateCategory(category);
    }
  };

  trackIsDataHasUpdated = (isDataHasUpdated) => {
    this.setState({
      isDataHasUpdated: isDataHasUpdated,
    });
  };

  render() {
    const html = (
      <>
        {this.props.categoryListRequestStatus === RequestStatus.RQ_SUCCESS ? (
          <>
            <div>
              <FormButton type="add" onClick={this.openAddNewCategory} />
            </div>
            <TableComponent
              headers={this.getTableHeaders()}
              rows={this.getTableRows()}
              noItemRow={
                <td colSpan={this.state.headers.length}>Chưa có thương hiệu</td>
              }
            />
            <FormModal
              size="lg"
              isShowModal={this.state.isOpenCategoryDetailModal}
              modalTitle={this.state.modalTitle}
              modalContent={
                this.state.targetCategory ? (
                  <AdminCategoryDetail
                    save={this.saveCategoryDetail}
                    category={this.state.targetCategory}
                    categoryDetail_originName={
                      this.props.categoryDetail_originName
                    }
                    trackIsDataHasUpdated={this.trackIsDataHasUpdated}
                    insertCategoryRequestStatus={
                      this.props.insertCategoryRequestStatus
                    }
                    updateCategoryRequestStatus={
                      this.props.updateCategoryRequestStatus
                    }
                    //
                    titleIsAvailableRequestStatus={
                      this.props.titleIsAvailableRequestStatus
                    }
                    titleIsAvailableRequestResult={
                      this.props.titleIsAvailableRequestResult
                    }
                    checkTitleIsAvailable={this.props.checkTitleIsAvailable}
                  />
                ) : (
                  <div style={{ height: "450px" }}>
                    <LoadingComponent />
                  </div>
                )
              }
              isDataHasUpdated={this.state.isDataHasUpdated}
              closeModal={this.closeModal}
            />
            <FormConfirmationModal
              isShowModal={this.state.isOpenCategoryConfirmDeleteModal}
              modalTitle={this.state.confirmModalInfo.modalTitle}
              modalContent={this.state.confirmModalInfo.modalContent}
              okText="Xóa"
              cancelText="Hủy"
              onConfirmYesOrNo={this.onConfirmYesOrNo}
            />
          </>
        ) : (
          <>
            <LoadingComponent />
          </>
        )}
      </>
    );
    return html;
  }
}

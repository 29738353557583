import React from 'react';
import './admin-typical-face-list.css';
// 
import { RequestStatus } from '../../constants';
import { LoadingComponent } from '../public/loading-component';
import { TableComponent } from '../public/table-component';
import { FormButton, FormModal, FormConfirmationModal, FormNumberInput, FormToggle } from '../public/form-components';
import { AdminTypicalFaceDetail } from './admin-typical-face-detail';
import { TypicalFaceService } from '../../services/typical-face-service';
import { BaseService } from '../../services/base-service';

export class AdminTypicalFaceList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenTypicalFaceDetailModal: false,
            isOpenTypicalFaceConfirmDeleteModal: false,
            modalTitle: '',
            confirmModalInfo: {
                modalTitle: '',
                modalContent: ''
            },
            targetTypicalFace: null,
            deletingTypicalFaceId: null,
            typicalFaceList: [],
            typicalFaceOrderNumberTimerId: BaseService.getRandomString(),
            typicalFaceUpdateOrderNumberTargetId: null,
            typicalFaceIsDeletedTimerId: BaseService.getRandomString(),
            typicalFaceUpdateIsDeletedTargetId: null,
            // 
            isDataHasUpdated: false,
            headers: [
                { title: 'Sắp xếp', width: '111' },
                { title: 'Kích hoạt', width: '99' },
                { title: 'Tên' },
                // { title: 'Lời giới thiệu' },
                { title: 'Đường dẫn', width: '450' },
                { title: '', width: '120' }
            ]
        }
    }

    componentDidMount() {
        this.props.getTypicalFaceList();
    }

    componentWillReceiveProps(nextProps) {
        console.log('AdminTypicalFacePage - componentWillReceiveProps - nextProps', nextProps);

        if (this.props.typicalFaceListRequestStatus !== nextProps.typicalFaceListRequestStatus &&
            nextProps.typicalFaceListRequestStatus === RequestStatus.RQ_SUCCESS) {
            let typicalFaceList = (nextProps.isHotFace === true) ? (
                nextProps.typicalFaceList.filter(t => t.isHot === true)
            ) : (
                nextProps.typicalFaceList.filter(t => t.isHot !== true)
            )
            this.reOrderTypicalFaceList(typicalFaceList);
        }

        if (
            (this.props.insertTypicalFaceRequestStatus !== nextProps.insertTypicalFaceRequestStatus &&
                nextProps.insertTypicalFaceRequestStatus === RequestStatus.RQ_SUCCESS) ||
            (this.props.updateTypicalFaceRequestStatus !== nextProps.updateTypicalFaceRequestStatus &&
                nextProps.updateTypicalFaceRequestStatus === RequestStatus.RQ_SUCCESS) ||
            (this.props.deleteTypicalFaceRequestStatus !== nextProps.deleteTypicalFaceRequestStatus &&
                nextProps.deleteTypicalFaceRequestStatus === RequestStatus.RQ_SUCCESS)
        ) {
            this.setState({
                isOpenTypicalFaceDetailModal: false
            });
            // setTimeout(() => {
            this.props.getTypicalFaceList();
            // }, 5000);
        }

        if (this.props.updateTypicalFaceRequestStatus !== nextProps.updateTypicalFaceRequestStatus &&
            nextProps.updateTypicalFaceRequestStatus === RequestStatus.RQ_SUCCESS) {
            this.setState({
                typicalFaceUpdateOrderNumberTargetId: null
            })
        }

        if (this.props.typicalFaceDetailRequestStatus !== nextProps.typicalFaceDetailRequestStatus &&
            nextProps.typicalFaceDetailRequestStatus === RequestStatus.RQ_SUCCESS) {
            if (nextProps.typicalFaceDetail) {
                this.setState({
                    targetTypicalFace: nextProps.typicalFaceDetail
                })
            }
        }

        // if(this.props.updateTypicalFaceOrderNumberRequestStatus !== nextProps.updateTypicalFaceOrderNumberRequestStatus &&
        // nextProps.updateTypicalFaceOrderNumberRequestStatus === RequestStatus.RQ_SUCCESS) {
        //     let typicalFaceList = (nextProps.isHotFace === true)?(
        //         nextProps.typicalFaceList.filter(t => t.isHot === true)
        //     ):(
        //         nextProps.typicalFaceList.filter(t => t.isHot !== true)
        //     )
        //     this.reOrderTypicalFaceList(typicalFaceList);
        // }
    }

    reOrderTypicalFaceList = (typicalFaceList) => {
        let res = BaseService.getOrderListWithName(typicalFaceList);
        this.setState({
            typicalFaceList: res
        })
    }

    getTableHeaders = () => {
        const headers = this.state.headers;
        return headers;
    }

    onChangeTypicalFaceOrderNumber = (typicalFaceId, value) => {
        const idx = this.state.typicalFaceList.findIndex(t => t.id === typicalFaceId);
        if (idx !== -1) {
            let typicalFaceList = this.state.typicalFaceList;
            typicalFaceList[idx].orderNumber = value;
            this.setState({
                typicalFaceList: typicalFaceList
            })
            BaseService.clearTimer(this.state.typicalFaceOrderNumberTimerId);
            BaseService.setTimmer(this.state.typicalFaceOrderNumberTimerId, () => {
                this.setState({
                    typicalFaceUpdateOrderNumberTargetId: typicalFaceId
                });
                this.props.updateTypicalFace_OrderNumber(
                    typicalFaceId,
                    (typicalFaceList[idx].image) ? (typicalFaceList[idx].image.id) : (null),
                    value
                );
            })
        }
    }

    onChangeTypicalFaceIsDeleted = (typicalFaceId, value) => {
        const idx = this.state.typicalFaceList.findIndex(t => t.id === typicalFaceId);
        if (idx !== -1) {
            let typicalFaceList = this.state.typicalFaceList;
            typicalFaceList[idx].isDeleted = !value;
            this.setState({
                typicalFaceList: typicalFaceList
            })
            BaseService.clearTimer(this.state.typicalFaceIsDeletedTimerId);
            BaseService.setTimmer(this.state.typicalFaceIsDeletedTimerId, () => {
                this.setState({
                    typicalFaceUpdateIsDeletedTargetId: typicalFaceId
                });
                this.props.updateTypicalFace_IsDeleted(
                    typicalFaceId,
                    (typicalFaceList[idx].image) ? (typicalFaceList[idx].image.id) : (null),
                    !value
                );
            })
        }
    }

    getTableRows = () => {
        const rows = [];
        this.state.typicalFaceList.forEach((item) => {
            rows.push({
                columns: [
                    <>
                        <div>
                            <FormNumberInput
                                value={item.orderNumber}
                                min='0'
                                onChange={(value) => {
                                    this.onChangeTypicalFaceOrderNumber(item.id, value);
                                }}
                            />
                            {
                                (this.props.updateTypicalFaceOrderNumberRequestStatus === RequestStatus.RQ_REQUEST && this.state.typicalFaceUpdateOrderNumberTargetId === item.id) ? (
                                    <div className='form-component-submitting-message-div'>
                                        <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                    </>,
                    <>
                        <FormToggle
                            value={(item.isDeleted) ? (!item.isDeleted) : (true)}
                            onChange={(value) => {
                                this.onChangeTypicalFaceIsDeleted(item.id, value);
                            }}
                        />
                        {
                            (this.props.updateTypicalFaceIsDeletedRequestStatus === RequestStatus.RQ_REQUEST && this.state.typicalFaceUpdateIsDeletedTargetId === item.id) ? (
                                <div className='form-component-submitting-message-div'>
                                    <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
                                </div>
                            ) : (<></>)
                        }
                    </>,
                    <>
                        <div>
                            <div className='table-component-title-link-div' onClick={() => { this.openEditTypicalFace(item); }}>
                                <div dangerouslySetInnerHTML={{ __html: item.name }}></div>
                                <div className='table-component-image-div'>
                                    <img alt='' src={
                                        (item.image) ? (
                                            BaseService.getImageUrlFromServer(
                                                item.image.id, item.image.name,
                                                item.image.url
                                            )
                                        ) : (null)
                                    } />
                                </div>
                            </div>
                        </div>
                    </>,
                    // <>
                    //     <div>
                    //         {item.description}
                    //     </div>
                    // </>,
                    <>
                        <div>
                            {item.url}
                        </div>
                    </>,
                    <>
                        <div style={{ width: 'max-content' }}>
                            <FormButton
                                type='delete'
                                onClick={() => { this.onClickDeleteTypicalFace(item); }}
                                isRequesting={(item.id === this.state.deletingTypicalFaceId) ? (true) : (false)}
                            />
                        </div>
                    </>
                ]
            });
        });
        return rows;
    }

    openAddNewTypicalFace = () => {
        this.setState({
            modalTitle: `Thêm mới ${(this.props.isHotFace) ? ('nghệ sĩ') : ('nhà phân phối')}`,
            targetTypicalFace: TypicalFaceService.getNewTypicalFace(),
            isOpenTypicalFaceDetailModal: true,
            isDataHasUpdated: false
        });
    }

    openEditTypicalFace = (typicalFace) => {
        this.props.getTypicalFaceDetail(typicalFace.id);
        this.setState({
            modalTitle: `Chỉnh sửa ${(this.props.isHotFace) ? ('nghệ sĩ') : ('nhà phân phối')}`,
            targetTypicalFace: null,
            isOpenTypicalFaceDetailModal: true,
            isDataHasUpdated: false
        });
    }

    onClickDeleteTypicalFace = (typicalFace) => {
        if (this.props.deleteTypicalFaceRequestStatus === RequestStatus.RQ_REQUEST) return;
        const confirmModalInfo = BaseService.setDeleteConfirmationModalDetail('Gương mặt tiêu biểu', typicalFace.name);
        this.setState({
            targetTypicalFace: typicalFace,
            isOpenTypicalFaceConfirmDeleteModal: true,
            confirmModalInfo: {
                modalTitle: confirmModalInfo.title,
                modalContent: confirmModalInfo.content
            }
        });
    }

    onConfirmYesOrNo = (res) => {
        this.setState({
            isOpenTypicalFaceConfirmDeleteModal: false
        });
        if (res) {
            this.setState({
                deletingTypicalFaceId: this.state.targetTypicalFace.id
            })
            this.props.deleteTypicalFace(this.state.targetTypicalFace);
        }
    }

    closeModal = () => {
        this.setState({
            isOpenTypicalFaceDetailModal: false
        });
    }

    saveTypicalFaceDetail = (typicalFace) => {
        if (typicalFace.id === null) {
            this.props.insertTypicalFace(typicalFace);
        } else {
            this.props.updateTypicalFace(typicalFace);
        }
    }

    trackIsDataHasUpdated = (isDataHasUpdated) => {
        this.setState({
            isDataHasUpdated: isDataHasUpdated
        })
    }

    render() {
        const html = <>
            {
                (this.props.typicalFaceListRequestStatus === RequestStatus.RQ_SUCCESS) ? (
                    <>
                        <div>
                            <FormButton
                                type='add'
                                onClick={this.openAddNewTypicalFace}
                            />
                        </div>
                        <TableComponent
                            headers={this.getTableHeaders()}
                            rows={this.getTableRows()}
                            noItemRow={
                                <td colSpan={this.state.headers.length}>Chưa có gương mặt</td>
                            }
                        />
                        <FormModal
                            size='xl'
                            isShowModal={this.state.isOpenTypicalFaceDetailModal}
                            modalTitle={this.state.modalTitle}
                            modalContent={
                                (this.state.targetTypicalFace) ? (
                                    <AdminTypicalFaceDetail
                                        save={this.saveTypicalFaceDetail}
                                        typicalFace={this.state.targetTypicalFace}
                                        trackIsDataHasUpdated={this.trackIsDataHasUpdated}
                                        insertTypicalFaceRequestStatus={this.props.insertTypicalFaceRequestStatus}
                                        updateTypicalFaceRequestStatus={this.props.updateTypicalFaceRequestStatus}
                                        isHotFace={this.props.isHotFace}
                                    />
                                ) : (
                                    <div style={{ height: '450px' }}>
                                        <LoadingComponent />
                                    </div>
                                )
                            }
                            isDataHasUpdated={this.state.isDataHasUpdated}
                            closeModal={this.closeModal}
                        />
                        <FormConfirmationModal
                            isShowModal={this.state.isOpenTypicalFaceConfirmDeleteModal}
                            modalTitle={this.state.confirmModalInfo.modalTitle}
                            modalContent={this.state.confirmModalInfo.modalContent}
                            okText='Xóa'
                            cancelText='Hủy'
                            onConfirmYesOrNo={this.onConfirmYesOrNo}
                        />
                    </>
                ) : (
                    <>
                        <LoadingComponent />
                    </>
                )
            }
        </>;
        return (
            html
        );
    }
}
import React from "react";
import "./top-branch-bar.css";
//
import { BaseService } from "../../services/base-service";
import { NavLink, Link } from "react-router-dom";
import shopeeIcon from "../../assets/images/icons/shopee-48.png";
import facebookIcon from "../../assets/images/icons/facebook-48.png";
import youtubeIcon from "../../assets/images/icons/youtube-48.png";

export class TopBranchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfCartItems: 0,
      target: "_blank",
    };
  }

  componentDidMount() {
    this.setNumberOfCartItems(this.props.productItemsInCart);
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.productItemsInCart) !==
        JSON.stringify(nextProps.productItemsInCart) ||
      this.props.productItemsInCart_LastestModifyTime !==
        nextProps.productItemsInCart_LastestModifyTime
    ) {
      this.setNumberOfCartItems(nextProps.productItemsInCart);
    }
  }

  setNumberOfCartItems = (productItemsInCart) => {
    this.setState({
      numberOfCartItems: productItemsInCart.length,
    });
  };

  getCartElement = () => {
    const element = (
      <>
        <div className="top-branch-bar-cart-div">
          {this.state.numberOfCartItems > 0 ? (
            <div className="top-branch-bar-cart-number-of-item-div">
              {this.state.numberOfCartItems}
            </div>
          ) : (
            <></>
          )}
          <Link to="/gio-hang">
            <i className="fas fa-shopping-cart top-branch-bar-cart-icon-i "></i>
          </Link>
        </div>
      </>
    );
    return element;
  };

  renderShopeeLink = () => {
    let element = <></>;
    if (this.props.footerInfoData && this.props.footerInfoData.socialLinks) {
      const footerInfoData = this.props.footerInfoData;
      if (footerInfoData.socialLinks[4]) {
        element = (
          <div className="top-branch-bar-hotline-number-div">
            {/* <i
              className="fab fa-youtube top-branch-hotline-social-i top-branch-hotline-social-youtube-i"
              title="Trang Youtube."
            ></i> */}
            <a
              href={footerInfoData.socialLinks[4].path}
              target={this.state.target}
            >
              <img
                className="top-branch-hotline-social-i"
                src={shopeeIcon}
                alt=""
              />
            </a>
          </div>
        );
      }
    }
    return element;
  };

  renderFacebookLink = () => {
    let element = <></>;
    if (this.props.footerInfoData && this.props.footerInfoData.socialLinks) {
      const footerInfoData = this.props.footerInfoData;
      if (footerInfoData.socialLinks[0]) {
        element = (
          <div className="top-branch-bar-hotline-number-div">
            {/* <i
              className="fab fa-youtube top-branch-hotline-social-i top-branch-hotline-social-youtube-i"
              title="Trang Youtube."
            ></i> */}
            <a
              href={`https://www.facebook.com/${footerInfoData.socialLinks[0].path}`}
              target={this.state.target}
            >
              <img
                className="top-branch-hotline-social-i"
                src={facebookIcon}
                alt=""
              />
            </a>
          </div>
        );
      }
    }
    return element;
  };

  renderYoutubeLink = () => {
    let element = <></>;
    if (this.props.footerInfoData && this.props.footerInfoData.socialLinks) {
      const footerInfoData = this.props.footerInfoData;
      if (footerInfoData.socialLinks[1]) {
        element = (
          <div className="top-branch-bar-hotline-number-div">
            {/* <i
              className="fab fa-youtube top-branch-hotline-social-i top-branch-hotline-social-youtube-i"
              title="Trang Youtube."
            ></i> */}
            <a
              href={footerInfoData.socialLinks[1].path}
              target={this.state.target}
            >
              <img
                className="top-branch-hotline-social-i"
                src={youtubeIcon}
                alt=""
              />
            </a>
          </div>
        );
      }
    }
    return element;
  };

  render() {
    const html = (
      <>
        <div
          id="TopBranchBarContainerDiv"
          className={`top-branch-bar-container-div${
            this.props.windowWidth >= 1200
              ? ""
              : " top-branch-bar-responsive-container-top"
          }${
            this.props.isOpenedReponseMenu
              ? " top-branch-bar-container-fixed-height-div"
              : ""
          }`}
        >
          <div className="top-branch-bar-hotline-div">
            {this.props.windowWidth >= 1200 ? (
              <>
                <div className="top-branch-bar-hotline-number-div">
                  Hotline:{" "}
                  <a
                    className="top-branch-bar-hotline-number-a"
                    href="tel:+84773588999"
                    title="Bấm để gọi cho chúng tôi."
                  >
                    0773 588 999
                  </a>
                </div>
                {/* <div className="top-branch-bar-hotline-number-div">
                  <i
                    className="fab fa-facebook top-branch-hotline-social-i top-branch-hotline-social-facebook-i"
                    title="Trang Facebook."
                  ></i>
                </div>
                <div className="top-branch-bar-hotline-number-div">
                  <i
                    className="fab fa-youtube top-branch-hotline-social-i top-branch-hotline-social-youtube-i"
                    title="Trang Youtube."
                  ></i>
                </div> */}
                {this.renderFacebookLink()}
                {this.renderYoutubeLink()}
                {this.renderShopeeLink()}
              </>
            ) : (
              <>
                <div className="top-branch-bar-logo-div">
                  <NavLink to="/trang-chu">{BaseService.getLogo()}</NavLink>
                </div>
              </>
            )}
          </div>
          {this.props.windowWidth >= 1200 ? (
            <>
              <div className="top-branch-bar-logo-div">
                <NavLink to="/trang-chu">{BaseService.getLogo()}</NavLink>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="top-branch-bar-search-div">
            {this.props.windowWidth >= 1200 ? (
              <>
                <div className="top-branch-bar-search-input-div">
                  <input
                    type="text"
                    className="top-branch-bar-search-input"
                    placeholder="Tìm kiếm ..."
                  />
                  <i className="fas fa-search top-branch-bar-search-input-icon-i"></i>
                </div>
                {/* {this.getCartElement()} */}
              </>
            ) : (
              <>
                {/* {this.getCartElement()} */}
                <div
                  className="top-branch-bar-responsive-cart-div"
                  onClick={this.props.toggleResponseMenu}
                >
                  <i
                    className={`fas fa-bars top-branch-bar-responsive-icon-i${
                      this.props.isOpenedReponseMenu
                        ? ""
                        : " top-branch-bar-cart-show-icon-i"
                    }`}
                  ></i>
                  <i
                    className={`fas fa-times top-branch-bar-responsive-icon-i${
                      this.props.isOpenedReponseMenu
                        ? " top-branch-bar-cart-show-icon-i"
                        : ""
                    }`}
                  ></i>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
    return html;
  }
}

import {
  homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_REQUEST,
  homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_SUCCESS,
  homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_ERROR,
  homepage_LOAD_SERVICE_LIST_REQUEST,
  homepage_LOAD_SERVICE_LIST_SUCCESS,
  homepage_LOAD_SERVICE_LIST_ERROR,
  homepage_LOAD_PRODUCT_CATEGORY_LIST_REQUEST,
  homepage_LOAD_PRODUCT_CATEGORY_LIST_SUCCESS,
  homepage_LOAD_PRODUCT_CATEGORY_LIST_ERROR,
  homepage_LOAD_PRODUCT_LIST_REQUEST,
  homepage_LOAD_PRODUCT_LIST_SUCCESS,
  homepage_LOAD_PRODUCT_LIST_ERROR,
  homepage_LOAD_VIDEO_LIST_REQUEST,
  homepage_LOAD_VIDEO_LIST_SUCCESS,
  homepage_LOAD_VIDEO_LIST_ERROR,
  homepage_LOAD_POLICY_LIST_REQUEST,
  homepage_LOAD_POLICY_LIST_SUCCESS,
  homepage_LOAD_POLICY_LIST_ERROR,
  homepage_LOAD_TYPICAL_FACE_LIST_REQUEST,
  homepage_LOAD_TYPICAL_FACE_LIST_SUCCESS,
  homepage_LOAD_TYPICAL_FACE_LIST_ERROR,
  homepage_LOAD_EVENT_LIST_REQUEST,
  homepage_LOAD_EVENT_LIST_SUCCESS,
  homepage_LOAD_EVENT_LIST_ERROR,
  homepage_LOAD_PARTNERS_LIST_REQUEST,
  homepage_LOAD_PARTNERS_LIST_SUCCESS,
  homepage_LOAD_PARTNERS_LIST_FAILURE,
} from "../actions/home-page-actions";
import { RequestStatus } from "../../constants";

const initialState = {
  imageSliderImagesListRequestStatus: RequestStatus.RQ_NEW,
  imageSliderImagesList: [],
  serviceListRequestStatus: RequestStatus.RQ_NEW,
  serviceList: [],
  productCategoryListRequestStatus: RequestStatus.RQ_NEW,
  productCategoryList: [],
  productListRequestStatus: RequestStatus.RQ_NEW,
  productList: [],
  videoListRequestStatus: RequestStatus.RQ_NEW,
  videoList: [],
  policyListRequestStatus: RequestStatus.RQ_NEW,
  policyList: [],
  typicalFaceListRequestStatus: RequestStatus.RQ_NEW,
  typicalFaceStarList: [],
  typicalFaceDistributorList: [],
  eventListRequestStatus: RequestStatus.RQ_NEW,
  eventList: [],
  getPartnersListRequestStatus: RequestStatus.RQ_NEW,
  getPartnersListRequestResult: [],
};

export function homePageReducer(state = initialState, actions) {
  switch (actions.type) {
    case homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_REQUEST: {
      return {
        ...state,
        imageSliderImagesListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_SUCCESS: {
      return {
        ...state,
        imageSliderImagesListRequestStatus: RequestStatus.RQ_SUCCESS,
        imageSliderImagesList: actions.payload.imageSliderImagesList,
        subImageSliderImagesList: actions.payload.subImageSliderImagesList,
      };
    }
    case homepage_LOAD_IMAGE_SLIDER_IMAGES_LIST_ERROR: {
      return {
        ...state,
        imageSliderImagesListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case homepage_LOAD_SERVICE_LIST_REQUEST: {
      return {
        ...state,
        serviceListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case homepage_LOAD_SERVICE_LIST_SUCCESS: {
      return {
        ...state,
        serviceListRequestStatus: RequestStatus.RQ_SUCCESS,
        serviceList: actions.payload.serviceList,
      };
    }
    case homepage_LOAD_SERVICE_LIST_ERROR: {
      return {
        ...state,
        serviceListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case homepage_LOAD_PRODUCT_CATEGORY_LIST_REQUEST: {
      return {
        ...state,
        productCategoryListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case homepage_LOAD_PRODUCT_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        productCategoryListRequestStatus: RequestStatus.RQ_SUCCESS,
        productCategoryList: actions.payload.productCategoryList,
      };
    }
    case homepage_LOAD_PRODUCT_CATEGORY_LIST_ERROR: {
      return {
        ...state,
        productCategoryListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case homepage_LOAD_PRODUCT_LIST_REQUEST: {
      return {
        ...state,
        productListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case homepage_LOAD_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        productListRequestStatus: RequestStatus.RQ_SUCCESS,
        productList: actions.payload.productList,
      };
    }
    case homepage_LOAD_PRODUCT_LIST_ERROR: {
      return {
        ...state,
        productListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case homepage_LOAD_VIDEO_LIST_REQUEST: {
      return {
        ...state,
        videoListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case homepage_LOAD_VIDEO_LIST_SUCCESS: {
      return {
        ...state,
        videoListRequestStatus: RequestStatus.RQ_SUCCESS,
        videoList: actions.payload.videoList,
      };
    }
    case homepage_LOAD_VIDEO_LIST_ERROR: {
      return {
        ...state,
        videoListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case homepage_LOAD_POLICY_LIST_REQUEST: {
      return {
        ...state,
        policyListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case homepage_LOAD_POLICY_LIST_SUCCESS: {
      return {
        ...state,
        policyListRequestStatus: RequestStatus.RQ_SUCCESS,
        policyList: actions.payload.policyList,
      };
    }
    case homepage_LOAD_POLICY_LIST_ERROR: {
      return {
        ...state,
        policyListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case homepage_LOAD_TYPICAL_FACE_LIST_REQUEST: {
      return {
        ...state,
        typicalFaceListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case homepage_LOAD_TYPICAL_FACE_LIST_SUCCESS: {
      const typicalFaceStarList = actions.payload.typicalFaceList.filter(
        (t) => t.isHot
      );
      const typicalFaceDistributorList = actions.payload.typicalFaceList.filter(
        (t) => !t.isHot
      );
      return {
        ...state,
        typicalFaceListRequestStatus: RequestStatus.RQ_SUCCESS,
        typicalFaceStarList: typicalFaceStarList,
        typicalFaceDistributorList: typicalFaceDistributorList,
      };
    }
    case homepage_LOAD_TYPICAL_FACE_LIST_ERROR: {
      return {
        ...state,
        typicalFaceListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case homepage_LOAD_EVENT_LIST_REQUEST: {
      return {
        ...state,
        eventListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case homepage_LOAD_EVENT_LIST_SUCCESS: {
      return {
        ...state,
        eventListRequestStatus: RequestStatus.RQ_SUCCESS,
        eventList: actions.payload.eventList,
      };
    }
    case homepage_LOAD_EVENT_LIST_ERROR: {
      return {
        ...state,
        eventListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case homepage_LOAD_PARTNERS_LIST_REQUEST: {
      return {
        ...state,
        getPartnersListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case homepage_LOAD_PARTNERS_LIST_SUCCESS: {
      return {
        ...state,
        getPartnersListRequestStatus: RequestStatus.RQ_SUCCESS,
        getPartnersListRequestResult:
          actions.payload.getPartnersListRequestResult,
      };
    }
    case homepage_LOAD_PARTNERS_LIST_FAILURE: {
      return {
        ...state,
        getPartnersListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    default: {
      return state;
    }
  }
}

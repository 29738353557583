import React from "react";
import "./partners-list.css";
import { Row, Col, Carousel } from "react-bootstrap";
import { BaseService } from "../../services/base-service";
import { OleeApiURL, RequestStatus } from "../../constants";

export class PartnersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      getPartnersListRequestResult: [],
      numberOfPartnerItemPerSlide: 4,
      target: "_blank",
    };
  }

  componentDidMount() {
    const windowWidth = this.props.windowWidth;
    this.setColSize(windowWidth);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.getPartnersListRequestStatus !==
      nextProps.getPartnersListRequestStatus &&
      nextProps.getPartnersListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      const getPartnersListRequestResult =
        nextProps.getPartnersListRequestResult;
      let res = BaseService.getOrderListWithName(getPartnersListRequestResult);
      this.setState({
        getPartnersListRequestResult: res,
      });
    }

    if (this.props.windowWidth !== nextProps.windowWidth) {
      const windowWidth = nextProps.windowWidth;
      this.setColSize(windowWidth);
    }
  }

  setColSize = (windowWidth) => {
    let numberOfPartnerItemPerSlide = this.state.numberOfPartnerItemPerSlide;
    if (windowWidth >= 992) {
      numberOfPartnerItemPerSlide = 4;
    } else if (windowWidth >= 768) {
      numberOfPartnerItemPerSlide = 4;
    } else {
      numberOfPartnerItemPerSlide = 2;
    }
    console.log(windowWidth + "-" + numberOfPartnerItemPerSlide);
    this.setState({
      numberOfPartnerItemPerSlide: numberOfPartnerItemPerSlide,
    });
  };

  renderPartnerGroupElement = (partnerGroup) => {
    const html = (
      <>
        <Row>
          {partnerGroup.map((partner) => (
            <Col xs={6} md={3}>
              <div className="partners-list-image-container-div">
                <a href={partner.url} target={this.state.target}>
                  <img
                    className="partners-list-image"
                    alt=""
                    src={`${OleeApiURL}/api/images/${partner.logoId}/${partner.logoName}`}
                  // src={BaseService.getImageUrlFromServer(
                  //   partner.logoId,
                  //   partner.logoName
                  // )}
                  />
                </a>
              </div>
            </Col>
          ))}
        </Row>
      </>
    );
    return html;
  };

  renderCarousel = () => {
    let partnerGroup = [];
    let index = 0;
    const getPartnersListRequestResult =
      this.state.getPartnersListRequestResult;
    if (getPartnersListRequestResult.length > 0) {
      do {
        const partnerGroupItem = getPartnersListRequestResult.slice(
          index,
          index + this.state.numberOfPartnerItemPerSlide
        );
        partnerGroup.push(partnerGroupItem);
        index += this.state.numberOfPartnerItemPerSlide;
      } while (index < getPartnersListRequestResult.length);
    }
    const html = (
      <>
        <Carousel
          id="TypicalFaceCarousel"
          className="typical-face-carousel"
          activeIndex={this.state.carouselActiveIndex}
          onSelect={(index) => {
            this.setState({
              carouselActiveIndex: index,
              targetPartner: partnerGroup[index][0],
            });
          }}
          controls={false}
          interval={null}
        >
          {partnerGroup.map((groupItem, index) => (
            <Carousel.Item key={index}>
              {this.renderPartnerGroupElement(groupItem)}
            </Carousel.Item>
          ))}
          {/* {this.state.getPartnersListRequestResult.map((typicalFaceItem, index) => (
            <Carousel.Item key={index} className="typical-face-carousel-item">
              {this.getTypicalFaceElement(typicalFaceItem)}
            </Carousel.Item>
          ))} */}
        </Carousel>
      </>
    );
    return html;
  };

  render() {
    const html = (
      <>
        <div id="partnersListContainerDiv" className="component-container-div">
          <div
            id="partnersListContainerTitleDiv"
            className="component-title-div"
            style={{
              backgroundImage: `url('${this.props.home_page_title_jpg}')`,
            }}
          >
            <div className="component-title-text-div">
              <div>
                Đối Tác Thương Hiệu
                <div className="component-title-hr"></div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div>{this.renderCarousel()}</div>
        </div>
      </>
    );
    return html;
  }
}

import React from "react";
import "./typical-face-star-list.css";
//
import { Row, Col, Carousel } from "react-bootstrap";
import { BaseService } from "../../services/base-service";
import { RequestStatus } from "../../constants";
import { Link } from "react-router-dom";

export class TypicalFaceStarList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      target: "_blank",
      typicalFaceStarList: [],
      numberOfKOLItemPerSlide: 3,
      targetKOL: null,
      carouselActiveIndex: 0,
    };
  }

  componentDidMount() {
    this.setImageHeight();
    const windowWidth = this.props.windowWidth;
    this.setColSize(windowWidth);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.typicalFaceListRequestStatus !==
        nextProps.typicalFaceListRequestStatus &&
      nextProps.typicalFaceListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      const typicalFaceStarList = nextProps.typicalFaceStarList;
      let res = BaseService.getOrderListWithName(typicalFaceStarList);
      let targetKOL = null;
      if (res.length > 0) {
        targetKOL = res[0];
      }
      this.setState({
        typicalFaceStarList: res,
        targetKOL: targetKOL,
      });

      setTimeout(() => {
        console.log("TypicalFaceList - componentWillReceiveProps");
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
      }, 0);
    }

    if (this.props.windowWidth !== nextProps.windowWidth) {
      const windowWidth = nextProps.windowWidth;
      this.setColSize(windowWidth);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  setColSize = (windowWidth) => {
    let numberOfKOLItemPerSlide = this.state.numberOfKOLItemPerSlide;
    if (windowWidth >= 992) {
      numberOfKOLItemPerSlide = 3;
    } else if (windowWidth >= 768) {
      numberOfKOLItemPerSlide = 3;
    } else {
      numberOfKOLItemPerSlide = 1;
    }
    console.log(windowWidth + "-" + numberOfKOLItemPerSlide);
    this.setState({
      numberOfKOLItemPerSlide: numberOfKOLItemPerSlide,
    });
  };

  handleResize = () => {
    this.setImageHeight();
  };

  setImageHeight = () => {
    BaseService.setHeightByWidthOfClass("typical-face-img-boder-div");
    setTimeout(() => {
      // BaseService.setMaxHeightByClassName('typical-face-description-div');
      setInterval(() => {
        BaseService.setMaxHeightByClassName("typical-face-carousel-item");
      }, 500);
      const TypicalFaceCarousel = document.getElementById(
        "TypicalFaceCarousel"
      );
      if (TypicalFaceCarousel) {
        TypicalFaceCarousel.classList.remove("typical-face-carousel");
      }
    }, 1000);
  };

  getTypicalFaceElement = (typicalFaceItem) => {
    const element = (
      <>
        <Row className="typical-face-list-container">
          <Col lg={1} className="d-none d-lg-block"></Col>
          <Col lg={5} md={6}>
            <div className="typical-face-star-item-div">
              <Link to={`/guong-mat-nghe-si/${typicalFaceItem.id}`}>
                <div
                  className="typical-face-title-div"
                  dangerouslySetInnerHTML={{
                    __html: typicalFaceItem.displayName,
                  }}
                ></div>
                <div className="d-block d-md-none">
                  <img
                    className="typical-face-img"
                    alt=""
                    src={typicalFaceItem.imgURL}
                  />
                </div>
                <div className="typical-face-description-div">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: typicalFaceItem.description,
                    }}
                  ></div>
                </div>
              </Link>
              {!BaseService.stringIsNullOrWhiteSpace(typicalFaceItem.url) ? (
                <a
                  href={typicalFaceItem.url}
                  target={this.state.target}
                  className="typical-face-facebook-a"
                >
                  <i className="fab fa-facebook"></i> Xem thêm
                </a>
              ) : (
                <></>
              )}
            </div>
          </Col>
          <Col lg={5} md={6} className="d-none d-md-block">
            <Link to={`/guong-mat-nghe-si/${typicalFaceItem.id}`}>
              <img
                className="typical-face-img typical-star-face-img"
                alt=""
                src={typicalFaceItem.imgURL}
              />
            </Link>
          </Col>
          <Col lg={1} className="d-none d-lg-block"></Col>
        </Row>
        <br />
      </>
    );
    return element;
  };

  handleOnClickKOL = (kol) => {
    this.setState((state) => ({
      targetKOL: kol,
    }));
  };

  getTartgetKOLDecription = () => {
    let result = "";
    if (this.state.targetKOL) {
      result = `<i class="fas fa-quote-left"></i>${this.state.targetKOL.description}<i class="fas fa-quote-right"></i>`;
    }
    return result;
  };

  renderKOLGroupElement = (kolGroup) => {
    const html = (
      <>
        <Row>
          {kolGroup.map((kol) => (
            <Col xs={12} md={4}>
              <div
                className={`kol-item-container-div${
                  this.state.targetKOL && this.state.targetKOL.id === kol.id
                    ? " kol-target-item-container-div"
                    : ""
                }`}
                onClick={() => this.handleOnClickKOL(kol)}
              >
                <div className="kol-img-container-div">
                  <img className="kol-img" alt="" src={kol.imgURL} />
                </div>
                <h2 className="kol-name-h2">{kol.name}</h2>
                <div className="kol-name-div">Nói gì về sản phẩm</div>
                <i class="fas fa-caret-down kol-arrow-i"></i>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            {this.state.targetKOL ? (
              <div
                className="kol-description-div"
                dangerouslySetInnerHTML={{
                  __html: this.getTartgetKOLDecription(),
                }}
              ></div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <br />
        <br />
      </>
    );
    return html;
  };

  renderCarousel = () => {
    let kolGroup = [];
    let index = 0;
    const typicalFaceStarList = this.state.typicalFaceStarList;
    if (typicalFaceStarList.length > 0) {
      do {
        const kolGroupItem = typicalFaceStarList.slice(
          index,
          index + this.state.numberOfKOLItemPerSlide
        );
        kolGroup.push(kolGroupItem);
        index += this.state.numberOfKOLItemPerSlide;
      } while (index < typicalFaceStarList.length);
    }
    const html = (
      <>
        <Carousel
          id="TypicalFaceCarousel"
          className="typical-face-carousel"
          activeIndex={this.state.carouselActiveIndex}
          onSelect={(index) => {
            this.setState({
              carouselActiveIndex: index,
              targetKOL: kolGroup[index][0],
            });
          }}
          controls={false}
          interval={null}
        >
          {kolGroup.map((groupItem, index) => (
            <Carousel.Item key={index}>
              {this.renderKOLGroupElement(groupItem)}
            </Carousel.Item>
          ))}
          {/* {this.state.typicalFaceStarList.map((typicalFaceItem, index) => (
            <Carousel.Item key={index} className="typical-face-carousel-item">
              {this.getTypicalFaceElement(typicalFaceItem)}
            </Carousel.Item>
          ))} */}
        </Carousel>
      </>
    );
    return html;
  };

  render() {
    const html = (
      <>
        <div
          id="typicalFaceListContainerDiv"
          className="component-container-div typical-container-div"
        >
          <div
            id="typicalFaceListContainerTitleDiv"
            className="component-title-div"
            style={{
              backgroundImage: `url('${this.props.home_page_title_jpg}')`,
            }}
          >
            <div className="component-title-text-div component-kols-title-text-div">
              <div>
                KOLS
                {/* <div className="component-title-hr"></div> */}
              </div>
            </div>
          </div>
          <br />
          <br />
          <div>{this.renderCarousel()}</div>
        </div>
      </>
    );
    return html;
  }
}

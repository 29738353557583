import React from "react";
import "./admin-typical-face-page.css";
//
import { AdminBannerList } from "../components/admin-layout/admin-banner-list";

export class AdminBannerPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <AdminBannerList
          windowWidth={this.props.windowWidth}
          bannerListRequestStatus={this.props.bannerListRequestStatus}
          bannerList={this.props.bannerList}
          bannerDetailRequestStatus={this.props.bannerDetailRequestStatus}
          bannerDetail={this.props.bannerDetail}
          insertBannerRequestStatus={this.props.insertBannerRequestStatus}
          insertedBanner={this.props.insertedBanner}
          updateBannerRequestStatus={this.props.updateBannerRequestStatus}
          updatedBanner={this.props.updatedBanner}
          updateBannerOrderNumberRequestStatus={
            this.props.updateBannerOrderNumberRequestStatus
          }
          updatedBannerOrderNumber={this.props.updatedBannerOrderNumber}
          updateBannerIsDeletedRequestStatus={
            this.props.updateBannerIsDeletedRequestStatus
          }
          updatedBannerIsDeleted={this.props.updatedBannerIsDeleted}
          deleteBannerRequestStatus={this.props.deleteBannerRequestStatus}
          deletedBanner={this.props.deletedBanner}
          bannerItemListRequestStatus={this.props.bannerItemListRequestStatus}
          bannerItemList={this.props.bannerItemList}
          bannerItemDetailRequestStatus={
            this.props.bannerItemDetailRequestStatus
          }
          bannerItemDetail={this.props.bannerItemDetail}
          insertBannerItemRequestStatus={
            this.props.insertBannerItemRequestStatus
          }
          insertedBannerItem={this.props.insertedBannerItem}
          updateBannerItemRequestStatus={
            this.props.updateBannerItemRequestStatus
          }
          updatedBannerItem={this.props.updatedBannerItem}
          updateBannerItemOrderNumberRequestStatus={
            this.props.updateBannerItemOrderNumberRequestStatus
          }
          updatedBannerItemOrderNumber={this.props.updatedBannerItemOrderNumber}
          updateBannerItemIsDeletedRequestStatus={
            this.props.updateBannerItemIsDeletedRequestStatus
          }
          updatedBannerItemIsDeleted={this.props.updatedBannerItemIsDeleted}
          deleteBannerItemRequestStatus={
            this.props.deleteBannerItemRequestStatus
          }
          deletedBannerItem={this.props.deletedBannerItem}
          errorMessage={this.props.bannerErrorMessage}
          //
          getBannerList={this.props.getBannerList}
          getBannerDetail={this.props.getBannerDetail}
          insertBanner={this.props.insertBanner}
          updateBanner={this.props.updateBanner}
          updateBanner_OrderNumber={this.props.updateBanner_OrderNumber}
          updateBanner_IsDeleted={this.props.updateBanner_IsDeleted}
          deleteBanner={this.props.deleteBanner}
          getBannerItemList={this.props.getBannerItemList}
          getBannerItemDetail={this.props.getBannerItemDetail}
          insertBannerItem={this.props.insertBannerItem}
          updateBannerItem={this.props.updateBannerItem}
          updateBannerItem_OrderNumber={this.props.updateBannerItem_OrderNumber}
          updateBannerItem_IsDeleted={this.props.updateBannerItem_IsDeleted}
          deleteBannerItem={this.props.deleteBannerItem}
          //
          productList={this.props.productList}
          getProductList={this.props.getProductList}
        />
      </>
    );
    return html;
  }
}

import React from "react";
import "./product-paging-list.css";
//
import "./product-list.css";
// import { RequestStatus } from "../../constants";
// import { LoadingComponent } from "../public/loading-component";
import { ProductItem } from "../web-user-layout/product-item";
import { Container, Row, Col } from "react-bootstrap";
import { BaseService } from "../../services/base-service";
import {
  FormNAEmement,
  FormPagination,
  FormSelect,
  FormTextInput,
} from "../public/form-components";

export class ProductPagingList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayNumberOptions: [
        // { value: 1, text: 1 },
        { value: 20, text: 20 },
        { value: 40, text: 40 },
        { value: 80, text: 80 },
      ],
      numberOfItemsDisplay: 20,
      currentPageIndex: 0,
      groupOfProductList: [],
      productSearchKey: "",
      numberOfDisplayItem: 0,
      selectedCategoryId: null,
      selectedTags: [],
    };
  }

  componentDidMount() {
    this.setGroupOfProductList(this.props.productList);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.categoryList !== nextProps.categoryList) {
  //   }
  // }

  setGroupOfProductList = (productList) => {
    productList = BaseService.getOrderListWithName(
      productList.filter((t) => !t.isDeleted || t.isDeleted === false)
    );

    // -1: SP Mới, -2: SP Hot, -3: Sale 1.1
    if (this.state.selectedCategoryId) {
      if (this.state.selectedCategoryId >= 0) {
        productList = productList.filter(
          (t) =>
            t.categoryId === this.state.selectedCategoryId ||
            (t.saleInfo &&
              t.saleInfo.subCategories &&
              t.saleInfo.subCategories.findIndex(
                (k) => k.value === this.state.selectedCategoryId
              ) !== -1)
        );
      } else {
        switch (this.state.selectedCategoryId) {
          case -1: {
            productList = productList.filter((t) => t.isNew === true);
            break;
          }
          case -2: {
            productList = productList.filter((t) => t.isHot === true);
            break;
          }
          case -3: {
            productList = productList
              .filter((t) => t.saleInfo && t.saleInfo.sale11 === true)
              .map((t) => {
                t.orderNumber = t.saleInfo.sale11OrderNumber
                  ? t.saleInfo.sale11OrderNumber
                  : 0;
                return t;
              });
            productList = BaseService.getOrderListWithName(productList);
            break;
          }
          default: {
          }
        }
      }
    }

    if (this.state.selectedTags.length > 0) {
      let selectedTagsProductItemList = [];
      productList.forEach((item) => {
        let isShowItem = false;
        this.state.selectedTags.forEach((tag) => {
          if (!BaseService.stringIsNullOrWhiteSpace(item.tags)) {
            if (
              item.tags.toLowerCase().indexOf(tag.title.toLowerCase()) !== -1
            ) {
              isShowItem = true;
            }
          }
        });
        if (isShowItem) {
          selectedTagsProductItemList.push(item);
        }
      });
      productList = selectedTagsProductItemList;
    }

    if (!BaseService.stringIsNullOrWhiteSpace(this.state.productSearchKey)) {
      productList = productList.filter(
        (t) =>
          BaseService.transformVNIToEnglishCase(t.name)
            .trim()
            .toLowerCase()
            .indexOf(
              BaseService.transformVNIToEnglishCase(
                this.state.productSearchKey.toLowerCase()
              )
            ) !== -1
      );
    }

    this.setState({
      numberOfDisplayItem: productList.length,
    });

    let groupOfProductList = [];
    while (productList.length > 0) {
      groupOfProductList.push(
        productList.slice(0, this.state.numberOfItemsDisplay)
      );
      productList = productList.slice(this.state.numberOfItemsDisplay);
    }
    this.setState({
      groupOfProductList: groupOfProductList,
    });
  };

  getProductListElement = () => {
    const element = (
      <>
        <Row>
          {this.state.groupOfProductList[this.state.currentPageIndex].map(
            (item) => (
              <>
                <Col
                  xs={6}
                  md={3}
                  lg={3}
                  className="product-paging-product-item-col"
                >
                  <ProductItem
                    productItem={item}
                    addProductItemToCart={this.props.addProductItemToCart}
                  />
                </Col>
              </>
            )
          )}
        </Row>
      </>
    );
    return element;
  };

  handleOnPageChange = (pageIndex) => {
    document.documentElement.scrollTop = 0;
    this.setState({
      currentPageIndex: pageIndex,
    });
  };

  getPagingElement = () => {
    const element = (
      <>
        <div className="product-paging-wrapper-div">
          {
            <FormPagination
              pageCount={this.state.groupOfProductList.length}
              pageIndex={this.state.currentPageIndex}
              handleOnPageChange={this.handleOnPageChange}
            />
          }
        </div>
      </>
    );
    return element;
  };

  handleOnChangeNumberOfItemsDisplay = (value) => {
    this.setState(
      {
        numberOfItemsDisplay: value,
        currentPageIndex: 0,
      },
      () => {
        this.setGroupOfProductList(this.props.productList);
      }
    );
  };

  handleOnChangeProductSearchKey = (value) => {
    this.setState(
      {
        productSearchKey: value,
        currentPageIndex: 0,
      },
      () => {
        this.setGroupOfProductList(this.props.productList);
      }
    );
  };

  getProductListFilter = () => {
    const element = (
      <>
        {this.state.numberOfDisplayItem > 0 ? (
          <div className="product-paging-filter-wrapper-div">
            <div className="product-paging-filter-number-of-product-div">
              {this.state.numberOfDisplayItem} sp
            </div>
            <div className="product-paging-filter-search-input-div">
              <FormTextInput
                placeholder="Tìm kiếm ..."
                value={this.state.productSearchKey}
                onChange={this.handleOnChangeProductSearchKey}
              />
            </div>
            <div className="product-paging-filter-number-of-options-div">
              <FormSelect
                options={this.state.displayNumberOptions}
                selectedValue={this.state.numberOfItemsDisplay}
                onChange={this.handleOnChangeNumberOfItemsDisplay}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  getLeftComlumnElement = () => {
    const element = (
      <>
        <div className="product-paging-left-column-wrapper-div">
          {this.getSaleListElement()}
          {this.getCategoryListElement()}
        </div>
      </>
    );
    return element;
  };

  handleOnClickCategoryItem = (categoryItem) => {
    let categoryItemId = categoryItem.id;
    if (this.state.selectedCategoryId === categoryItemId) {
      categoryItemId = null;
    }
    this.setState(
      {
        selectedCategoryId: categoryItemId,
        selectedTags: [],
        currentPageIndex: 0,
      },
      () => {
        this.setGroupOfProductList(this.props.productList);
      }
    );
  };

  isSelectedTag = (tagId) => {
    const idIndex = this.state.selectedTags.findIndex((t) => t.id === tagId);
    return idIndex !== -1 ? true : false;
  };

  handleOnClickTagItem = (tagItem) => {
    let selectedTags = this.state.selectedTags;
    const idIndex = selectedTags.findIndex((t) => t.id === tagItem.id);
    if (idIndex === -1) {
      selectedTags.push(tagItem);
    } else {
      selectedTags.splice(idIndex, 1);
    }
    console.log("selectedTags", selectedTags);
    this.setState(
      {
        selectedTags: selectedTags,
      },
      () => {
        this.setGroupOfProductList(this.props.productList);
      }
    );
  };

  getCategoryTagsElement = (categoryItem) => {
    const tags = categoryItem.tags.map((t) => {
      return {
        ...t,
        name: t.title,
      };
    });
    const element = (
      <>
        {tags && tags.length > 0 ? (
          <>
            <div className="product-paging-category-tags-wrapper-div">
              {BaseService.getOrderListWithName(tags).map((item) => (
                <>
                  <div
                    className={`product-list-catrgory-tag-item-div${
                      this.isSelectedTag(item.id)
                        ? " product-list-catrgory-tag-selected-item-div"
                        : ""
                    }`}
                    onClick={() => {
                      this.handleOnClickTagItem(item);
                    }}
                  >
                    {item.name}
                  </div>
                </>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  getSaleListElement = () => {
    const element = (
      <>
        <div className="product-paging-category-wrapper-div product-paging-sale-wrapper-div">
          <div
            className={`product-paging-left-column-option-div product-paging-left-column-option-all-div${
              this.state.selectedCategoryId === -1
                ? " product-paging-left-column-option-selected-div"
                : ""
            }`}
            onClick={() => {
              this.handleOnClickCategoryItem({ id: -1 });
            }}
          >
            <i class="fas fa-certificate"></i> Sản phẩm mới
          </div>
          <div
            className={`product-paging-left-column-option-div product-paging-left-column-option-all-div${
              this.state.selectedCategoryId === -2
                ? " product-paging-left-column-option-selected-div"
                : ""
            }`}
            onClick={() => {
              this.handleOnClickCategoryItem({ id: -2 });
            }}
          >
            <i class="fas fa-fire"></i> Sản phẩm nổi bật
          </div>
          <div
            className={`product-paging-left-column-option-div product-paging-left-column-option-all-div${
              this.state.selectedCategoryId === -3
                ? " product-paging-left-column-option-selected-div"
                : ""
            }`}
            onClick={() => {
              this.handleOnClickCategoryItem({ id: -3 });
            }}
          >
            <i class="fas fa-tag"></i> Sale 1.1
          </div>
        </div>
      </>
    );
    return element;
  };

  getCategoryListElement = () => {
    let categoryList = this.props.categoryList;
    categoryList = BaseService.getOrderListWithName(
      categoryList.filter((t) => !t.isDeleted || t.isDeleted === false)
    );

    const element = (
      <>
        <div className="product-paging-category-wrapper-div">
          <div className="product-paging-left-column-title-div">
            Thương hiệu
          </div>
          <div>
            <div
              className={`product-paging-left-column-option-div product-paging-left-column-option-all-div${
                this.state.selectedCategoryId === null
                  ? " product-paging-left-column-option-selected-div"
                  : ""
              }`}
              onClick={() => {
                this.handleOnClickCategoryItem({ id: null });
              }}
            >
              Toàn bộ
            </div>
            {categoryList.map((item) => (
              <>
                <div
                  className={`product-paging-left-column-option-div${
                    item.id === this.state.selectedCategoryId
                      ? " product-paging-left-column-option-selected-div"
                      : ""
                  }`}
                  onClick={() => {
                    this.handleOnClickCategoryItem(item);
                  }}
                >
                  {item.id === this.state.selectedCategoryId ? (
                    <i class="fas fa-circle"></i>
                  ) : (
                    <i class="far fa-circle"></i>
                  )}
                  {item.name}
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: item.name,
                    }}
                  ></div> */}
                </div>
                {this.getCategoryTagsElement(item)}
              </>
            ))}
          </div>
        </div>
      </>
    );
    return element;
  };

  getProductPagingProductElement = () => {
    const element = (
      <>
        <Row>
          <Col>{this.getProductListFilter()}</Col>
        </Row>
        {this.state.groupOfProductList.length > 0 ? (
          <>
            <Row>
              <Col>{this.getProductListElement()}</Col>
            </Row>
            <Row>
              <Col>{this.getPagingElement()}</Col>
            </Row>
          </>
        ) : (
          <FormNAEmement message="Chưa có sản phẩm nào!" />
        )}
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        {/* <b>this.props.productList.length: {this.props.productList.length}</b><br />
            <b>this.state.groupOfProductList.length: {this.state.groupOfProductList.length}</b> */}
        <Container fluid={true}>
          <Container fluid={true} className="fluid-container">
            <Row>
              <Col md={3} lg={2} className="product-paging-left-column-col">
                {this.getLeftComlumnElement()}
              </Col>
              <Col md={9} lg={10}>
                {this.getProductPagingProductElement()}
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
    return html;
  }
}

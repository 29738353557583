import React from "react";
import "./image-slider.css";
//
import { Carousel } from "react-bootstrap";
import { BaseService } from "../../services/base-service";
import { Link } from "react-router-dom";
import { FormVideo } from "../../components/public/form-components";

export class ImageSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      defaultIntervalTime: 20000,
      imageInterval: null,
    };
  }

  componentDidMount() {
    if (this.props.isImageNavigation) {
      this.setState({
        imageInterval: this.setImageInterval(),
      });
    }
    if (!BaseService.stringIsNullOrWhiteSpace(this.props.id)) {
      this.setImageSlideHeight(this.props);
      // const limitWidth = 992;
      // const imageSliderWidth = (this.props.windowWidth >= limitWidth)?(1360):(1360);
      // const imageSliderHeight = (this.props.windowWidth >= limitWidth)?(450):(720);
      // document.getElementById(this.props.id).style.height = `${BaseService.getHeightByRatio(imageSliderWidth, imageSliderHeight)}px`;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.imageSliderImagesList) !==
      JSON.stringify(nextProps.imageSliderImagesList)
    ) {
      console.log("ImageSlider - componentWillReceiveProps");
      this.setState({
        activeIndex: 0,
      });
    }
    if (this.props.windowWidth !== nextProps.windowWidth) {
      console.log("ImageSlider - componentWillReceiveProps");
      if (!BaseService.stringIsNullOrWhiteSpace(this.props.id)) {
        this.setImageSlideHeight(nextProps);
        // const limitWidth = 992;
        // const imageSliderWidth = (nextProps.windowWidth >= limitWidth)?(1360):(1360);
        // const imageSliderHeight = (nextProps.windowWidth >= limitWidth)?(450):(720);
        // document.getElementById(this.props.id).style.height = `${BaseService.getHeightByRatio(imageSliderWidth, imageSliderHeight)}px`;
      }
    }
  }

  setImageSlideHeight = (props) => {
    // const limitWidth = 992;
    // const imageSliderWidth = (props.windowWidth >= limitWidth)?(1360):(1360);
    // const imageSliderHeight = (props.windowWidth >= limitWidth)?(600):(552);
    if (this.props.windowWidth > 767) {
      document.getElementById(
        props.id
      ).style.height = `${BaseService.getHeightByRatio(1920, 848)}px`;
    }
  };

  setImageInterval = () => {
    return setInterval(
      () => {
        let activeIndex = this.state.activeIndex;
        activeIndex =
          activeIndex === this.props.imageSliderImagesList.length - 1
            ? 0
            : activeIndex + 1;
        this.setState({
          activeIndex: activeIndex,
        });
      },
      this.props.intervalTime
        ? this.props.intervalTime
        : this.state.defaultIntervalTime
    );
  };

  openLinkUrl = (imgItem) => {
    if (!BaseService.stringIsNullOrWhiteSpace(imgItem.linkUrl)) {
      window.open(imgItem.linkUrl);
    }
  };

  renderImageItemElement = (imgItem) => {
    let url = imgItem.imageUrl;
    if (imgItem.smallImageUrl && this.props.windowWidth < 768) {
      url = imgItem.smallImageUrl;
    }
    const html = (
      <img
        onClick={() => {
          this.openLinkUrl(imgItem);
        }}
        className="d-block w-100 image-slider-carousel-img"
        src={url}
        alt={`Slide`}
      />
    );
    return html;
  };

  getSliderItem = (imgItem) => {
    let element = <></>;
    if (this.props.isHomePageImage === true) {
      element = BaseService.stringIsNullOrWhiteSpace(imgItem.videoUrl) ? (
        <>{this.renderImageItemElement(imgItem)}</>
      ) : (
        <>
          <div className="image-slider-carousel-video">
            <FormVideo
              videoUrl={imgItem.videoUrl}
              canAccess={true}
              autoPlay={false}
              // videoUrl='https://www.youtube.com/watch?v=3QfDDKkxXUc'
            />
          </div>
        </>
      );
    } else {
      element = BaseService.isVideoFile(imgItem.url) ? (
        // <video autoplay muted loop id='myVideo'>
        //     <source src='rain.mp4' type='video/mp4'>
        // </video>
        <video autoPlay muted loop className="image-slider-carousel-video">
          <source type="video/mp4" src={imgItem.url}></source>
        </video>
      ) : (
        <img
          className="d-block w-100 image-slider-carousel-img"
          src={imgItem.url}
          alt={`Slide`}
        />
      );
    }
    return element;
  };

  isChanging = false;
  changeActiveIndex = (index) => {
    if (!this.isChanging) {
      this.isChanging = true;
      clearInterval(this.state.imageInterval);
      this.setState({
        activeIndex: index,
        imageInterval: this.setImageInterval(),
      });
      setTimeout(() => {
        this.isChanging = false;
      }, 700);
    }
  };

  getImageNavigationElement = () => {
    const element = (
      <>
        <div className="image-slider-image-navigation-div">
          <div>
            {this.props.imageSliderImagesList.map((imgItem, index) => (
              <React.Fragment key={index}>
                <div
                  onClick={() => {
                    this.changeActiveIndex(index);
                  }}
                  className={`image-slider-image-navigation-item-div${
                    index === this.state.activeIndex
                      ? " image-slider-image-navigation-active-item-div"
                      : ""
                  }`}
                  style={{
                    backgroundImage: `url(${imgItem.url})`,
                  }}
                ></div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </>
    );
    return element;
  };

  getImageSliderContainerElement = (imageLisElement) => {
    const element = (
      <>
        <div id={this.props.id} className={`image-slider-container-div`}>
          <Carousel
            className="image-slider-carousel-div"
            nextIcon={
              <i className="image-slider-caroudel-playback-icon fas fa-caret-right"></i>
            }
            prevIcon={
              <i className="image-slider-caroudel-playback-icon fas fa-caret-left"></i>
            }
            interval={
              this.props.intervalTime
                ? this.props.intervalTime
                : this.state.defaultIntervalTime
            }
          >
            {imageLisElement}
          </Carousel>
        </div>
      </>
    );
    return element;
  };

  // onClickNextImage = () => {
  //     const activeIndex = this.state.activeIndex;
  //     const nextActiveIndex = (activeIndex === this.props.imageSliderImagesList.length - 1)?(0):(activeIndex + 1);
  //     this.changeActiveIndex(nextActiveIndex);
  // }

  // onClickPrevImage = () => {
  //     const activeIndex = this.state.activeIndex;
  //     const preActiveIndex = (activeIndex === 0)?(this.props.imageSliderImagesList.length - 1):(activeIndex - 1);
  //     this.changeActiveIndex(preActiveIndex);
  // }

  getImageSliderImageNavigationContainerElement = (imageLisElement) => {
    const element = (
      <>
        <div
          id={this.props.id}
          className={`image-slider-container-div image-slider-image-navigation-container-div`}
        >
          <Carousel
            id="ImageSliderCarousel"
            className="image-slider-carousel-div"
            nextIcon={
              <i className="image-slider-caroudel-playback-icon fas fa-caret-right"></i>
            }
            prevIcon={
              <i className="image-slider-caroudel-playback-icon fas fa-caret-left"></i>
            }
            activeIndex={this.state.activeIndex}
            onSelect={(index) => {
              this.setState({ activeIndex: index });
            }}
          >
            {imageLisElement}
          </Carousel>
          {this.getImageNavigationElement()}
        </div>
      </>
    );
    // setTimeout(() => {
    //     BaseService.addClickFunctionToClass('image-slider-caroudel-playback-next-icon', this.onClickNextImage);
    //     BaseService.addClickFunctionToClass('image-slider-caroudel-playback-prev-icon', this.onClickPrevImage);
    //     let classList = document.getElementsByClassName('carousel-inner');
    //     for(var i = 0; i < classList.length; i++) {
    //         var classItem = classList[i];
    //         classItem.addEventListener("touchstart", this.startTouch, false);
    //         classItem.addEventListener("touchmove", this.moveTouch, false);
    //     }
    // }, 0);
    return element;
  };

  // initialX = null;
  // initialY = null;

  // startTouch = (e) => {
  //     this.initialX = e.touches[0].clientX;
  //     this.initialY = e.touches[0].clientY;
  // }

  // moveTouch = (e) => {
  //     if (this.initialX === null) {
  //         return;
  //       }

  //       if (this.initialY === null) {
  //         return;
  //       }

  //       var currentX = e.touches[0].clientX;
  //       var currentY = e.touches[0].clientY;

  //       var diffX = this.initialX - currentX;
  //       var diffY = this.initialY - currentY;

  //       if (Math.abs(diffX) > Math.abs(diffY)) {
  //         // sliding horizontally
  //         if (diffX > 0) {
  //           // swiped left
  //           this.onClickNextImage();
  //         } else {
  //           // swiped right
  //           this.onClickPrevImage();
  //         }
  //       }
  //     //   else {
  //     //     // sliding vertically
  //     //     if (diffY > 0) {
  //     //       // swiped up
  //     //       console.log("swiped up");
  //     //     } else {
  //     //       // swiped down
  //     //       console.log("swiped down");
  //     //     }
  //     //   }

  //       this.initialX = null;
  //       this.initialY = null;

  //       e.preventDefault();
  // }

  getElementList = () => {
    return this.props.imageSliderImagesList.map((imgItem, index) => (
      <Carousel.Item key={index}>
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {this.getSliderItem(imgItem)}
        </Link>
      </Carousel.Item>
    ));
  };

  render() {
    const html = (
      <>
        {/* <h3>windowWidth: {this.props.windowWidth}</h3> */}
        {this.props.isImageNavigation
          ? this.getImageSliderImageNavigationContainerElement(
              this.getElementList()
            )
          : this.getImageSliderContainerElement(this.getElementList())}
      </>
    );
    return html;
  }
}

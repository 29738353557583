import React from "react";
import "./product-detail.css";
import MetaTags from "react-meta-tags";
//
import { RequestStatus } from "../../constants";
import { LoadingComponent } from "../public/loading-component";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { ImageSlider } from "./image-slider";
import { ButtonComponent } from "../public/button-component";
import { BaseService } from "../../services/base-service";
import { ColorTagsSlider } from "./color-tags-slider";
import { FormButton, FormTextInput } from "../public/form-components";
import phone_png from "../../assets/images/icons/phone.png";
import zalo_png from "../../assets/images/icons/zalo.png";
import facebook_messenge_png from "../../assets/images/icons/facebook-messenge.png";
import { SaleRequestsService } from "../../services/sale-requests-service";

export class ProductDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      colorTagItemSelectedId: null,
      target: "_blank",
      customerName: "",
      customerPhone: "",
      customerPhoneTouch: false,
      customerPhoneError: null,
      customerPhoneErrorMessage: "Xin vui lòng để lại số điện thoại",
      isInsertSaleRequestsSuccess: false,
    };
  }

  componentDidMount() {
    console.log("componentDidMount - this.props", this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.insertSaleRequestsRequestStatus !==
      nextProps.insertSaleRequestsRequestStatus &&
      nextProps.insertSaleRequestsRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState((state) => ({
        isInsertSaleRequestsSuccess: true,
      }));
    }
  }

  convertToImageURLList = () => {
    const list = [];
    let images = this.props.productDetail.images;
    if (this.state.colorTagItemSelectedId) {
      images = images.filter(
        (t) =>
          t.colorTags &&
          t.colorTags.findIndex(
            (y) => y.id === this.state.colorTagItemSelectedId
          ) !== -1
      );
    } else {
      images = images.filter(
        (t) => !t.colorTags || t.colorTags.findIndex((y) => y.id === -1) !== -1
      );
    }
    if (images) {
      images.forEach((item) => {
        list.push({
          url: item.url
          // url: BaseService.getImageUrlFromServer(
          //   item.id,
          //   item.name,
          //   item.url
          // ),
        });
      });
    }
    return list;
  };

  getPriceString = (price) => {
    price = price ? price : 0;
    return `${BaseService.formatNumber(price.toString())}đ`;
  };

  handleOnClickColorTagItem = (colorTagItemId) => {
    this.setState({
      colorTagItemSelectedId: colorTagItemId,
    });
  };

  // isSelectedColorTag = (colorTagItem) => {
  //     let selectedClass = '';
  //     if(this.state.colorTagItemSelectedId === colorTagItem.id) {
  //         selectedClass = 'product-detail-color-tags-item-selected-div';
  //     }
  //     return selectedClass;
  // }

  getColorTags = () => {
    let colorTags = [];
    let images = this.props.productDetail.images;
    const propsColorTags = this.props.productDetail.colorTags;
    propsColorTags.forEach((colorTagItem) => {
      let isHasImage = false;
      for (var i = 0; i < images.length; i++) {
        if (
          images[i].colorTags &&
          images[i].colorTags.findIndex((t) => t.id === colorTagItem.id) !== -1
        ) {
          isHasImage = true;
          break;
        }
      }
      if (isHasImage) {
        colorTags.push(colorTagItem);
      }
    });
    return colorTags;
  };

  handleOnChangeCustomerName = (value) => {
    this.setState((state) => ({
      customerName: value,
    }));
  };

  handleOnChangeCustomerPhone = (value) => {
    this.setState(
      {
        customerPhone: value,
        customerPhoneTouch: true,
      },
      () => {
        this.validCustomerPhone();
      }
    );
  };

  validCustomerPhone = (callBack) => {
    this.setState(
      {
        customerPhoneError: BaseService.stringIsNullOrWhiteSpace(
          this.state.customerPhone
        )
          ? this.state.customerPhoneErrorMessage
          : null,
      },
      () => {
        if (callBack) callBack();
      }
    );
  };

  handleOnClickSendProductRequest = () => {
    this.setState(
      {
        customerPhoneTouch: true,
      },
      () => {
        this.validCustomerPhone(() => {
          if (!this.state.customerPhoneError) {
            const saleRequestsTypeInfo =
              SaleRequestsService.getSaleRequestsTypeInfo(
                "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM"
              );
            let saleRequestsInput = SaleRequestsService.getNewSaleRequests();
            saleRequestsInput.name = saleRequestsTypeInfo.text;
            let productItem = this.props.productDetail;
            productItem = {
              id: productItem.id,
              name: productItem.name,
              titleId: productItem.titleId,
              orderNumber: productItem.orderNumber,
              categoryId: productItem.category ? productItem.category.id : null,
              categoryName: productItem.category
                ? productItem.category.name
                : null,
              tags: productItem.hashTags,
              price: productItem.price,
              originalPrice: productItem.originalPrice,
              discountPercent: productItem.discountPercent,
              promoText: productItem.promoText,
              imgURL: productItem.image_url,
              saleInfo: productItem.saleInfo,
            };
            saleRequestsInput.content = JSON.stringify({
              products: [productItem],
              customer: {
                name: this.state.customerName,
                phone: this.state.customerPhone,
              },
            });
            saleRequestsInput.type = saleRequestsTypeInfo.value;
            saleRequestsInput.status = BaseService.getRecordStatus(0);
            this.props.insertSaleRequests(saleRequestsInput);
          }
        });
      }
    );
  };

  getSaleContactElement = () => {
    const element = (
      <>
        <div className="product-detail-sale-contact-title-div">
          Liên hệ trực tiếp hoặc gửi thông tin của bạn về cho chúng để được tư
          vấn miễn phí trong thời gian sớm nhất:
        </div>
        <div className="product-detail-sale-contact-wrapper-div">
          <div className="product-detail-sale-contact-contact-div">
            <div>
              <div className="product-detail-sale-contact-item-div product-detail-sale-contact-phone-div">
                <a href="tel:+84773588999" title="Bấm để gọi cho chúng tôi.">
                  <img alt="" src={phone_png} />
                  0773 588 999
                </a>
              </div>
              <div className="product-detail-sale-contact-item-div product-detail-sale-contact-zalo-div">
                <a href="https://zalo.me/0773588999" target={this.state.target}>
                  <img alt="" src={zalo_png} />
                  Zalo
                </a>
              </div>
              <div className="product-detail-sale-contact-item-div product-detail-sale-contact-messenger-div">
                <a
                  href="https://www.messenger.com/t/102099432092711"
                  target={this.state.target}
                >
                  <img alt="" src={facebook_messenge_png} />
                  Messenger
                </a>
              </div>
            </div>
          </div>
          <div className="product-detail-sale-contact-line-div">
            <div></div>
            <div>hoặc</div>
          </div>
          <div className="product-detail-sale-contact-form-div">
            {this.state.isInsertSaleRequestsSuccess ? (
              <>
                <div>
                  Cảm ơn bạn đã tin dùng Grace Face Việt Nam. Bộ phận kinh doanh
                  của chúng tôi sẽ liên hệ bạn trong thời gian sớm nhất.
                </div>
              </>
            ) : (
              <>
                <FormTextInput
                  labelText="Tên Anh/Chị"
                  value={this.state.customerName}
                  onChange={this.handleOnChangeCustomerName}
                />
                <FormTextInput
                  labelText="Số điện thoại"
                  value={this.state.customerPhone}
                  onChange={this.handleOnChangeCustomerPhone}
                  error={
                    this.state.customerPhoneTouch &&
                      this.state.customerPhoneError
                      ? this.state.customerPhoneError
                      : null
                  }
                />
                <br />
                <FormButton
                  faIconClass="far fa-paper-plane"
                  text="Gửi"
                  onClick={this.handleOnClickSendProductRequest}
                />
              </>
            )}
          </div>
        </div>
      </>
    );
    return element;
  };

  getUserManualTabTitle = () => {
    const element = (
      <>
        {this.props.windowWidth >= 768 ? (
          "Hướng dẫn sử dụng"
        ) : (
          <>
            Hướng dẫn
            <br />
            sử dụng
          </>
        )}
      </>
    );
    return element;
  };

  addProductToCart = () => {
    let productItem = this.props.productDetail;
    productItem = {
      id: productItem.id,
      name: productItem.name,
      titleId: productItem.titleId,
      orderNumber: productItem.orderNumber,
      categoryId: productItem.category ? productItem.category.id : "",
      categoryName: productItem.category ? productItem.category.name : "",
      tags:
        productItem.hashTags && productItem.hashTags.length > 0
          ? BaseService.convertArrayStringToArray(
            productItem.hashTags,
            "#"
          ).toString()
          : "",
      price: productItem.price,
      originalPrice: productItem.originalPrice,
      discountPercent: productItem.discountPercent,
      promoText: productItem.promoText,
      imgURL: productItem.image_url,
      saleInfo: productItem.saleInfo,
    };
    this.props.addProductItemToCart(productItem);
  };

  getProductDetailElement = () => {
    const productDetail = this.props.productDetail;
    const element = (
      <>
        <MetaTags>
          {/* <!-- Primary Meta Tags --> */}
          <title>{productDetail.name}</title>
          <meta
            name="description"
            content={productDetail.seoDescription}
          ></meta>

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content={productDetail.name} />
          <meta
            property="og:description"
            content={productDetail.seoDescription}
          />
          <meta
            property="og:image"
            content={`https://lh3.google.com/u/0/d/${productDetail.image.url}`}
          />

          {/* <!-- Twitter --> */}
          <meta property="twitter:url" content={window.location.href} />
          <meta property="twitter:title" content={productDetail.name} />
          <meta
            property="twitter:description"
            content={productDetail.seoDescription}
          />
          <meta
            property="twitter:image"
            content={`https://lh3.google.com/u/0/d/${productDetail.image.url}`}
          />
        </MetaTags>
        <Container className="product-detail-container-div">
          <Row>
            <Col md={6} lg={7} className="product-detail-cotent-col-div">
              <ImageSlider
                imageSliderImagesList={this.convertToImageURLList()}
                windowWidth={this.props.windowWidth}
                isImageNavigation={true}
              />
              {this.props.productDetail.colorTags &&
                this.props.productDetail.colorTags.filter(
                  (item) => item.id !== -1
                ).length > 0 ? (
                <div className="product-detail-color-tags-wrapper-div d-block d-sm-none">
                  <ColorTagsSlider
                    colorTags={this.getColorTags()}
                    handleOnClickColorTagItem={this.handleOnClickColorTagItem}
                  />
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col md={6} lg={5} className="product-detail-cotent-col-div">
              <h1 className="product-detail-product-name-h1">
                {this.props.productDetail.name}
              </h1>
              <div className="product-detail-product-status-div">
                {this.props.productDetail.saleInfo &&
                  this.props.productDetail.saleInfo.sale11 === true ? (
                  <div className="product-detail-product-sale11-div">
                    {/* Mua 1 tặng 1 */}
                    Khuyến mãi
                  </div>
                ) : (
                  <></>
                )}
                {this.props.productDetail.isNew ? (
                  <div className="product-detail-product-isnew-div">Mới</div>
                ) : (
                  <></>
                )}
                {this.props.productDetail.isHot ? (
                  <div className="product-detail-product-ishot-div">Hot</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="product-detail-product-description-div">
                {/* {this.props.productDetail.description} */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.productDetail.description,
                  }}
                ></div>
              </div>
              <div className="product-detail-product-price-div">
                {this.getPriceString(this.props.productDetail.price)}
                <div className="product-detail-product-original-price-div">
                  {this.props.productDetail.price !==
                    this.props.productDetail.originalPrice ? (
                    <span>
                      {this.getPriceString(
                        this.props.productDetail.originalPrice
                      )}
                    </span>
                  ) : (
                    <></>
                  )}
                  {this.props.productDetail.price !==
                    this.props.productDetail.originalPrice ? (
                    <span className="product-detail-product-discount-percent-span">
                      {" "}
                      -{this.props.productDetail.discountPercent}%
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="product-detail-product-promotext-div">
                {this.props.productDetail.promoText}
              </div>
              {/* <div>{this.state.colorTagItemSelectedId}</div> */}
              {this.props.productDetail.colorTags &&
                this.props.productDetail.colorTags.filter(
                  (item) => item.id !== -1
                ).length > 0 ? (
                <div className="product-detail-color-tags-wrapper-div d-none d-md-block">
                  <div>Màu sắc:</div>
                  <ColorTagsSlider
                    colorTags={this.getColorTags()}
                    handleOnClickColorTagItem={this.handleOnClickColorTagItem}
                  />
                  {/* {
                                        <>
                                            {
                                                BaseService.getOrderListWithName(this.props.productDetail.colorTags).filter(item => item.id !== -1).map(item => <>
                                                    <div className={`
                                                        product-detail-color-tags-item-div
                                                        ${this.isSelectedColorTag(item)}
                                                    `} title={item.name}
                                                        style={{
                                                            backgroundColor: (!BaseService.stringIsNullOrWhiteSpace(item.colorCode))?(`#${item.colorCode}`):('#fff')
                                                        }}
                                                        onClick={() => { this.handleOnClickColorTagItem(item); }}
                                                    ></div>
                                                </>)
                                            }
                                        </>
                                    } */}
                </div>
              ) : (
                <></>
              )}
              {/* <div className="product-detail-button-div">
                <ButtonComponent
                  clickFunction={this.addProductToCart}
                  iconClass="fas fa-cart-plus"
                  text="Thêm vào giỏ"
                />
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="product-detail-product-cotent-col-div">
                {
                  // BaseService.getReadMoreElement('productContentDiv', <div dangerouslySetInnerHTML={{ __html: this.props.productDetail.Content }}></div>)
                  <div className="tabs-container-div">
                    <Tabs>
                      <Tab eventKey="productInfo" title="Thông tin sản phẩm">
                        <div className="read-more-container-div ck-content sun-editor-editable">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                this.props.productDetail.informationDescription,
                            }}
                          ></div>
                        </div>
                        {/* <div className="product-detail-product-detail-order-buttons-container-div product-detail-button-div">
                          <div className="product-detail-product-detail-order-button-div" onClick={() => {
                            this.addProductToCart();
                            setTimeout(() => {
                              window.open('/gio-hang', '_self');
                            }, 2000);
                          }}>Đặt hàng ngay</div>
                          <div>Hoặc</div>
                          <a className="product-detail-product-detail-order-button-div" href="tel:+84773588999" title="Bấm để gọi cho chúng tôi.">
                            Gọi  0773 588 999
                            <div className="product-detail-product-detail-order-button-sub-div">(Để được tư vấn miễn phí)</div>
                          </a>
                        </div> */}
                      </Tab>
                      <Tab eventKey="naturalIngredients" title="Thành phần sản phẩm">
                        <div className="read-more-container-div ck-content sun-editor-editable">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                this.props.productDetail.ingredientDescription,
                            }}
                          ></div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="userManual"
                        title={this.getUserManualTabTitle()}
                      >
                        <div className="read-more-container-div ck-content sun-editor-editable">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.productDetail.usesDescription,
                            }}
                          ></div>
                        </div>
                      </Tab>
                      <Tab eventKey="saleContact" title="Tư vấn da miễn phí">
                        {this.getSaleContactElement()}
                      </Tab>
                    </Tabs>
                  </div>
                }
              </div>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        <div>
          {this.props.productDetailRequestStatus ===
            RequestStatus.RQ_SUCCESS ? (
            this.getProductDetailElement()
          ) : (
            <LoadingComponent />
          )}
        </div>
      </>
    );
    return html;
  }
}

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { InDevelopmentComponent } from "../components/public/in-development-component";
import {
  FormInfoBox,
  FormLink,
  FormModal,
} from "../components/public/form-components";
import "./admin-ui-configs-page.css";
import { AdminMenuList } from "../components/admin-layout/admin-menu-list";
import { AdminDynamicPagesList } from "../components/admin-layout/admin-dynamic-pages-list";
import { AdminFooterForm } from "../components/admin-layout/admin-footer-form";
import { AdminPartnersForm } from "../components/admin-layout/admin-partners-form";
import { RequestStatus } from "../constants";
import { LoadingComponent } from "../components/public/loading-component";
import { BaseService } from "../services/base-service";
import { UIConfigsService } from "../services/ui-configns-service";
// import ReactGA from "react-ga";

export class AdminUIConfigsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // assetsModuleIsOpen: false,
      // pageModuleIsOpen: false,
      // menuModuleIsOpen: false,
      // footerModuleIsOpen: false,
      targetModuleId: null,
      moduleModalTitle: "",
      moduleModalContent: null,
      modules: [
        {
          id: "menu",
          iconClass: "fas fa-bars",
          title: "Menu",
          description: "Phần chỉnh sửa menu",
          // handleOnClickFormInfoBox: this.handleOnClickFormInfoBoxMenu,
        },
        {
          id: "assets",
          iconClass: "fas fa-image",
          title: "Hình ảnh",
          description: "Hình ảnh và màu sắc",
          // handleOnClickFormInfoBox: this.handleOnClickFormInfoBoxLogo,
          isDisabled: true,
        },
        {
          id: "pages",
          iconClass: "fas fa-file",
          title: "Trang",
          description: "Phần quản lý trang động",
          // handleOnClickFormInfoBox: this.handleOnClickFormInfoBoxPage,
          // isDisabled: true,
        },
        {
          id: "footer",
          iconClass: "fas fa-sliders-h",
          title: "Footer",
          description: "Phần chỉnh sửa footer",
          // handleOnClickFormInfoBox: this.handleOnClickFormInfoBoxFooter,
        },
        {
          id: "partners",
          iconClass: "fas fa-handshake",
          title: "Đối tác",
          description: "Phần chỉnh sửa đối tác thương hiệu",
          // handleOnClickFormInfoBox: this.handleOnClickFormInfoBoxFooter,
        },
      ],
      adminMenuListConfigs: null,
      adminFooterConfigs: null,
      adminPagesConfigs: [],
      adminPartnersList: [],
      isDataHasUpdated: false,
    };
  }

  componentDidMount() {
    this.props.getUIConfigsList();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.uiConfigsListRequestStatus !==
        nextProps.uiConfigsListRequestStatus &&
      nextProps.uiConfigsListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      const uiConfigsListRequestResult =
        BaseService.getOrderListWithCreatedTime(
          nextProps.uiConfigsListRequestResult
        ).reverse();
      const adminMenuListConfigs = uiConfigsListRequestResult.filter(
        (t) => t.type === "UI_CONFIGS_0_MENU" && t.isDeleted !== true
      );
      const adminPagesConfigs = uiConfigsListRequestResult.filter(
        (t) => t.type === "UI_CONFIGS_2_PAGES" && t.isDeleted !== true
      );
      const adminFooterConfigs = uiConfigsListRequestResult.filter(
        (t) => t.type === "UI_CONFIGS_3_FOOTER" && t.isDeleted !== true
      );
      const adminPartnersList = uiConfigsListRequestResult.filter(
        (t) => t.type === "UI_CONFIGS_4_PARTNERS" && t.isDeleted !== true
      );
      this.setState((state) => {
        return {
          adminMenuListConfigs:
            adminMenuListConfigs.length > 0
              ? adminMenuListConfigs[0]
              : UIConfigsService.getNewUIConfigsByType("UI_CONFIGS_0_MENU"),
          adminPagesConfigs:
            BaseService.getOrderListWithName(adminPagesConfigs),
          adminFooterConfigs:
            adminFooterConfigs.length > 0
              ? adminFooterConfigs[0]
              : UIConfigsService.getNewUIConfigsByType("UI_CONFIGS_3_FOOTER"),
          adminPartnersList:
            adminPartnersList.length > 0
              ? adminPartnersList[0]
              : UIConfigsService.getNewUIConfigsByType("UI_CONFIGS_4_PARTNERS"),
        };
      });
    }

    if (
      (this.props.insertUIConfigsRequestStatus !==
        nextProps.insertUIConfigsRequestStatus &&
        nextProps.insertUIConfigsRequestStatus === RequestStatus.RQ_SUCCESS) ||
      (this.props.updateUIConfigsRequestStatus !==
        nextProps.updateUIConfigsRequestStatus &&
        nextProps.updateUIConfigsRequestStatus === RequestStatus.RQ_SUCCESS)
    ) {
      // if(this.state.targetModuleId !== 'pages') {
      this.closeModal();
      // }
      this.props.getUIConfigsList();
    }
  }

  // handleOnClickFormInfoBoxLogo = () => {
  //   alert("handleOnClickFormInfoBoxLogo");
  // };

  // handleOnClickFormInfoBoxMenu = () => {
  //   alert("handleOnClickFormInfoBoxMenu");
  // };

  // handleOnClickFormInfoBoxPage = () => {
  //   alert("handleOnClickFormInfoBoxPage");
  // };

  // handleOnClickFormInfoBoxFooter = () => {
  //   alert("handleOnClickFormInfoBoxFooter");
  // };

  trackIsDataHasUpdated = (isDataHasUpdated) => {
    this.setState({
      isDataHasUpdated: isDataHasUpdated,
    });
  };

  saveUIConfigs = (uiConfigs) => {
    if (uiConfigs.id === null) {
      this.props.insertUIConfigs(uiConfigs);
    } else {
      this.props.updateUIConfigs(uiConfigs);
    }
  };

  renderModuleModalContent = (moduleId) => {
    this.setState((state) => {
      const newState = {
        targetModuleId: moduleId,
      };
      return newState;
    });

    let element = <InDevelopmentComponent />;
    switch (moduleId) {
      case "menu": {
        element = (
          <AdminMenuList
            adminMenuListConfigs={this.state.adminMenuListConfigs}
            insertUIConfigsRequestStatus={
              this.props.insertUIConfigsRequestStatus
            }
            updateUIConfigsRequestStatus={
              this.props.updateUIConfigsRequestStatus
            }
            trackIsDataHasUpdated={this.trackIsDataHasUpdated}
            save={this.saveUIConfigs}
          />
        );
        break;
      }
      case "pages": {
        element = (
          <AdminDynamicPagesList
            adminPagesConfigs={this.state.adminPagesConfigs}
            insertUIConfigsRequestStatus={
              this.props.insertUIConfigsRequestStatus
            }
            updateUIConfigsRequestStatus={
              this.props.updateUIConfigsRequestStatus
            }
            // trackIsDataHasUpdated={this.trackIsDataHasUpdated}
            save={this.saveUIConfigs}
          />
        );
        break;
      }
      case "footer": {
        element = (
          <AdminFooterForm
            adminFooterConfigs={this.state.adminFooterConfigs}
            insertUIConfigsRequestStatus={
              this.props.insertUIConfigsRequestStatus
            }
            updateUIConfigsRequestStatus={
              this.props.updateUIConfigsRequestStatus
            }
            trackIsDataHasUpdated={this.trackIsDataHasUpdated}
            save={this.saveUIConfigs}
          />
        );
        break;
      }
      case "partners": {
        element = (
          <AdminPartnersForm
            adminPartnersList={this.state.adminPartnersList}
            insertUIConfigsRequestStatus={
              this.props.insertUIConfigsRequestStatus
            }
            updateUIConfigsRequestStatus={
              this.props.updateUIConfigsRequestStatus
            }
            trackIsDataHasUpdated={this.trackIsDataHasUpdated}
            save={this.saveUIConfigs}
          />
        );
        break;
      }
      default: {
      }
    }
    return element;
  };

  openModuleModal = (module) => {
    this.setState((state) => {
      const newState = {
        isDataHasUpdated: false,
      };
      // newState[`${moduleId}ModuleIsOpen`] = true;
      newState["moduleModalTitle"] = <>{module.title}</>;
      newState["moduleModalContent"] = (
        <div className="admin-ui-configs-page-module-modal-content-wrapper-div">
          {this.renderModuleModalContent(module.id)}
        </div>
      );
      return newState;
    });
  };

  closeModal = () => {
    this.setState({
      moduleModalTitle: "",
      moduleModalContent: null,
      isDataHasUpdated: false,
    });
  };

  trackIsDataHasUpdated = (isDataHasUpdated) => {
    this.setState({
      isDataHasUpdated: isDataHasUpdated,
    });
  };

  renderUIConfigsContent = () => {
    const element = (
      <>
        <Container fluid={true}>
          <Row>
            <Col>
              <div>
                <FormLink path="/" text="Đi tới trang chủ" />
              </div>
              <br />
            </Col>
          </Row>
          <Row>
            {this.state.modules.map((module) => (
              <Col xs={12} sm={6} xl={3}>
                <FormInfoBox
                  iconClass={module.iconClass}
                  title={module.title}
                  description={module.description}
                  handleOnClickFormInfoBox={() => {
                    this.openModuleModal(module);
                  }}
                  isDisabled={module.isDisabled}
                />
              </Col>
            ))}
          </Row>
        </Container>
        <FormModal
          id="AdminUIConfigsModal"
          size="xl"
          isShowModal={this.state.moduleModalContent !== null}
          modalTitle={this.state.moduleModalTitle}
          modalContent={this.state.moduleModalContent}
          closeModal={this.closeModal}
          isDataHasUpdated={this.state.isDataHasUpdated}
        />
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        {this.props.uiConfigsListRequestStatus === RequestStatus.RQ_REQUEST ? (
          <LoadingComponent />
        ) : (
          this.renderUIConfigsContent()
        )}
      </>
    );
    return html;
  }
}

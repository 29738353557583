import { OleeApiURL, UIConfigsApiUrl } from "../constants";
import { AuthenticationService } from "./authentication-service";
import { BaseService } from "./base-service";

// UI_CONFIGS_0_MENU
// UI_CONFIGS_1_ASSETS
// UI_CONFIGS_2_PAGES
// UI_CONFIGS_3_FOOTER
// UI_CONFIGS_4_PARTNERS

// *** UI_CONFIGS_0_MENU
// {
//     ...,
//     content: JSON.stringfy(
//         [
//             {
//                 id: string,
//                 text: string,
//                 path: string,
//                 parentId: string,
//                 orderNumber: number,
//                 isDeleted: boolean
//             }
//         ]
//     )
// }

// *** UI_CONFIGS_2_PAGES
// {
//     ...,
//     content: JSON.stringfy(
//         [
//             {
//                 id: string,
//                 name: string,
//                 content: string,
//                 orderNumber: number,
//                 isDeleted: boolean
//             }
//         ]
//     )
// }

// *** UI_CONFIGS_3_FOOTER
// {
//     ...,
//     content: JSON.stringfy(
//         {
//             googleMapSrc: string,
//             contactInfo: {
//                 addresses: [string],
//                 hotlines: [string],
//                 emails: [string],
//                 websites: [string]
//             },
//             aboutUs: [
//                 {
//                     text: string,
//                     path: string
//                 }
//             ],
//             socialLinks: [
//                 {
//                     social: {
//                         name: string,
//                         isDeleted: boolean,
//                         path: string
//                     }
//                 }
//             ]
//         }
//     )
// }

// *** UI_CONFIGS_4_PARTNERS
// {
//     ...,
//     content: JSON.stringfy(
//         [
//             {
//                 id: string,
//                 name: string,
//                 url: string,
//                 logoId: string,
//                 logoName: string,
//                 orderNumber: number,
//                 isDeleted: boolean
//             }
//         ]
//     )
// }

export class UIConfigsService {
  static getNewUIConfigs = () => {
    return {
      id: null,
      name: "",
      content: "",
      orderNumber: 0,
      type: "",
      isDeleted: false,
    };
  };

  static getNewUIConfigsByType = (type) => {
    return {
      id: null,
      name: "",
      content: "",
      orderNumber: 0,
      type,
      isDeleted: false,
    };
  };

  static getUIConfigsInput = (uiConfigs) => {
    const uiConfigsInput = {
      id: uiConfigs.id,
      name: uiConfigs.name,
      content: uiConfigs.content,
      orderNumber: uiConfigs.orderNumber,
      type: uiConfigs.type,
      isDeleted: uiConfigs.isDeleted,
    };
    return uiConfigsInput;
  };

  static getNewMenuItem = () => {
    return {
      id: BaseService.getRandomString(5),
      text: "",
      path: "",
      parentId: null,
      orderNumber: 0,
      isDeleted: false,
    };
  };

  static getNewFooterConfigs = () => {
    return {
      googleMapSrc: "",
      contactInfos: [
        {
          id: "LS3S2",
          text: "Địa chỉ",
          value: "",
          path: "",
          orderNumber: 0,
          isDeleted: false,
        },
        {
          id: "rYHms",
          text: "Hotline",
          value: "",
          path: "",
          orderNumber: 1,
          isDeleted: false,
        },
        {
          id: "fKcpn",
          text: "Email",
          value: "",
          path: "",
          orderNumber: 2,
          isDeleted: false,
        },
        {
          id: "JGEYK",
          text: "Website",
          value: "",
          path: "",
          orderNumber: 3,
          isDeleted: false,
        },
      ],
      aboutUs: [
        {
          id: "heRmh",
          text: "Giới thiệu",
          path: "",
          orderNumber: 0,
          isDeleted: false,
        },
        {
          id: "aX7W9",
          text: "Chính sách đại lý",
          path: "",
          orderNumber: 1,
          isDeleted: false,
        },
        {
          id: "RJ2Oz",
          text: "Tuyển dụng",
          path: "",
          orderNumber: 2,
          isDeleted: false,
        },
      ],
      socialLinks: [
        {
          id: "hCNET",
          text: "Facebook",
          path: "",
          iconURL: "",
          orderNumber: 0,
          isDeleted: false,
        },
        {
          id: "ONsPd",
          text: "Youtube",
          path: "",
          iconURL: "",
          orderNumber: 1,
          isDeleted: false,
        },
        {
          id: "LElbs",
          text: "Twitter",
          path: "",
          iconURL: "",
          orderNumber: 2,
          isDeleted: false,
        },
        {
          id: "bFBxW",
          text: "Instagram",
          path: "",
          iconURL: "",
          orderNumber: 3,
          isDeleted: false,
        },
        {
          id: "seTBa",
          text: "Shopee",
          path: "",
          iconURL: "",
          orderNumber: 4,
          isDeleted: false,
        },
      ],
    };
  };

  static getUIConfigsList = () => {
    return fetch(`${OleeApiURL}${UIConfigsApiUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static getUIConfigsDetail = (uiConfigsId) => {
    return fetch(`${OleeApiURL}${UIConfigsApiUrl}/${uiConfigsId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static insertUIConfigs = (uiConfigs) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(uiConfigs);
      return fetch(`${OleeApiURL}${UIConfigsApiUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static updateUIConfigs = (uiConfigs) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(uiConfigs);
      return fetch(`${OleeApiURL}${UIConfigsApiUrl}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static deleteUIConfigs = (uiConfigs) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(`${OleeApiURL}${UIConfigsApiUrl}/${uiConfigs.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loginData.token}`,
        },
      }).then((res) => res.json());
    } else {
      return;
    }
  };
}
